//- Lineups Table
//- ----------------------------------------------

.match-lineups-container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-up(lg) {
		max-width: 708px;
	}

	@media (min-width: 1200px) {
		max-width: 970px;
	}

	.matches-tabs & {
		@include media-breakpoint-up(lg) {
			margin-bottom: -20px;
		}
	}
}

.lineups-table {
	width: 100%;

	tr + tr td {
		border-top-style: solid;
	}

	tr {
		&:nth-child(odd) {
			td {
				&.team-blue {
					background-color: rgba($msw-blue-color, .2);
				}

				&.team-red {
					background-color: rgba($msw-red-color, .2);
				}
			}
		}

		&:nth-child(even) {
			td {
				&.team-blue {
					background-color: rgba($msw-blue-color, .1);
				}

				&.team-red {
					background-color: rgba($msw-red-color, .1);
				}
			}
		}
	}

	th, td {
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;

		&:first-child {
			padding-left: 0;
			text-align: left;
		}

		&:last-child {
			padding-right: 0;
			text-align: right;
		}
	}

	th {
		padding-bottom: 5px;
		font-size: .75rem;
		vertical-align: bottom;

		@include media-breakpoint-up(md) {
			padding-bottom: 11px;
			font-size: .875rem;
		}
	}

	td {
		font-size: .75rem;
		text-transform: none;

		&.team-blue:first-child,
		&.team-red:last-child {
			position: relative;

			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 2px;
				content: "";
			}
		}

		&.team-blue:first-child:before {
			left: 0;
			background-color: $msw-blue-color;
		}

		&.team-red:last-child:before {
			right: 0;
			background-color: $msw-red-color;
		}
	}

	td {
		&:last-child {
			.match-player {
				justify-content: flex-end;
			}

			.match-player__avatar {
				order: 2;
			}
		}

		@media (max-width: 374px) {
			&:first-child {
				.match-player {
					padding-left: 15px;
				}
			}

			&:last-child {
				.match-player {
					padding-right: 15px;
				}
			}
		}
	}

	.td-d-none-sm {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	svg {
		width: 14px;
		height: 14px;
		fill: theme-color("light");
	}

	&--style-1 {
		tr + tr td {
			border-top-width: 1px;
		}

		td {
			padding-top: 0;
			padding-bottom: 0;

			&:first-child,
			&:last-child {
				width: 145px;

				@include media-breakpoint-up(sm) {
					width: 225px;
				}
			}

			&:last-child {
				.match-player__avatar {
					&:before {
						position: absolute;
						right: -50%;
						left: 40%;
						transform: rotate(-20deg);
					}
				}
			}

			@include media-breakpoint-down(sm) {
				.match-player {
					position: relative;
					padding-top: 18px;
					padding-bottom: 17px;
				}

				&:first-child {
					.match-player {
						@media (min-width: 375px) {
							padding-left: 24px;
						}
					}

					.match-player__avatar:not(.match-player__avatar--small) {
						&:before {
							left: 0;
							right: auto;
						}
					}
				}

				&:last-child {
					.match-player {
						@media (min-width: 375px) {
							padding-right: 24px;
						}
					}

					.match-player__avatar:not(.match-player__avatar--small) {
						&:before {
							left: auto;
							right: 0;
						}
					}
				}

				.match-player__avatar:not(.match-player__avatar--small) {
					position: initial;

					img {
						display: none;
					}

					&:before {
						top: 0;
						bottom: 0;
						width: 2px;
						transform: none;
					}
				}
			}

			@media (max-width: 575px) {
				padding: 0;
			}
		}

		.td-role {
			width: 40px;
			padding-left: 10px;
			padding-right: 10px;

			@include media-breakpoint-up(sm) {
				width: 60px;
				padding-left: 40px;
				padding-right: 40px;
			}

			@include media-breakpoint-up(md) {
				padding-left: 0;
				padding-right: 0;
			}

			@include media-breakpoint-up(lg) {
				width: 70px;
			}

			@media (min-width: 1200px) {
				width: 90px;
			}
		}

		.td-center-left {
			padding-left: 5px;
			padding-right: 30px;

			@include media-breakpoint-up(lg) {
				padding-right: 40px;
			}

			@media (min-width: 1200px) {
				padding-right: 60px;
			}
		}

		.td-center-right {
			padding-right: 5px;
			padding-left: 30px;

			@include media-breakpoint-up(lg) {
				padding-left: 40px;
			}

			@media (min-width: 1200px) {
				padding-left: 60px;
			}
		}
	}

	&--style-2 {
		tr + tr td {
			border-top-width: 2px;
			border-color: theme-color("dark");
		}

		td {
			padding-top: 15px;
			padding-bottom: 14px;

			@include media-breakpoint-up(lg) {
				&:first-child {
					padding-right: 0;
				}

				&:last-child {
					padding-left: 0;
				}
			}
		}

		.td-center-left {
			padding-right: 20px;

			@media (min-width: 1200px) {
				padding-left: 10px;
				padding-right: 44px;
			}
		}

		.td-center-right {
			padding-left: 20px;

			@media (min-width: 1200px) {
				padding-right: 10px;
				padding-left: 44px;
			}
		}

		td.td-center-right {
			border-left: 2px solid theme-color("dark");
		}

		.td-player {
			@include media-breakpoint-up(sm) {
				min-width: 186px;
			}

			@include media-breakpoint-up(md) {
				min-width: 186px;
			}

			@include media-breakpoint-up(lg) {
				min-width: 106px;

				&:first-child {
					padding-right: 0;
				}
				&:last-child {
					padding-left: 0;
				}
			}

			@media (min-width: 1200px) {
				min-width: 186px;
			}
		}

		.td-kda {
			min-width: 80px;
		}

		.td-strk {
			width: 70px;
			padding-left: 2px;
			padding-right: 2px;

			@include media-breakpoint-up(lg) {
				width: 44px;
			}
		}

		.td-gold {
			min-width: 70px;

			@include media-breakpoint-up(lg) {
				min-width: 74px;
			}
		}

		.td-cs {
			min-width: 70px;

			@include media-breakpoint-up(lg) {
				min-width: 52px;
			}
		}

		.match-player {
			&__avatar {
				img {
					display: block;
				}
			}
			&__avatar,
			&__skills {

				@include media-breakpoint-down(xs) {
					display: none;
				}

				@media (min-width: 992px) and (max-width: 1199px) {
					display: none;
				}
			}
		}
	}

	&--style-3 {
		tr + tr td {
			border-top-width: 1px;
		}

		td {
			position: relative;
			padding-top: 0;
			padding-bottom: 0;

			@include media-breakpoint-down(xs) {
				padding-top: 18px;
				padding-bottom: 17px;
			}

			@media (min-width: 768px) and (max-width: 1199px) {
				padding-top: 18px;
				padding-bottom: 17px;
			}

			.match-player:not(.match-player--small) .match-player__avatar {
				&:before {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					transform: none;
				}
			}

			&:first-child {
				.match-player {
					@include media-breakpoint-down(xs) {
						padding-left: 24px;
					}

					@media (min-width: 768px) and (max-width: 1199px) {
						padding-left: 24px;
					}

					&__avatar {
						@include media-breakpoint-between(sm,sm) {
							margin-right: 18px;
						}

						@media (min-width: 1200px) {
							margin-right: 18px;
						}
					}
				}
			}

			&:last-child {
				.match-player {
					@include media-breakpoint-down(xs) {
						padding-right: 24px;
					}

					@media (min-width: 768px) and (max-width: 1199px) {
						padding-right: 24px;
					}

					&__avatar {
						@include media-breakpoint-between(sm,sm) {
							margin-left: 18px;
						}

						@media (min-width: 1200px) {
							margin-left: 18px;
						}
					}
				}
			}

			&:first-child {
				.match-player__avatar:not(.match-player__avatar--small) {
					@include media-breakpoint-down(xs) {
						&:before {
							left: 0;
							right: auto;
						}
					}

					@media (min-width: 768px) and (max-width: 1199px) {
						&:before {
							left: 0;
							right: auto;
						}
					}
				}
			}

			&:last-child {
				.match-player__avatar:not(.match-player__avatar--small) {
					@include media-breakpoint-down(xs) {
						&:before {
							left: auto;
							right: 0;
						}
					}

					@media (min-width: 768px) and (max-width: 1199px) {
						&:before {
							left: auto;
							right: 0;
						}
					}
				}
			}

			.match-player {
				@include media-breakpoint-between(sm,sm) {
					min-width: 186px;
				}

				&__avatar:not(.match-player__avatar--small) {
					@include media-breakpoint-down(xs) {
						position: initial;

						&:before {
							top: 0;
							bottom: 0;
							width: 2px;
							transform: none;
						}

						img {
							display: none;
						}
					}

					@media (min-width: 768px) and (max-width: 1199px) {
						position: initial;

						&:before {
							top: 0;
							bottom: 0;
							width: 2px;
							transform: none;
						}

						img {
							display: none;
						}
					}
				}
			}

			@media (max-width: 374px) {
				&:first-child {
					.match-player {
						padding-left: 15px;
					}
				}

				&:last-child {
					.match-player {
						padding-right: 15px;
					}
				}
			}
		}

		.td-center-left {
			@media (min-width: 1200px) {
				padding-left: 5px;
				padding-right: 60px;
			}
		}

		.td-center-right {
			@media (min-width: 1200px) {
				padding-right: 5px;
				padding-left: 60px;
			}
		}
	}
}