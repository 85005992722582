//- Staff Members
//- ----------------------------------------------

.content {
	&.staff-layout {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-auto-flow: column;
			grid-template-rows: 50% 50%;
			grid-auto-columns: calc(100vh - #{$header-height-desktop-mobile});
		}

		@media (min-width: $xxl-breakpoint) {
			grid-auto-columns: calc(100vh - #{$header-height-desktop});
		}
	}
}

.staff-member {
	overflow: hidden;
	background-color: theme-color("light");

	@include media-breakpoint-up(sm) {
		display: flex;
	}

	&:nth-child(odd) {
		.staff-member__thumbnail {
			order: 2;
		}
	}

	&__thumbnail {
		@include media-breakpoint-up(sm) {
			width: 50%;
			height: 100%;
		}

		img {
			width: 100%;
		}
	}

	&__body {
		position: relative;
		padding: 32px 40px;
		overflow: hidden;

		@include media-breakpoint-up(sm) {
			width: 50%;
		}

		@include media-breakpoint-up(lg) {
			@include fluid-value(padding-top, 32);
			@include fluid-value(padding-right, 40);
			@include fluid-value(padding-bottom, 32);
			@include fluid-value(padding-left, 40);
		}
	}

	&__title {
		padding-right: 20px;

		@include media-breakpoint-up(lg) {
			@include fluid-value(margin-bottom, 18);
			@include fluid-value(padding-right, 20);
			@include fluid-value(font-size, 46);
		}
	}
	
	&__meta,
	&__position {
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}

	&__position,
	&__meta span {
		color: theme-color("info");
	}

	&__position {
		font-size: 1rem;
		line-height: 2.125;
		letter-spacing: -.06em;

		@include media-breakpoint-up(lg) {
			@include fluid-value(font-size, 16);
		}
	}

	&__meta {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		padding-top: 2px;
		padding-right: 20px;
		font-size: .75rem;
		line-height: 1;
		letter-spacing: -.03em;

		@include media-breakpoint-up(lg) {
			@include fluid-value(margin-bottom, 25);
			@include fluid-value(padding-top, 2);
			@include fluid-value(padding-right, 20);
			@include fluid-value(font-size, 12);
		}

		span {
			display: block;
			margin-bottom: 3px;
			font-size: .625rem;

			@include media-breakpoint-up(lg) {
				@include fluid-value(margin-bottom, 3);
				@include fluid-value(font-size, 10);
			}
		}
	}

	&__excerpt {
		font-size: .75rem;
		line-height: 1.667;

		@include media-breakpoint-up(lg) {
			@include fluid-value(font-size, 12);
		}
	}
}