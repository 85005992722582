/*
Template Name: Necromancers
Template URI: https://themeforest.net/item/necromancers-esports-team-html-template/24089893
Author: Dan Fisher
Author URI: https://themeforest.net/user/dan_fisher
Description: HTML Template for eSports Teams
Version: 1.0.2
*/
/*
File Name:    style.css
Written by:   Dan Fisher - (https://themeforest.net/user/dan_fisher)
Description:  General styling for template (including Header, Footer, Sidebar)
*/
/* Table of Content
==================================================

	#Imports

	#Content

	#Components
		- Accordion
		- Buttons
		- Countdown
		- Counter
		- Custom Select
		- Fields
		- Filter Menu
		- Forms
		- Icons
		- Info Box
		- Lightbox
		- Logo
		- Map
		- Menus
		- Misc
		- Overlay
		- Page Heading
		- Partner
		- Social Menus
		- Sprites
		- Staff Member
		- Stream

	#Modules
		- Header Actions
		- Menu Panel
		- Cart Panel
		- Search Panel

		- Widget Areas
		- Widget Default
		- Widget Posts Carousel

	#Base
		- Header
		- Header Navigation
		- Header Search
		- Page Header
		- Content
		- Footer
		- 404 Error Page
		- Basic WP styles
		- Misc
		- Helpers
		- Preloader
		- Partners



	#Widgets
		- Widgets common styles
		- Widget Schedule
		- Widget Social Links
		- Widget Categories
		- Widget Posts
		- Widgets Tags
		- Widget Instagram
		- Widget Archive

	#Blog
		- Post
		- Pagination
		- Post Author
		- Post Comments
		- Post Grid

	#Shop
		- Account
		- Checkout
		- Shop
*/
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.fa,
.fas,
.far,
.fal,
.fab,
.social-menu a:before,
.post__sharing a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.social-menu a:before, .post__sharing a:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: -.125em;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left,
.social-menu a.fa-pull-left:before,
.post__sharing a.fa-pull-left:before {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right,
.social-menu a.fa-pull-right:before,
.post__sharing a.fa-pull-right:before {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url("../vendor/fontawesome/webfonts/fa-solid-900.eot");
  src: url("../vendor/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../vendor/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("../vendor/fontawesome/webfonts/fa-solid-900.woff") format("woff"), url("../vendor/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"), url("../vendor/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../vendor/fontawesome/webfonts/fa-brands-400.eot");
  src: url("../vendor/fontawesome/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../vendor/fontawesome/webfonts/fa-brands-400.woff2") format("woff2"), url("../vendor/fontawesome/webfonts/fa-brands-400.woff") format("woff"), url("../vendor/fontawesome/webfonts/fa-brands-400.ttf") format("truetype"), url("../vendor/fontawesome/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}


.fab,
.social-menu a:before,
.post__sharing a:before {
  font-family: 'Font Awesome 5 Brands';
}

body {
  font-family: "Rajdhani", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.71428571;
  color: #222430;
}

@media (min-width: 1920px) {
  body {
    font-size: 1.25rem;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.125rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 0.875;
  text-transform: uppercase;
  color: #222430;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  letter-spacing: -.06em;
}

h1, .h1 {
  font-size: 2.55rem;
}

@media (min-width: 576px) {
  h1, .h1 {
    font-size: 4.78125rem;
  }
}

@media (min-width: 992px) {
  h1, .h1 {
    font-size: 6.375rem;
  }
}

@media (max-width: 767.98px) {
  .site-layout--landing h1, .site-layout--landing .h1 {
    font-size: 2.375rem;
  }
}

@media (min-width: 1920px) {
  .site-layout--landing h1, .site-layout--landing .h1 {
    font-size: 7.75rem;
  }
}

@media (min-width: 992px) {
  h2, .h2 {
    font-size: 4.5rem;
  }
}

@media (min-width: 992px) {
  h3, .h3 {
    font-size: 2.875rem;
  }
}

@media (min-width: 1920px) {
  h3, .h3 {
    font-size: 4rem;
  }
}

h4, .h4 {
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  h4, .h4 {
    font-size: 2rem;
  }
}

@media (min-width: 1920px) {
  h4, .h4 {
    font-size: 2.875rem;
  }
}

h4.text-sm, .h4.text-sm {
  font-size: 1.375rem;
}

@media (min-width: 992px) {
  h4.text-sm, .h4.text-sm {
    font-size: 1.625rem;
  }
}

@media (min-width: 1920px) {
  h4.text-sm, .h4.text-sm {
    font-size: 1.75rem;
  }
}

h5, .h5 {
  font-size: 1.125rem;
  line-height: 1;
}

@media (min-width: 992px) {
  h5, .h5 {
    font-size: 1.375rem;
  }
}

@media (min-width: 1920px) {
  h5, .h5 {
    font-size: 1.75rem;
  }
}

h6, .h6 {
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: -.03em;
}

@media (min-width: 1920px) {
  h6, .h6 {
    font-size: 1.5rem;
  }
}

h6.text-sm, .h6.text-sm {
  font-size: .875rem;
  line-height: 1.65;
}

@media (min-width: 1920px) {
  h6.text-sm, .h6.text-sm {
    font-size: 1.125rem;
  }
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

h1 .subtitle {
  display: block;
  margin-bottom: .43em;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -.03em;
  color: #a3ff12;
}

@media (min-width: 576px) {
  h1 .subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .site-layout--landing h1 .subtitle {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  h1 .subtitle {
    font-size: 1.875rem;
  }
}

@media (min-width: 1920px) {
  .site-layout--landing h1 .subtitle {
    font-size: 2.625rem;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid #333;
}

.hr--lg {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hr--xl {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

small,
.small {
  font-size: 0.625rem;
  font-weight: inherit;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

a {
  color: inherit;
  transition: 300ms all ease;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a[href^="mailto:"]:hover {
  opacity: .7;
}

.color-warning {
  color: #ffcc00;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

ol.list,
ul.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5em;
}

ol.list li,
ul.list li {
  position: relative;
  padding: 0 0 .5em 1.875em;
}

ol.list li::before,
ul.list li::before {
  display: inline-block;
  /* block would also work */
  position: absolute;
  /* move this out of the way of the text */
  left: 0;
  /* move the counter label into the space from the padding */
  width: 20px;
  font-size: 1em;
  color: #a3ff12;
}

ol.list {
  counter-reset: counter;
}

ol.list li::before {
  content: counter(counter) ". ";
  counter-increment: counter;
  font-weight: bold;
}

ul.list li::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: normal;
  content: "\f00c";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-bold {
  font-weight: bold;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.page-content * + h4 {
  margin-top: 50px;
}

.page-content * + .social-menu {
  margin-top: 28px;
}

@media (min-width: 1920px) {
  .page-content * + .social-menu {
    margin-top: 32px;
  }
}

.page-content h4 + p {
  margin-top: 27px;
}

@media (min-width: 1920px) {
  .page-content h4 + p {
    margin-top: 46px;
  }
}

.page-content h4 + form {
  margin-top: 66px;
}

@media (min-width: 1920px) {
  .page-content h4 + form {
    margin-top: 85px;
  }
}

.page-content .social-menu + h4.text-sm {
  margin-top: 59px;
}

@media (min-width: 1920px) {
  .page-content .social-menu + h4.text-sm {
    margin-top: 98px;
  }
}

.spacer,
.spacer-lg {
  display: block;
}

.spacer + h4,
.spacer-lg + h4 {
  margin-top: 0;
}

.spacer {
  height: 30px;
}

@media (min-width: 768px) {
  .spacer {
    height: 57px;
  }
}

@media (min-width: 1920px) {
  .spacer {
    height: 76px;
  }
}

p + .spacer {
  margin-top: -24px;
}

h4 + .spacer {
  margin-top: -32px;
}

.spacer-lg {
  height: 60px;
}

@media (min-width: 768px) {
  .spacer-lg {
    height: 90px;
  }
}

@media (min-width: 1920px) {
  .spacer-lg {
    height: 107px;
  }
}

.color-primary {
  color: #a3ff12 !important;
}

.color-warning {
  color: #ffcc00 !important;
}

.color-success {
  color: #88df00 !important;
}

.color-danger {
  color: #ff1c5c !important;
}

.color-gold {
  color: #ffdf0b;
}

.color-silver {
  color: #c3e0fc;
}

.color-bronze {
  color: #f88052;
}

.accordion-item {
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe0e5;
}

.accordion-item__body {
  padding-bottom: 12px;
}

@media (min-width: 1920px) {
  .accordion-item__body {
    padding-top: 4px;
    padding-bottom: 21px;
  }
}

.accordion-item__header {
  line-height: 1.2;
  padding-bottom: 16px;
}

@media (min-width: 1920px) {
  .accordion-item__header {
    padding-bottom: 20px;
  }
}

.accordion-item__link {
  position: relative;
  padding: 0 26px 0 0;
  border: none;
  background-color: transparent;
  font-weight: 700;
  letter-spacing: -.03em;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .accordion-item__link {
    padding-right: 34px;
  }
}

body:not(.cursor-is--active) .accordion-item__link {
  cursor: pointer;
}

.accordion-item__link:focus {
  outline: none;
}

.accordion-item__icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  border: 2px solid #88df00;
  transition: border-color 300ms ease;
}

@media (min-width: 1920px) {
  .accordion-item__icon {
    top: 3px;
    width: 16px;
    height: 16px;
  }
}

.accordion-item__icon:after, .accordion-item__icon:before {
  position: absolute;
  top: 4px;
  left: 2px;
  width: 6px;
  height: 2px;
  content: "";
  background-color: #222430;
  background-color: #88df00;
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .accordion-item__icon:after, .accordion-item__icon:before {
    top: 5px;
    width: 8px;
  }
}

.accordion-item__icon:after {
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion-item__link:hover .accordion-item__icon {
  border-color: #88df00;
}

.accordion-item__link:hover .accordion-item__icon:after, .accordion-item__link:hover .accordion-item__icon:before {
  background-color: #88df00;
}

.collapsed .accordion-item__icon {
  border-color: #222430;
}

.collapsed .accordion-item__icon:after, .collapsed .accordion-item__icon:before {
  background-color: #222430;
}

.collapsed .accordion-item__icon:after {
  opacity: 1;
}

.accordion-item + .accordion-item {
  margin-top: 25px;
}

@media (min-width: 1920px) {
  .accordion-item + .accordion-item {
    margin-top: 29px;
  }
}

.btn,
.button {
  display: inline-block;
  font-weight: bold;
  font-family: "Rajdhani", sans-serif;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  border-radius: 0;
  color: #222430;
  padding: 0.875rem 1.875rem;
  font-size: 0.75rem;
  line-height: 1;
  transition: all 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn,
  .button {
    transition: none;
  }
}

@media (min-width: 1920px) {
  .btn,
  .button {
    padding: 1.1875rem 3.25rem;
    font-size: 1.125rem;
    line-height: 1;
  }
}

.btn:hover, .btn:focus,
.button:hover,
.button:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus,
.button:focus,
.button.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled,
.button.disabled,
.button:disabled {
  opacity: .65;
}

.btn:active, .btn.active,
.button:active,
.button.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #222430;
  background-color: #a3ff12;
}

.btn-primary:hover {
  color: #222430;
  background-color: #b7ff45;
}

.btn-secondary {
  color: #fff;
  background-color: #5e627e;
}

.btn-secondary:hover {
  color: #222430;
  background-color: #a3ff12;
}

.btn-success {
  color: #fff;
  background-color: #88df00;
}

.btn-success:hover {
  color: #fff;
  background-color: #a3ff13;
}

.btn-danger {
  color: #fff;
  background-color: #ff1c5c;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ff4f81;
}

.btn-warning {
  color: #fff;
  background-color: #ffcc00;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ffd633;
}

.btn-info {
  color: #222430;
  background-color: #ced0da;
}

.btn-info:hover {
  color: #222430;
  background-color: #ebecf0;
}

.btn-twitch {
  color: #fff;
  background-color: #5e22e4;
}

.btn-twitch:hover {
  color: #fff;
  background-color: #7f4fea;
}

.btn-link {
  font-weight: normal;
  color: #fff;
  border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
  background-color: transparent;
}

.btn-link, .btn-link:focus, .btn-link:active {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:hover, .btn-link:focus {
  color: #a3ff12;
  text-decoration: none;
  background-color: transparent;
}

.btn-link:disabled {
  color: #6c757d;
}

.btn-link:disabled:hover, .btn-link:disabled:focus {
  text-decoration: none;
}

.btn-link--clean {
  letter-spacing: .25em;
  text-transform: uppercase;
  font-size: .75rem;
  padding-left: 0;
  padding-right: 0;
}

.btn-link--dash {
  position: relative;
}

.btn-link--dash::before {
  content: "";
  display: inline-block;
  height: 1px;
  width: 40px;
  vertical-align: middle;
  margin-right: 1.5rem;
  background-color: #a3ff12;
}

@media (min-width: 768px) {
  .btn-link--dash::before {
    width: 80px;
  }
}

.btn-link--dash-secondary::before {
  background-color: #fff;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.1875rem 2.75rem;
  font-size: 1rem;
  line-height: 1;
}

@media (min-width: 1920px) {
  .btn-lg, .btn-group-lg > .btn {
    padding: 1.5rem 3.5rem;
    font-size: 1.375rem;
    line-height: 1;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.6875rem 1.25rem;
  font-size: 0.625rem;
  line-height: 1;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn i {
  position: relative;
  top: -.075rem;
  display: inline-block;
  margin-right: 14px;
  font-size: .75rem;
  font-weight: normal;
  vertical-align: middle;
}

@media (min-width: 1920px) {
  .btn i {
    top: -.125rem;
    font-size: 1.125rem;
  }
}

.btn-twitch i {
  font-size: 1.2em;
  margin-right: .5em;
}

.btn--landing {
  overflow: hidden;
  position: relative;
  margin-top: 1.4375rem;
  box-shadow: 0 0 40px 2px rgba(163, 255, 18, 0.2);
}

@media (max-width: 767.98px) {
  .btn--landing {
    font-size: .75rem;
    margin-top: .8rem;
  }
}

@media (min-width: 992px) {
  .btn--landing {
    margin-top: 24px;
  }
}

@media (min-width: 1920px) {
  .btn--landing {
    margin-top: 45px;
  }
}

.btn--landing:after, .btn--landing:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}

.btn--landing:after {
  background-color: #88de00;
  transition: .6s transform ease;
}

.btn--landing:before {
  background-color: #90eb00;
  transition: .4s .2s transform ease;
}

.btn--landing span {
  position: relative;
  z-index: 1;
}

.btn--landing:hover {
  box-shadow: none;
}

.btn--landing:hover:after {
  transform: translateY(0);
  transition: .4s .2s transform ease;
}

.btn--landing:hover:before {
  transform: translateY(0);
  transition: .6s transform ease;
}

@media (max-width: 767.98px) {
  .btn--landing.btn-lg, .btn-group-lg > .btn--landing.btn {
    padding: .9rem 1.6rem;
  }
}

.countdown-counter__item {
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
}

.countdown-counter__item + .countdown-counter__item:before {
  content: ":";
  display: block;
  position: absolute;
  left: 0;
  top: -3px;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: bold;
}

@media (min-width: 1440px) {
  .countdown-counter__item + .countdown-counter__item:before {
    top: -0.39062vh;
    font-size: 3.125vh;
  }
}

.countdown-counter__label {
  display: block;
}

.counter__number, .counter__label {
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
}

.widget-carousel .player__stats .counter__number, .widget-carousel .player__stats .counter__label {
  color: #fff;
}

.counter__number {
  font-size: 1.625rem;
}

@media (min-width: 1920px) {
  .counter__number {
    font-size: 1.75rem;
  }
}

@media (min-width: 1920px) {
  .widget-carousel .player__stats .counter__number {
    font-size: 2.5rem;
  }
}

.counter__label {
  margin-top: 3px;
  text-transform: uppercase;
  color: #ced0da;
}

@media (min-width: 1920px) {
  .counter__label {
    margin-top: 5px;
  }
}

@media (min-width: 1920px) {
  .widget-carousel .player__stats .counter__label {
    font-size: 1.125rem;
  }
}

.counter__icon .df-icon {
  width: 24px;
  height: 24px;
  fill: #88df00;
}

@media (min-width: 1920px) {
  .counter__icon .df-icon {
    width: 30px;
    height: 30px;
  }
}

.counter__icon--left {
  float: left;
  padding-right: 11px;
}

@media (min-width: 1920px) {
  .counter__icon--left {
    padding-right: 14px;
  }
}

.counter__icon--right {
  float: right;
  padding-left: 11px;
}

@media (min-width: 1920px) {
  .counter__icon--right {
    padding-left: 14px;
  }
}

/* Custom Select
/* ------------------------- */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 100;
  width: 100%;
  user-select: none;
}

div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}

.cs-select {
  /* Placeholder and selected option */
  /* Options */
  /* Optgroup and optgroup label */
}

.cs-select select {
  display: none;
}

.cs-select span {
  position: relative;
  display: block;
  padding: 24px 24px;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
  color: #fff;
  overflow: hidden;
}

body:not(.cursor-is--active) .cs-select span {
  cursor: pointer;
}

@media (min-width: 768px) {
  .cs-select span {
    padding-top: 36px;
    padding-bottom: 24px;
  }
}

.cs-select > span {
  padding-right: 3em;
}

.cs-select > span:after, .cs-select > span:before {
  speak: none;
  position: absolute;
  content: "";
  background-color: #a3ff12;
  transition: opacity 300ms ease;
}

.cs-select > span:before {
  top: 38px;
  right: 20px;
  width: 2px;
  height: 6px;
}

.cs-select > span:after {
  top: 40px;
  right: 18px;
  width: 6px;
  height: 2px;
}

.cs-select.cs-active > span::before {
  opacity: 0;
}

.cs-select .cs-options {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(-1rem);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
}

.site-header--top .cs-select .cs-options {
  border-top: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--top .cs-select .cs-options {
    border-top-width: 4px;
  }
}

.site-header--bottom .cs-select .cs-options {
  top: auto;
  bottom: 100%;
  transform: translateY(1rem);
  border-bottom: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--bottom .cs-select .cs-options {
    border-bottom-width: 4px;
  }
}

.cs-select.cs-active .cs-options {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
  transition-delay: 0s, 0s, 0.3s;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 16px 0;
  width: 100%;
  background-color: #151720;
}

.cs-select ul span {
  padding: 6.5px 24px;
  font-size: .625rem;
  transition: color 300ms ease;
}

@media (min-width: 1920px) {
  .cs-select ul span {
    font-size: .8125rem;
  }
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

.cs-select li span:hover,
.cs-select li.cs-selected span {
  color: #a3ff12;
}

div.cs-active {
  z-index: 200;
}

.cs-selected {
  color: #a3ff12;
}

/* Common styles of menus */
.dl-menuwrapper {
  margin-top: -45px;
  padding-top: 45px;
  width: 100%;
  float: left;
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
}

.dl-menuwrapper .dl-menu,
.dl-menuwrapper .dl-submenu,
.dl-menuwrapper .dl-submenu:not(.dl-megamenu) ul {
  padding: 0;
}

.dl-menuwrapper ul {
  list-style: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.dl-menuwrapper li {
  position: relative;
  /* Hide the inner submenus */
}

.dl-menuwrapper li a {
  display: inline-block;
  position: relative;
  padding: 14px 12px 14px 0;
  font-size: 2.875rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  outline: none;
}

@media (min-width: 1920px) {
  .dl-menuwrapper li a {
    padding: 19px 18px 19px 0;
    font-size: 4rem;
  }
}

.dl-menuwrapper li a:hover {
  color: #a3ff12;
}

.dl-menuwrapper li.active > a {
  color: #a3ff12;
}

.dl-menuwrapper li .dl-submenu {
  display: none;
}

.dl-menuwrapper .dl-menu {
  margin: 5px 0 0 0;
  position: absolute;
  width: 100%;
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* 
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link. 
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  margin: 0;
}

.dl-submenu li a {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 1rem;
}

@media (min-width: 1920px) {
  .dl-submenu li a {
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 1.375rem;
  }
}

.dl-subviewopen > .dl-submenu.dl-megamenu li {
  display: block !important;
}

.dl-submenu .dl-back {
  margin-bottom: 38px;
}

@media (min-width: 1920px) {
  .dl-submenu .dl-back {
    margin-bottom: 53px;
  }
}

.dl-submenu .dl-back a {
  font-size: 2rem;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .dl-submenu .dl-back a {
    font-size: 2.875rem;
  }
}

.dl-submenu .dl-back a:hover {
  color: #fff;
}

.dl-submenu .dl-back a:hover svg {
  fill: #fff;
}

.dl-submenu .dl-back svg {
  width: .25em;
  height: .4375em;
  vertical-align: middle;
  margin-right: .125em;
  margin-bottom: .09375em;
  fill: #5e627e;
  transition: 300ms fill ease;
}

.dl-toggle {
  position: relative;
  top: -7px;
  display: inline-block;
  width: 16px;
  height: 16px;
}

@media (min-width: 1920px) {
  .dl-toggle {
    top: -10px;
    width: 22px;
    height: 22px;
  }
}

.dl-submenu .dl-toggle {
  top: 7px;
  left: -6px;
  width: 8px;
  height: 8px;
}

@media (min-width: 1920px) {
  .dl-submenu .dl-toggle {
    top: 10px;
    left: -8px;
    width: 10px;
    height: 10px;
  }
}

.dl-toggle:after, .dl-toggle:before {
  position: absolute;
  content: "";
  background-color: #a3ff12;
}

.dl-toggle:before {
  top: 0;
  left: 6px;
  width: 4px;
  height: 16px;
  transition: opacity 300ms ease;
}

@media (min-width: 1920px) {
  .dl-toggle:before {
    left: 9px;
    height: 22px;
  }
}

.dl-submenu .dl-toggle:before {
  left: 3px;
  width: 2px;
  height: 8px;
}

@media (min-width: 1920px) {
  .dl-submenu .dl-toggle:before {
    left: 4px;
    height: 10px;
  }
}

.dl-toggle:after {
  top: 6px;
  left: 0;
  width: 16px;
  height: 4px;
}

@media (min-width: 1920px) {
  .dl-toggle:after {
    top: 9px;
    width: 22px;
  }
}

.dl-submenu .dl-toggle:after {
  top: 3px;
  width: 8px;
  height: 2px;
}

@media (min-width: 1920px) {
  .dl-submenu .dl-toggle:after {
    top: 4px;
    width: 10px;
  }
}

a:hover ~ .dl-toggle:before {
  opacity: 0;
}

.dl-menu.dl-subview .dl-toggle {
  display: none;
}

.dl-menu .dl-subviewopen .dl-submenu > li > .dl-toggle {
  display: inline-block;
}

/* Animation classes for moving out and in */
.dl-menu.dl-animate-out-1 {
  -webkit-animation: MenuAnimOut1 0.4s;
  animation: MenuAnimOut1 0.4s;
}

.dl-menu.dl-animate-out-2 {
  -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
  animation: MenuAnimOut2 0.3s ease-in-out;
}

.dl-menu.dl-animate-out-3 {
  -webkit-animation: MenuAnimOut3 0.4s ease;
  animation: MenuAnimOut3 0.4s ease;
}

.dl-menu.dl-animate-out-4 {
  -webkit-animation: MenuAnimOut4 0.4s ease;
  animation: MenuAnimOut4 0.4s ease;
}

.dl-menu.dl-animate-out-5 {
  -webkit-animation: MenuAnimOut5 0.4s ease;
  animation: MenuAnimOut5 0.4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
  0% {
  }
  50% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
  }
  75% {
    -webkit-transform: translateZ(-372.5px) rotateY(15deg);
    opacity: .5;
  }
  100% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut3 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut4 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut5 {
  0% {
  }
  100% {
    -webkit-transform: translateY(40%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut1 {
  0% {
  }
  50% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    transform: translateZ(-250px) rotateY(30deg);
  }
  75% {
    -webkit-transform: translateZ(-372.5px) rotateY(15deg);
    transform: translateZ(-372.5px) rotateY(15deg);
    opacity: .5;
  }
  100% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
}

@keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut3 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes MenuAnimOut4 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
}

@keyframes MenuAnimOut5 {
  0% {
  }
  100% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
    opacity: 0;
  }
}

.dl-menu.dl-animate-in-1 {
  -webkit-animation: MenuAnimIn1 0.3s;
  animation: MenuAnimIn1 0.3s;
}

.dl-menu.dl-animate-in-2 {
  -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
  animation: MenuAnimIn2 0.3s ease-in-out;
}

.dl-menu.dl-animate-in-3 {
  -webkit-animation: MenuAnimIn3 0.4s ease;
  animation: MenuAnimIn3 0.4s ease;
}

.dl-menu.dl-animate-in-4 {
  -webkit-animation: MenuAnimIn4 0.4s ease;
  animation: MenuAnimIn4 0.4s ease;
}

.dl-menu.dl-animate-in-5 {
  -webkit-animation: MenuAnimIn5 0.4s ease;
  animation: MenuAnimIn5 0.4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
  0% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
  20% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn5 {
  0% {
    -webkit-transform: translateY(40%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes MenuAnimIn1 {
  0% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
  20% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    transform: translateZ(-250px) rotateY(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn5 {
  0% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
  -webkit-animation: SubMenuAnimIn1 0.4s ease;
  animation: SubMenuAnimIn1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
  -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
  animation: SubMenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
  -webkit-animation: SubMenuAnimIn3 0.4s ease;
  animation: SubMenuAnimIn3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
  -webkit-animation: SubMenuAnimIn4 0.4s ease;
  animation: SubMenuAnimIn4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
  -webkit-animation: SubMenuAnimIn5 0.4s ease;
  animation: SubMenuAnimIn5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
  0% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn5 {
  0% {
    -webkit-transform: translateZ(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn1 {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn5 {
  0% {
    -webkit-transform: translateZ(-200px);
    transform: translateZ(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
  -webkit-animation: SubMenuAnimOut1 0.4s ease;
  animation: SubMenuAnimOut1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
  -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
  animation: SubMenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
  -webkit-animation: SubMenuAnimOut3 0.4s ease;
  animation: SubMenuAnimOut3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
  -webkit-animation: SubMenuAnimOut4 0.4s ease;
  animation: SubMenuAnimOut4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
  -webkit-animation: SubMenuAnimOut5 0.4s ease;
  animation: SubMenuAnimOut5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut3 {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut4 {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut5 {
  0% {
    -webkit-transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-200px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut1 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut3 {
  0% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut4 {
  0% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut5 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-200px);
    transform: translateZ(-200px);
    opacity: 0;
  }
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
  display: block;
}

.no-js .dl-menuwrapper li.dl-back {
  display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
  background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
  content: '';
}

/* Colors for demos */
/* Demo 1 */
.demo-1 .dl-menuwrapper button {
  background: #c62860;
}

.demo-1 .dl-menuwrapper button:hover,
.demo-1 .dl-menuwrapper button.dl-active,
.demo-1 .dl-menuwrapper ul {
  background: #9e1847;
}

/* Demo 2 */
.demo-2 .dl-menuwrapper button {
  background: #e86814;
}

.demo-2 .dl-menuwrapper button:hover,
.demo-2 .dl-menuwrapper button.dl-active,
.demo-2 .dl-menuwrapper ul {
  background: #D35400;
}

/* Demo 3 */
.demo-3 .dl-menuwrapper button {
  background: #08cbc4;
}

.demo-3 .dl-menuwrapper button:hover,
.demo-3 .dl-menuwrapper button.dl-active,
.demo-3 .dl-menuwrapper ul {
  background: #00b4ae;
}

/* Demo 4 */
.demo-4 .dl-menuwrapper button {
  background: #90b912;
}

.demo-4 .dl-menuwrapper button:hover,
.demo-4 .dl-menuwrapper button.dl-active,
.demo-4 .dl-menuwrapper ul {
  background: #79a002;
}

/* Demo 5 */
.demo-5 .dl-menuwrapper button {
  background: #744783;
}

.demo-5 .dl-menuwrapper button:hover,
.demo-5 .dl-menuwrapper button.dl-active,
.demo-5 .dl-menuwrapper ul {
  background: #643771;
}

.form-group {
  position: relative;
  margin-bottom: 35px;
}

@media (min-width: 1920px) {
  .form-group {
    margin-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .account-page .form .form-group .btn {
    display: block;
    width: 100%;
    margin-top: 28px;
    float: none;
  }
}

.account-page .form .form-group .btn {
  margin-bottom: 5px;
}

.form-control {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0px 0px 10px;
  border: none;
  border-radius: 0;
  font-size: 0.875rem;
  color: #222430;
  background-color: transparent;
  border-bottom: 1px solid #dfe0e5;
}

@media (min-width: 1920px) {
  .form-control {
    font-size: 1.25rem;
    padding-bottom: 12px;
  }
}

.form-control::-webkit-input-placeholder {
  color: rgba(34, 36, 48, 0.4);
}

.form-control::-moz-placeholder {
  color: rgba(34, 36, 48, 0.4);
}

.form-control:-ms-input-placeholder {
  color: rgba(34, 36, 48, 0.4);
}

.form-control:-moz-placeholder {
  color: rgba(34, 36, 48, 0.4);
}

.form-control:focus,
.has-active .form-control {
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border-color: #88df00;
}

.form-control.has-success {
  background-image: url("../img/form-success.svg");
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 24px 24px;
}

.form-control.has-error {
  background-image: url("../img/form-alert.svg");
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 24px 24px;
}

textarea.form-control {
  display: block;
}

.form-notice {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  font-size: .625rem;
}

@media (min-width: 1920px) {
  .form-notice {
    font-size: .75rem;
  }
}

.control-label {
  display: block;
  margin-bottom: 4px;
  line-height: 1;
  font-size: .5625rem;
  color: rgba(34, 36, 48, 0.4);
}

@media (min-width: 1920px) {
  .control-label {
    font-size: .75rem;
  }
}

.select-wrapper {
  position: relative;
  margin-top: 0px;
}

.select-wrapper select {
  display: inline-block;
  width: 100%;
  padding: 0px 20px 10px 0px;
  line-height: 1.5;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  border-bottom: 1px solid #dfe0e5;
}

@media (min-width: 1920px) {
  .select-wrapper select {
    padding-bottom: 12px;
  }
}

body:not(.cursor-is--active) .select-wrapper select {
  cursor: pointer;
}

.select-wrapper:after, .select-wrapper:before {
  content: "";
  position: absolute;
  background-color: #88df00;
  transition: opacity 300ms ease;
}

.select-wrapper:before {
  top: 12px;
  right: 0;
  width: 6px;
  height: 2px;
}

@media (min-width: 1920px) {
  .select-wrapper:before {
    top: 15px;
    width: 9px;
    height: 3px;
  }
}

.select-wrapper:after {
  top: 10px;
  right: 2px;
  width: 2px;
  height: 6px;
}

@media (min-width: 1920px) {
  .select-wrapper:after {
    top: 12px;
    right: 3px;
    width: 3px;
    height: 9px;
  }
}

.checkbox {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  line-height: 1.2;
}

@media (min-width: 1920px) {
  .checkbox {
    padding-top: 6px;
    padding-left: 37px;
    font-size: 1.125rem;
  }
}

.checkbox-inline {
  display: inline-flex;
}

.checkbox-label {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #dfe0e5;
}

@media (min-width: 1920px) {
  .checkbox-label {
    top: 4px;
    width: 24px;
    height: 24px;
  }
}

.checkbox-label:after, .checkbox-label:before {
  content: "";
}

.checkbox-label:after, .checkbox-label:before {
  position: absolute;
  top: 6px;
  left: 3px;
  width: 8px;
  height: 2px;
  content: "";
  opacity: 0;
  background-color: #88df00;
  transition: opacity 300ms ease;
}

@media (min-width: 1920px) {
  .checkbox-label:after, .checkbox-label:before {
    top: 10px;
    left: 4px;
    width: 14px;
    height: 3px;
  }
}

.checkbox-label:after {
  transform: rotate(45deg);
}

.checkbox-label:before {
  transform: rotate(-45deg);
}

.checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-input:checked ~ .checkbox-label:after, .checkbox-input:checked ~ .checkbox-label:before {
  opacity: 1;
}

.checkbox-input:focus ~ .custom-control-label:after, .checkbox-input:focus ~ .custom-control-label:before {
  opacity: 1;
}

.checkbox-input:active ~ .custom-control-label:after, .checkbox-input:active ~ .custom-control-label:before {
  opacity: 1;
}

.filter-menu {
  padding-left: 0;
  list-style: none;
  display: block;
  position: absolute;
  right: -208px;
  visibility: hidden;
  z-index: 99999;
  opacity: 0;
  transition: all 0s ease-in-out;
}

@media (min-width: 992px) {
  .filter-menu {
    right: -198px;
  }
}

@media (min-width: 1920px) {
  .filter-menu {
    right: -228px;
  }
}

.site-header--top .filter-menu {
  top: 100%;
  transform: translateY(-1rem);
  border-top: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--top .filter-menu {
    border-width: 4px;
  }
}

.site-header--bottom .filter-menu {
  bottom: 100%;
  transform: translateY(1rem);
  border-bottom: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--bottom .filter-menu {
    border-width: 4px;
  }
}

.header-filter-toggle:hover .filter-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
  transition-duration: .3s;
}

.filter-menu__form {
  width: 100%;
  display: flex;
  background-color: #151720;
}

.filter-menu__select {
  position: relative;
  width: 180px;
  border-right: 1px solid #242633;
}

@media (min-width: 1920px) {
  .filter-menu__select {
    width: 220px;
  }
  .filter-menu__select .cs-placeholder {
    padding-top: 47px;
    padding-bottom: 35px;
    font-size: 1.125rem;
    letter-spacing: -.03em;
  }
}

.filter-menu__submit {
  padding: 20px;
}

@media (min-width: 1920px) {
  .filter-menu__submit {
    padding-top: 30px;
  }
}

@media (min-width: 1920px) {
  .filter-menu__submit button {
    font-size: .75rem;
    padding: .875rem 1.875rem;
  }
}

.filter-menu__label {
  position: absolute;
  top: 24px;
  left: 24px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: .625rem;
  letter-spacing: -.03em;
  text-transform: uppercase;
  line-height: 1;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .filter-menu__label {
    top: 35px;
    font-size: .703125rem;
  }
}

@media (min-width: 1920px) {
  .filter-menu .cs-select > span:after {
    top: 54px;
  }
}

@media (min-width: 1920px) {
  .filter-menu .cs-select > span:before {
    top: 52px;
  }
}

.search-form--header .form-control {
  padding-bottom: 0;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
}

@media (min-width: 768px) {
  .search-form--header .form-control {
    font-size: 2.875rem;
  }
}

@media (min-width: 1920px) {
  .search-form--header .form-control {
    font-size: 4rem;
  }
}

.search-form--header .form-control::-webkit-input-placeholder {
  color: #fff;
  transition: all ease 300ms;
}

.search-form--header .form-control::-moz-placeholder {
  color: #fff;
  transition: all ease 300ms;
}

.search-form--header .form-control:-ms-input-placeholder {
  color: #fff;
  transition: all ease 300ms;
}

.search-form--header .form-control:-moz-placeholder {
  color: #fff;
  transition: all ease 300ms;
}

.search-form--header .form-control:hover::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-form--header .form-control:hover::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-form--header .form-control:hover:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-form--header .form-control:hover:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-form--header .form-control:focus {
  border-color: #dfe0e5;
}

.search-form--header .form-control:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.search-form--header .form-control:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.search-form--header .form-control:focus:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.search-form--header .form-control:focus:-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.search-form--header + span {
  display: inline-block;
  margin-top: 1.5em;
  font-size: .75rem;
  letter-spacing: -.03em;
  line-height: 1.25;
  color: #c6cbea;
}

@media (min-width: 768px) {
  .search-form--header + span {
    margin-top: 2em;
  }
}

@media (min-width: 1920px) {
  .search-form--header + span {
    font-size: 1rem;
  }
}

.form::after,
.review-form::after,
.comment-form::after {
  display: block;
  clear: both;
  content: "";
}

.form .btn,
.form button,
.review-form .btn,
.review-form button,
.comment-form .btn,
.comment-form button {
  margin-top: 5px;
  float: right;
}

@media (min-width: 1920px) {
  .form .btn,
  .form button,
  .review-form .btn,
  .review-form button,
  .comment-form .btn,
  .comment-form button {
    margin-top: 10px;
  }
}

.form > .form-group:last-child,
.review-form > .form-group:last-child,
.comment-form > .form-group:last-child {
  margin-bottom: 0;
}

.form .form-group--password-forgot + .btn,
.form .form-group--password-forgot + button,
.review-form .form-group--password-forgot + .btn,
.review-form .form-group--password-forgot + button,
.comment-form .form-group--password-forgot + .btn,
.comment-form .form-group--password-forgot + button {
  margin-top: 1px;
}

.form .btn + .btn,
.review-form .btn + .btn,
.comment-form .btn + .btn {
  margin-top: 14px;
}

@media (min-width: 1920px) {
  .form .btn + .btn,
  .review-form .btn + .btn,
  .comment-form .btn + .btn {
    margin-top: 18px;
  }
}

.login-form,
.register-form {
  width: 100%;
  max-width: 320px;
}

@media (max-width: 1439px) {
  .login-form,
  .register-form {
    margin: 0 auto;
  }
}

@media (min-width: 1920px) {
  .login-form,
  .register-form {
    max-width: 440px;
  }
}

.login-form__info,
.register-form__info {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 22px;
  font-size: .75rem;
  font-weight: bold;
  line-height: 1.167;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .login-form__info,
  .register-form__info {
    margin-top: 36px;
    margin-bottom: 25px;
    font-size: 1.125rem;
  }
}

.login-form__info:last-child,
.register-form__info:last-child {
  margin-bottom: 0;
}

.h4 + .login-form, .h4 +
.register-form {
  margin-top: 38px;
}

@media (min-width: 768px) {
  .h4 + .login-form, .h4 +
  .register-form {
    margin-top: 66px;
  }
}

.login-form__button, .login-form__twitter-button, .login-form__facebook-button {
  color: #fff;
}

.login-form__button:hover, .login-form__twitter-button:hover, .login-form__facebook-button:hover {
  color: #fff;
}

@media (min-width: 1920px) {
  .login-form__info {
    margin-top: 56px;
  }
}

.login-form__button {
  background-color: #222430;
}

@media (min-width: 1920px) {
  .login-form__button {
    margin-top: 5px;
  }
}

.login-form__button:hover {
  background-color: #373a4e;
}

.login-form .login-form__button {
  margin-top: 0;
}

.login-form__twitter-button {
  background-color: #09aafe;
}

.login-form__twitter-button:hover {
  background-color: #3cbcfe;
}

.login-form__facebook-button {
  background-color: #224bc0;
}

.login-form__facebook-button:hover {
  background-color: #3963dc;
}

.login-form .password-reminder {
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .login-form .password-reminder {
    margin-top: 7px;
  }
}

.login-form .password-reminder a {
  color: #ced0da;
}

.login-form .password-reminder a:hover {
  color: #222430;
}

.df-icon {
  fill: #a3ff12;
  stroke-width: 2px;
}

.df-icon--match-replay {
  transform: translateX(8%);
}

.widget-twitch .df-icon--twitch-word-logo {
  width: 88px;
  height: 30px;
  fill: #fff;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .widget-twitch .df-icon--twitch-word-logo,
  .blog-layout--style-2 .widget-twitch .df-icon--twitch-word-logo,
  .blog-layout--style-3 .widget-twitch .df-icon--twitch-word-logo,
  .blog-layout--style-4 .widget-twitch .df-icon--twitch-word-logo {
    width: 11.45833vh;
    height: 3.90625vh;
  }
}

.add-icon {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 3px solid #fff;
  transition: border-color 300ms ease;
  box-sizing: border-box;
}

@media (min-width: 1920px) {
  .add-icon {
    width: 34px;
    height: 34px;
    border-width: 4px;
  }
}

.add-icon:after, .add-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 2px;
  content: "";
  background-color: #a3ff12;
  transition: background-color 300ms ease;
}

@media (min-width: 1920px) {
  .add-icon:after, .add-icon:before {
    width: 14px;
    height: 4px;
  }
}

.add-icon:after {
  transform: translate(-50%, -50%);
}

.add-icon:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.add-icon:hover {
  border-color: #a3ff12;
}

.info-box__label, .info-box__content {
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

.info-box__label {
  font-size: .75rem;
  line-height: .95;
  color: #ced0da;
}

.menu-panel .info-box__label {
  margin-bottom: -1px;
  font-size: .625rem;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .menu-panel .info-box__label {
    font-size: .875rem;
  }
}

@media (min-width: 1920px) {
  .contact-us-page .info-box__label {
    font-size: .875rem;
  }
}

@media (min-width: 1920px) {
  .contact-us-page .info-box__content {
    margin-top: -3px;
  }
}

@media (min-width: 1920px) {
  .menu-panel .info-box__content {
    font-size: 1.125rem;
  }
}

.menu-panel .info-box__content, .menu-panel
.info-box__content a, .menu-panel
.info-box__content a[href^="mailto:"] {
  color: #fff;
}

@media (min-width: 1920px) {
  .contact-us-page .info-box__content a[href^="mailto:"] {
    font-size: 1.125rem;
  }
}

* + .info-box {
  margin-top: 31px;
}

@media (min-width: 1920px) {
  * + .info-box {
    margin-top: 36px;
  }
}

.menu-panel * + .info-box {
  margin-top: 25px;
}

.menu-panel .info-box + .info-box {
  margin-top: 19px;
}

@media (min-width: 1920px) {
  .info-box + .info-box {
    margin-top: 25px;
  }
}

.lightbox .mp_iframe {
  display: block;
}

.lightbox img {
  width: 100%;
}

.lightbox--video .mp_iframe {
  position: relative;
}

.lightbox--video .mp_iframe:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  transition: background-color 300ms ease;
}

.lightbox--video:hover .mp_iframe:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.lightbox__icon {
  position: absolute;
  right: 50%;
  bottom: 50%;
  display: block;
  overflow: hidden;
  border-radius: 20%;
  transform: translateX(50%) translateY(75%) rotate(0deg) skewY(30deg) scaleX(0.866) translateZ(0px);
}

.lightbox__icon, .lightbox__icon:after, .lightbox__icon:before {
  width: 1.375rem;
  height: 1.375rem;
}

@media (min-width: 1920px) {
  .lightbox__icon, .lightbox__icon:after, .lightbox__icon:before {
    width: 2rem;
    height: 2rem;
  }
}

.lightbox__icon:after, .lightbox__icon:before {
  position: absolute;
  background: #fff;
  pointer-events: auto;
  content: "";
}

.lightbox__icon:after {
  border-radius: 20% 20% 53% 20%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%) translateZ(0px);
}

.lightbox__icon:before {
  border-radius: 20% 20% 20% 53%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%) translateZ(0px);
}

.header-logo--img {
  z-index: 1;
  transition: .3s all ease;
}

.site-wrapper--has-menu-overlay .header-logo--img {
  opacity: 0;
}

.site-layout--landing .header-logo--img {
  opacity: 1;
  transition: 0.7s 0.6s all cubic-bezier(0.86, 0, 0.07, 1);
}

.preloader-is--active .site-layout--landing .header-logo--img {
  opacity: 0;
  transform: translateY(-50%) scale(1.5);
}

.site-header--top .header-logo--img,
.site-header--bottom .header-logo--img {
  position: absolute;
  left: 28px;
}

@media (max-width: 991.98px) {
  .site-header--top .header-logo--img,
  .site-header--bottom .header-logo--img {
    max-width: 90px;
    left: 20px;
  }
  .site-header--top .header-logo--img img,
  .site-header--bottom .header-logo--img img {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 1920px) {
  .site-header--top .header-logo--img,
  .site-header--bottom .header-logo--img {
    left: 46px;
  }
}

.site-header--top .header-logo--img {
  top: 12px;
}

@media (min-width: 1920px) {
  .site-header--top .header-logo--img {
    top: 20px;
  }
}

.site-header--bottom .header-logo--img {
  bottom: 12px;
}

@media (max-width: 991.98px) {
  .site-header--bottom .header-logo--img {
    bottom: 6px;
  }
}

@media (min-width: 1920px) {
  .site-header--bottom .header-logo--img {
    bottom: 20px;
  }
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom .header-logo--img {
    top: 6px;
    bottom: auto;
  }
}

.site-header--landing .header-logo--img {
  z-index: 3;
  padding-left: 48px;
  padding-right: 48px;
}

@media (min-width: 992px) {
  .site-header--landing .header-logo--img {
    padding-left: 86px;
    padding-right: 86px;
  }
}

.header-logo--img img {
  max-width: 140px;
}

@media (min-width: 992px) and (max-width: 1919px) {
  .site-header--landing .header-logo--img img {
    max-width: 18.229167vh;
  }
}

@media (min-width: 1920px) {
  .header-logo--img img {
    max-width: 200px;
  }
}

.gm-map--aside {
  display: block;
  width: calc(100% + 48px);
  height: 400px;
  margin: 60px -24px -60px;
}

@media (min-width: 992px) {
  .gm-map--aside {
    position: fixed !important;
    left: 0;
    width: calc(100% - 606px);
    height: calc(100vh - 72px);
    margin: 0;
  }
  .site-header--top ~ .site-content .gm-map--aside {
    bottom: 0;
  }
  .site-header--bottom ~ .site-content .gm-map--aside {
    top: 0;
  }
}

@media (min-width: 1440px) {
  .gm-map--aside {
    width: calc(100% - 860px);
  }
}

.gm-map .gm-style br {
  background-color: transparent;
}

.gm-map .gm-style .gm-style-iw-t:after, .gm-map .gm-style .gm-style-iw-t:before {
  display: none;
}

.gm-map .gm-style .gm-style-iw-c {
  padding: 13px 22px 12px !important;
  border-radius: 0;
  box-shadow: none;
  background-color: #151720;
}

.gm-map .gm-style .gm-ui-hover-effect {
  opacity: .3;
  transition: opacity 300ms ease;
}

.gm-map .gm-style .gm-ui-hover-effect img {
  display: none !important;
}

.gm-map .gm-style .gm-ui-hover-effect:after, .gm-map .gm-style .gm-ui-hover-effect:before {
  content: "";
  position: absolute;
  top: 14px;
  right: 12px;
  width: 8px;
  height: 2px;
  background-color: #fff;
}

.gm-map .gm-style .gm-ui-hover-effect:after {
  transform: rotate(45deg);
}

.gm-map .gm-style .gm-ui-hover-effect:before {
  transform: rotate(-45deg);
}

.gm-map .gm-style .gm-ui-hover-effect:hover {
  opacity: 1;
}

.gm-map .gm-style .gm-style-iw-d {
  overflow: hidden !important;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  line-height: .75rem;
  font-size: .875rem;
  letter-spacing: -.03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.gm-map .gm-style .gm-style-iw-d address {
  display: block;
  margin-bottom: 0;
  padding-top: 7px;
  font-size: .75rem;
  color: #5e627e;
}

.main-nav {
  display: none;
}

@media (min-width: 1200px) {
  .main-nav {
    display: block;
    transition: .3s all ease;
  }
  .site-wrapper--has-menu-overlay .main-nav {
    opacity: 0;
  }
}

.main-nav ul {
  padding-left: 0;
  list-style: none;
}

.main-nav ul li {
  position: relative;
}

.main-nav ul li a {
  font-weight: 700;
  letter-spacing: -.03em;
  line-height: 1.25;
  text-transform: uppercase;
  transition: all 240ms ease;
}

.main-nav ul li a:hover {
  color: #a3ff12;
}

.main-nav__list a {
  color: #fff;
}

.main-nav__list li.active > a {
  color: #a3ff12;
}

.main-nav__list > li {
  display: inline-block;
}

.main-nav__list > li:first-child {
  padding-left: 0;
}

.main-nav__list > li:last-child {
  padding-right: 0;
}

@media (min-width: 1200px) {
  .main-nav__list > li {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

@media (min-width: 1920px) {
  .main-nav__list > li {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.main-nav__list > li > a {
  font-size: 0.875rem;
  display: inline-block;
  padding-top: 28px;
  padding-bottom: 27px;
}

@media (min-width: 1920px) {
  .main-nav__list > li > a {
    font-size: 1rem;
    padding-top: 41px;
    padding-bottom: 39px;
  }
}

.main-nav__sub {
  display: block;
  float: left;
  position: absolute;
  left: 6px;
  visibility: hidden;
  z-index: 99999;
  opacity: 0;
  transition: all 0s ease-in-out;
}

.main-nav__list > li:first-child .main-nav__sub {
  left: -24px;
}

@media (min-width: 1920px) {
  .main-nav__sub {
    left: 12px;
  }
  .main-nav__list > li:first-child .main-nav__sub {
    left: -36px;
  }
  .site-header--landing .main-nav__list > li:first-child .main-nav__sub {
    left: -24px;
  }
}

.site-header--top .main-nav__sub {
  top: 100%;
  transform: translateY(-1rem);
  border-top: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--top .main-nav__sub {
    border-width: 4px;
  }
}

.site-header--landing .main-nav__sub {
  transform: translateY(-1rem);
}

.site-header--bottom .main-nav__sub {
  bottom: 100%;
  transform: translateY(1rem);
  border-bottom: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--bottom .main-nav__sub {
    border-width: 4px;
  }
}

.main-nav__sub ul {
  left: 99%;
}

.site-header--top .main-nav__sub ul,
.site-header--bottom .main-nav__sub ul,
.site-header--landing .main-nav__sub ul {
  transform: translateX(-1rem);
}

.site-header--top .main-nav__sub ul {
  top: 0;
  border-top: 0;
}

.site-header--bottom .main-nav__sub ul {
  top: 0;
  border-bottom: 0;
}

.site-header--landing .main-nav__sub ul {
  top: 0;
}

.main-nav__sub li {
  border-left: 2px solid transparent;
}

@media (min-width: 1920px) {
  .main-nav__sub li {
    border-width: 4px;
  }
}

.main-nav__sub li:hover > ul,
.main-nav__sub li.focus > ul {
  left: 100%;
}

.main-nav__sub li a {
  display: block;
  width: 200px;
  padding: 16px 44px 16px 24px;
  font-size: .75rem;
  background-color: #151720;
  border-bottom: 1px solid #242633;
}

@media (min-width: 1920px) {
  .main-nav__sub li a {
    width: 280px;
    padding: 24px 44px 24px 35px;
    font-size: .875rem;
  }
}

.main-nav__sub li:last-child a {
  border-bottom: none;
}

.main-nav li:hover > ul,
.main-nav li.focus > ul,
li:hover > .main-nav__megamenu,
li.focus > .main-nav__megamenu {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
  transition-duration: .3s;
}

.site-header--landing .main-nav {
  display: none;
}

@media (min-width: 768px) {
  .site-header--landing .main-nav {
    display: block;
  }
}

.site-header--landing .main-nav__list > li {
  padding-left: 1rem;
  padding-right: 1rem;
}

.site-header--landing .main-nav__list > li:first-child {
  padding-left: 0;
}

.site-header--landing .main-nav__list > li:last-child {
  padding-right: 0;
}

@media (min-width: 992px) {
  .site-header--landing .main-nav__list > li {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.site-layout--landing .main-nav {
  opacity: 1;
  transition: 0.7s 0.6s all cubic-bezier(0.86, 0, 0.07, 1);
}

.preloader-is--active .site-layout--landing .main-nav {
  opacity: 0;
  transform: translateY(-50%) scale(1.5);
}

.main-nav__toggle,
.main-nav__toggle-2 {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 6px;
  height: 2px;
  margin-left: 6px;
  vertical-align: middle;
  background-color: #a3ff12;
}

.main-nav__toggle:before,
.main-nav__toggle-2:before {
  position: absolute;
  top: -2px;
  left: 2px;
  width: 2px;
  height: 6px;
  content: "";
  background-color: #a3ff12;
  transition: opacity 300ms ease;
}

li:hover > .main-nav__toggle:before,
li.focus > .main-nav__toggle:before, li:hover >
.main-nav__toggle-2:before,
li.focus >
.main-nav__toggle-2:before {
  opacity: 0;
}

.main-nav__list ul .main-nav__toggle, .main-nav__list ul
.main-nav__toggle-2 {
  position: absolute;
  top: 23px;
  right: 18px;
}

@media (min-width: 1920px) {
  .main-nav__list ul .main-nav__toggle, .main-nav__list ul
  .main-nav__toggle-2 {
    top: 33px;
    right: 24px;
  }
}

.mobile-bar-item .main-nav__toggle, .mobile-bar-item
.main-nav__toggle-2 {
  position: absolute;
  top: 27px;
  right: 24px;
}

.mobile-bar-item .mobile-bar-item__header:not(.collapsed) .main-nav__toggle:before, .mobile-bar-item .mobile-bar-item__header:not(.collapsed)
.main-nav__toggle-2:before {
  opacity: 0;
}

.main-nav__megamenu {
  position: fixed;
  left: 0;
  right: 0;
  padding: 39px 100px 84px;
  visibility: hidden;
  opacity: 0;
  transition: all 0s ease-in-out;
}

.site-header--top .main-nav__megamenu {
  padding-top: 41px;
  padding-bottom: 82px;
}

@media (min-width: 1440px) {
  .main-nav__megamenu {
    padding: 39px 202px 54px;
  }
  .site-header--top .main-nav__megamenu {
    padding-top: 41px;
    padding-bottom: 52px;
  }
}

@media (min-width: 1920px) {
  .main-nav__megamenu {
    padding: 54px 326px 74px;
  }
  .site-header--top .main-nav__megamenu {
    padding-top: 56px;
    padding-bottom: 72px;
  }
}

.main-nav__megamenu:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151720;
}

.main-nav__megamenu ul {
  padding-left: 15px !important;
}

.main-nav__megamenu ul li + li {
  margin-top: 13px;
}

@media (min-width: 1920px) {
  .main-nav__megamenu ul li + li {
    margin-top: 17px;
  }
}

.main-nav__megamenu a {
  display: block;
  font-size: .625rem;
  vertical-align: top;
}

@media (min-width: 1920px) {
  .main-nav__megamenu a {
    font-size: .875rem;
  }
}

.site-header--top .main-nav__megamenu {
  top: calc(72px);
  transform: translateY(-1rem);
  border-top: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--top .main-nav__megamenu {
    top: calc(100px);
    border-width: 4px;
  }
}

.site-header--bottom .main-nav__megamenu {
  bottom: calc(72px);
  transform: translateY(1rem);
  border-bottom: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--bottom .main-nav__megamenu {
    bottom: calc(100px);
    border-width: 4px;
  }
}

.site-header--landing .main-nav__megamenu {
  z-index: 2;
  transform: translateY(-1rem);
}

.main-nav__title {
  margin-bottom: 30px;
  font-size: .875rem;
  color: #fff;
}

@media (min-width: 1920px) {
  .main-nav__title {
    margin-bottom: 43px;
    font-size: 1.125rem;
  }
}

.mobile-nav ul {
  padding-left: 0;
  list-style: none;
  padding: 0;
}

.mobile-nav li.active > a {
  color: #a3ff12;
}

.mobile-nav a {
  position: relative;
  display: block;
  font-size: .75rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -.03em;
  color: #fff;
}

.mobile-nav ul ul a {
  font-size: .625rem;
  padding: 5px 0 4px 34px;
  margin-right: 54px;
}

.mobile-nav__list > li > a {
  padding: 15px 0 13px 34px;
  margin-right: 54px;
}

.mobile-nav__list > li + li {
  border-top: 1px solid #242633;
}

.mobile-nav__list li {
  position: relative;
}

.mobile-nav__sub {
  margin-bottom: 10px;
}

.mobile-nav__sub-2, .mobile-nav__sub-3 {
  padding-left: 15px !important;
}

.mobile-nav__sub, .mobile-nav__sub-2, .mobile-nav__sub-3 {
  display: none;
}

.mobile-nav__toggle,
.mobile-nav__toggle-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
}

.mobile-nav__toggle:after, .mobile-nav__toggle:before,
.mobile-nav__toggle-2:after,
.mobile-nav__toggle-2:before {
  position: absolute;
  content: "";
  background-color: #a3ff12;
}

.mobile-nav__toggle:before,
.mobile-nav__toggle-2:before {
  top: calc(50% - 3px);
  left: calc(50% - 1px);
  width: 2px;
  height: 6px;
  transition: opacity 300ms ease;
}

.mobile-nav__toggle:after,
.mobile-nav__toggle-2:after {
  top: calc(50% - 1px);
  left: calc(50% - 3px);
  width: 6px;
  height: 2px;
}

.mobile-nav__toggle.active:before,
.mobile-nav__toggle-2.active:before {
  opacity: 0;
}

.mobile-nav__toggle {
  height: 48px;
}

.mobile-nav__toggle-2 {
  height: 26px;
}

.color-pallete-item {
  position: relative;
  width: 120px;
  height: 120px;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: -.03em;
  line-height: 122px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .color-pallete-item {
    margin-bottom: 20px;
  }
}

.color-pallete-item:before {
  content: '';
  position: absolute;
  top: 18px;
  left: 18px;
  width: 85px;
  height: 85px;
  border-width: 16px;
  border-style: solid;
  border-color: inherit;
  transform: rotate(45deg);
}

.landing-detail,
.landing-detail-cover {
  display: none;
}

@media (min-width: 768px) {
  .landing-detail,
  .landing-detail-cover {
    position: fixed;
    top: 50%;
    display: block;
    width: 398px;
    height: 398px;
    transform-origin: top;
    transform: scale(0.5) translateY(-50%);
  }
}

@media (min-width: 992px) {
  .landing-detail,
  .landing-detail-cover {
    transform: scale(0.75) translateY(-50%);
  }
}

@media (min-width: 1200px) {
  .landing-detail,
  .landing-detail-cover {
    transform: translateY(-50%);
  }
}

.landing-detail span,
.landing-detail-cover span {
  position: absolute;
  top: 50%;
  border-style: solid;
  transform: rotate(45deg);
}

.landing-detail span:first-child,
.landing-detail-cover span:first-child {
  width: 100px;
  height: 100px;
  margin-top: -50px;
  border-width: 32px;
}

.landing-detail span:nth-child(2),
.landing-detail-cover span:nth-child(2) {
  width: 200px;
  height: 200px;
  margin-top: -100px;
  border-width: 26px;
}

.landing-detail span:last-child,
.landing-detail-cover span:last-child {
  width: 282px;
  height: 282px;
  margin-top: -141px;
  border-width: 20px;
}

.landing-detail--left,
.landing-detail-cover--left {
  left: -199px;
}

.landing-detail--left span,
.landing-detail-cover--left span {
  left: 50%;
}

.preloader-is--active.site-layout--landing .landing-detail--left span, .preloader-is--active.site-layout--landing
.landing-detail-cover--left span {
  left: -100%;
}

.landing-detail--left span:first-child,
.landing-detail-cover--left span:first-child {
  margin-left: -50px;
}

.site-layout--landing .landing-detail--left span:first-child, .site-layout--landing
.landing-detail-cover--left span:first-child {
  transition: 0.7s 0.8s left cubic-bezier(0.86, 0, 0.07, 1);
}

.landing-detail--left span:nth-child(2),
.landing-detail-cover--left span:nth-child(2) {
  margin-left: -100px;
}

.site-layout--landing .landing-detail--left span:nth-child(2), .site-layout--landing
.landing-detail-cover--left span:nth-child(2) {
  transition: 0.7s 1s left cubic-bezier(0.86, 0, 0.07, 1);
}

.landing-detail--left span:last-child,
.landing-detail-cover--left span:last-child {
  margin-left: -141px;
}

.site-layout--landing .landing-detail--left span:last-child, .site-layout--landing
.landing-detail-cover--left span:last-child {
  transition: 0.7s 1.2s left cubic-bezier(0.86, 0, 0.07, 1);
}

.landing-detail--right,
.landing-detail-cover--right {
  right: -199px;
}

.landing-detail--right span,
.landing-detail-cover--right span {
  right: 50%;
}

.preloader-is--active.site-layout--landing .landing-detail--right span, .preloader-is--active.site-layout--landing
.landing-detail-cover--right span {
  right: -100%;
}

.landing-detail--right span:first-child,
.landing-detail-cover--right span:first-child {
  margin-right: -50px;
}

.site-layout--landing .landing-detail--right span:first-child, .site-layout--landing
.landing-detail-cover--right span:first-child {
  transition: 0.7s 0.8s right cubic-bezier(0.86, 0, 0.07, 1);
}

.landing-detail--right span:nth-child(2),
.landing-detail-cover--right span:nth-child(2) {
  margin-right: -100px;
}

.site-layout--landing .landing-detail--right span:nth-child(2), .site-layout--landing
.landing-detail-cover--right span:nth-child(2) {
  transition: 0.7s 1s right cubic-bezier(0.86, 0, 0.07, 1);
}

.landing-detail--right span:last-child,
.landing-detail-cover--right span:last-child {
  margin-right: -141px;
}

.site-layout--landing .landing-detail--right span:last-child, .site-layout--landing
.landing-detail-cover--right span:last-child {
  transition: 0.7s 1.2s right cubic-bezier(0.86, 0, 0.07, 1);
}

.landing-detail span {
  border-color: #68ff01;
}

.landing-detail-cover {
  clip: rect(0, 398px, 184px, 0);
}

.landing-detail-cover span {
  border-color: #ccff3a;
}

.site-overlay {
  left: 0;
  z-index: 1100;
  width: 100%;
  height: calc(100% - 72px);
  position: fixed;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s,opacity 320ms ease;
}

body:not(.cursor-is--active) .site-overlay {
  cursor: pointer;
}

@media (min-width: 1920px) {
  .site-overlay {
    height: calc(100% - 100px);
  }
}

.site-header--top ~ .site-overlay {
  bottom: 0;
}

.site-header--bottom ~ .site-overlay {
  top: 0;
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom ~ .site-overlay {
    top: 72px;
    bottom: 0;
  }
}

.site-wrapper--has-overlay .site-overlay,
.site-wrapper--has-redeem-overlay .site-overlay {
  visibility: visible;
  opacity: 1;
  background-color: rgba(21, 23, 32, 0.96);
}

.site-wrapper--has-redeem-overlay .site-overlay {
  height: 100%;
  z-index: 1106;
}

.page-heading--default {
  margin-bottom: 38px;
}

@media (min-width: 576px) {
  .page-heading--default {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .page-heading--default {
    width: 70%;
    margin-bottom: 65px;
  }
}

@media (min-width: 1920px) {
  .page-heading--default {
    width: 80%;
  }
}

.page-heading--default h1, .page-heading--default .h1, .page-heading--default h2, .page-heading--default .h2, .page-heading--default h3, .page-heading--default .h3, .page-heading--default h4, .page-heading--default .h4, .page-heading--default h5, .page-heading--default .h5, .page-heading--default h6, .page-heading--default .h6 {
  color: #fff;
}

.page-heading.page-heading--loop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 60px 15px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 576px) {
  .page-heading.page-heading--loop {
    padding-left: 60px;
    padding-right: 60px;
    height: calc(50vh - 72px);
  }
  .page-heading.page-heading--loop.page-heading--streams-archive {
    height: auto;
  }
}

@media (min-width: 992px) {
  .page-heading.page-heading--loop {
    width: 536px;
    height: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1920px) {
  .page-heading.page-heading--loop {
    width: 740px;
  }
}

@media (max-width: 991.98px) {
  .page-heading--streams-archive .page-heading__title {
    font-size: 4rem;
  }
}

@media (max-width: 767.98px) {
  .page-heading--streams-archive .page-heading__title {
    font-size: 3rem;
  }
}

.page-heading--streams-archive .page-heading__body svg {
  fill: #fff;
  width: 88px;
  height: 28px;
}

@media (min-width: 1920px) {
  .page-heading--streams-archive .page-heading__body svg {
    width: 120px;
    height: 40px;
  }
}

.page-heading--streams-archive .page-heading__body svg + * {
  margin-top: 38px;
}

@media (min-width: 1920px) {
  .page-heading--streams-archive .page-heading__body svg + * {
    margin-top: 45px;
  }
}

.page-heading--streams-archive .page-heading__body h5, .page-heading--streams-archive .page-heading__body .h5 {
  font-size: 1.25rem;
  line-height: 1;
}

@media (min-width: 1920px) {
  .page-heading--streams-archive .page-heading__body h5, .page-heading--streams-archive .page-heading__body .h5 {
    font-size: 1.75rem;
  }
}

.page-heading--streams-archive .page-heading__body p {
  padding-left: 16%;
  padding-right: 16%;
  line-height: 1.25rem;
}

.page-heading--streams-archive .page-heading__body * + p {
  margin-top: 8px;
}

@media (min-width: 1920px) {
  .page-heading--streams-archive .page-heading__body * + p {
    margin-top: 18px;
  }
}

.page-heading--partners {
  padding-top: 2rem;
}

@media (min-width: 1920px) {
  .page-heading__title {
    font-size: 7.75rem;
  }
}

@media (min-width: 1920px) {
  .text-small .page-heading__title {
    font-size: 5.875rem;
  }
}

.page-heading--loop .page-heading__title {
  margin-bottom: 0;
}

@media (min-width: 1920px) {
  .page-heading--loop .page-heading__title {
    font-size: 5.875rem;
  }
}

.page-heading--default .page-heading__title {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .page-heading__subtitle {
    font-size: 1.25rem;
  }
}

@media (min-width: 1920px) {
  .page-heading__subtitle {
    font-size: 2.25rem;
  }
}

@media (min-width: 1920px) {
  .text-small .page-heading__subtitle {
    font-size: 1.625rem;
  }
}

.page-heading--loop .page-heading__subtitle {
  margin-bottom: 10px;
}

@media (min-width: 1920px) {
  .page-heading--loop .page-heading__subtitle {
    margin-bottom: 9px;
    font-size: 1.625rem;
  }
}

.page-heading--default .page-heading__subtitle {
  margin-bottom: 8px;
}

@media (min-width: 1920px) {
  .page-heading--default .page-heading__subtitle {
    margin-bottom: 14px;
  }
}

.page-heading--partners .page-heading__title + .page-heading__body {
  margin-top: 44px;
}

@media (min-width: 992px) {
  .page-heading--partners .page-heading__title + .page-heading__body {
    margin-top: 84px;
  }
}

@media (min-width: 1920px) {
  .page-heading--partners .page-heading__title + .page-heading__body {
    margin-top: 74px;
  }
}

.page-heading--streams-archive .page-heading__title + .page-heading__body {
  margin-top: 44px;
}

@media (min-width: 1920px) {
  .page-heading--streams-archive .page-heading__title + .page-heading__body {
    margin-top: 68px;
  }
}

.page-heading__body * {
  margin-bottom: 0;
}

.page-heading__body h1, .page-heading__body h2, .page-heading__body h3, .page-heading__body h4, .page-heading__body h5, .page-heading__body h6,
.page-heading__body .h1, .page-heading__body .h2, .page-heading__body .h3, .page-heading__body .h4, .page-heading__body .h5, .page-heading__body .h6 {
  color: #fff;
}

.page-heading p, .page-heading__title,
.page-heading a[href^="mailto:"] {
  color: #fff;
}

@media (min-width: 576px) {
  .content.partners-layout {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .content.partners-layout {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 50% 50%;
    grid-auto-columns: calc(50vh - 72px / 2);
  }
}

@media (min-width: 1920px) {
  .content.partners-layout {
    grid-auto-columns: calc(50vh - 100px / 2);
  }
}

.partner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vw;
  padding: 40px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
}

@media (min-width: 576px) {
  .partner {
    width: 50%;
    min-height: 50vw;
  }
}

@media (min-width: 992px) {
  .partner {
    width: 100%;
    min-height: auto;
  }
}

@media (min-width: 992px) {
  .partner {
    padding: 5.20833vh;
  }
}

.partner:nth-child(4n-1), .partner:nth-child(4n-2) {
  background-color: #f7f8fa;
}

.partner__logo, .partner__header {
  position: absolute;
  left: 0;
  width: 100%;
  transition: all 700ms ease;
}

.partner__logo {
  bottom: 50%;
}

.partner__logo img {
  max-width: 80%;
  margin: 0 auto 24px;
}

@media (min-width: 992px) {
  .partner__logo img {
    margin-bottom: 3.125vh;
  }
}

.partner__header {
  top: 50%;
}

.partner__title {
  margin-bottom: 1px;
}

@media (min-width: 992px) {
  .partner__title {
    margin-bottom: 0.13021vh;
    font-size: 4.16667vh;
  }
}

.partner__info {
  font-size: .75rem;
  font-weight: bold;
  letter-spacing: -.06em;
  text-transform: uppercase;
  color: #ced0da;
}

@media (min-width: 992px) {
  .partner__info {
    font-size: 1.5625vh;
  }
}

.partner__info:hover {
  color: #a3ff12;
}

.partner__excerpt {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  font-size: .75rem;
  line-height: 1.667;
  opacity: 0;
  transform: translateY(48px);
  transition: all 700ms ease;
}

@media (min-width: 992px) {
  .partner__excerpt {
    padding-left: 5.20833vh;
    padding-right: 5.20833vh;
    font-size: 1.5625vh;
    transform: translateY(6.25vh);
  }
}

.partner:hover .partner__logo,
.partner:hover .partner__header {
  transform: translateY(-38px);
}

@media (min-width: 992px) {
  .partner:hover .partner__logo,
  .partner:hover .partner__header {
    transform: translateY(-4.94792vh);
  }
}

.partner:hover .partner__excerpt {
  opacity: 1;
  transform: translateY(38px);
}

@media (min-width: 992px) {
  .partner:hover .partner__excerpt {
    transform: translateY(4.94792vh);
  }
}

.social-menu {
  padding-left: 0;
  list-style: none;
}

.social-menu li {
  display: inline-block;
}

.partner .social-menu li,
.staff-member .social-menu li {
  display: block;
  padding: 0;
  font-size: .625rem;
}

@media (min-width: 992px) {
  .partner .social-menu li,
  .staff-member .social-menu li {
    font-size: 1.30208vh;
  }
}

@media (min-width: 1920px) {
  .partner .social-menu li,
  .staff-member .social-menu li {
    font-size: 1.82292vh;
  }
}

@media (min-width: 992px) {
  .partner .social-menu li a,
  .staff-member .social-menu li a {
    width: 5.20833vh;
    height: 5.20833vh;
    line-height: 5.20833vh;
  }
}

.social-menu.social-menu--header a:before {
  font-size: 1.5rem;
}

@media (min-width: 1920px) {
  .social-menu.social-menu--header a:before {
    font-size: 1.875rem;
  }
}

.partner .social-menu,
.staff-member .social-menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.social-menu.social-menu--header {
  display: block;
  width: 260px;
  margin: 0;
  float: left;
  position: absolute;
  left: -124px;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 0s ease-in-out;
}

@media (min-width: 1920px) {
  .social-menu.social-menu--header {
    width: 365px;
    left: -165px;
  }
}

.site-header--top .social-menu.social-menu--header {
  top: 100%;
  transform: translateY(-1rem);
}

.site-header--bottom .social-menu.social-menu--header {
  bottom: 100%;
  transform: translateY(1rem);
}

.social-menu.social-menu--header li {
  display: block;
}

.site-header--top .social-menu.social-menu--header li {
  border-top: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--top .social-menu.social-menu--header li {
    border-top-width: 4px;
  }
}

.site-header--bottom .social-menu.social-menu--header li {
  border-bottom: 2px solid transparent;
}

@media (min-width: 1920px) {
  .site-header--bottom .social-menu.social-menu--header li {
    border-bottom-width: 4px;
  }
}

.social-menu.social-menu--header li a {
  position: relative;
  display: block;
  padding: 23px 18px 19px;
  color: #fff;
  background-color: #151720;
  border-left-width: 72px;
  border-left-style: solid;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -.03em;
  line-height: 1.25;
  text-transform: uppercase;
  transition: all 240ms ease;
}

@media (min-width: 1920px) {
  .social-menu.social-menu--header li a {
    padding: 35px 28px 32px;
    border-left-width: 100px;
    font-size: 1.125rem;
  }
}

.social-menu.social-menu--header li a:hover {
  color: #a3ff12;
}

.social-menu.social-menu--header li a:before {
  position: absolute;
  top: 36px;
  left: -36px;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
}

@media (min-width: 1920px) {
  .social-menu.social-menu--header li a:before {
    top: 50px;
    left: -50px;
  }
}

.social-menu.social-menu--header li a .link-subtitle {
  display: block;
  font-size: .625em;
  line-height: 1;
  color: #5e627e;
}

.header-social-toggle:hover .social-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
  transition-duration: .3s;
}

.social-menu--default {
  margin-bottom: 0;
}

.player-info-detail__label + .social-menu--default {
  margin-top: 1px;
}

.info-box + .social-menu--default {
  margin-top: 20px;
}

.social-menu--default li {
  display: inline-block;
}

.social-menu--default li a:before {
  color: #a3ff12;
}

@media (min-width: 1920px) {
  .menu-panel .social-menu--default li a {
    font-size: 20px;
  }
}

.social-menu--default li a:hover {
  opacity: .8;
}

.social-menu--default li + li {
  margin-left: 20px;
}

@media (min-width: 1920px) {
  .social-menu--default li + li {
    margin-left: 50px;
  }
  .player-info-page .social-menu--default li + li {
    margin-left: 40px;
  }
}

.menu-panel .social-menu--default li + li {
  margin-left: 24px;
}

@media (min-width: 1920px) {
  .menu-panel .social-menu--default li + li {
    margin-left: 40px;
  }
}

.social-menu--landing {
  display: inline-block;
  margin: -1.125rem 0 0;
}

@media (min-width: 992px) {
  .social-menu--landing {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .social-menu--landing {
    text-align: left;
  }
}

.site-layout--landing .social-menu--landing {
  opacity: 1;
  transition: 0.7s 0.6s all cubic-bezier(0.86, 0, 0.07, 1);
}

.preloader-is--active .site-layout--landing .social-menu--landing {
  opacity: 0;
  transform: translateY(-50%) scale(1.5);
}

.social-menu--landing li {
  margin: 1.125rem 3rem;
}

@media (max-width: 767.98px) {
  .social-menu--landing li {
    display: block;
    margin: 2.25rem 0;
  }
}

@media (min-width: 768px) {
  .social-menu--landing li {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width: 1440px) {
  .social-menu--landing li {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.social-menu--landing li a {
  position: relative;
  display: block;
  padding-left: 2.875rem;
  font-size: 1.625rem;
  font-weight: 700;
  letter-spacing: -.03em;
  line-height: 1;
  text-transform: uppercase;
  text-align: left;
  color: #fff;
}

.social-menu--landing li a:before {
  display: none;
}

.social-menu--landing li a i {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 2.25rem;
  font-weight: 400;
  color: #a3ff12;
}

.social-menu--landing .link-subtitle {
  display: block;
  font-size: .75rem;
  font-weight: 500;
}

.social-menu--landing-glitch .glitch-layer {
  position: absolute;
  left: 0;
  top: 0;
  opacity: .8;
}

.social-menu--landing-glitch .glitch-layer--1 {
  color: #f0f;
  z-index: -2;
}

.social-menu--landing-glitch .glitch-layer--2 {
  color: #0ff;
  z-index: -1;
}

.social-menu--landing-glitch li a {
  transform: translate3d(0, 0, 0);
}

.social-menu--landing-glitch li a:hover .glitch-layer--1 {
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
}

.social-menu--landing-glitch li a:hover .glitch-layer--2 {
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-3px, 3px);
  }
  40% {
    transform: translate(-3px, -3px);
  }
  60% {
    transform: translate(3px, 3px);
  }
  80% {
    transform: translate(3px, -3px);
  }
  to {
    transform: translate(0);
  }
}

.social-menu--links {
  margin: -4px;
}

.social-menu--links li {
  padding: 4px;
}

.social-menu--links li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
}

.social-menu--links li a:hover {
  opacity: .75;
}

.social-menu--mobile-bar li {
  display: block;
}

.social-menu--mobile-bar li + li {
  border-top: 1px solid #242633;
}

.social-menu--mobile-bar a {
  display: block;
  padding: 12px 54px 12px 34px;
}

.social-menu--mobile-bar a:before {
  margin-right: 12px;
}

.social-menu--mobile-bar a span {
  font-size: .75rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  vertical-align: bottom;
  color: #fff;
}

.social-menu a[href*="discordapp.com"]:before,
.post__sharing a[href*="discordapp.com"]:before {
  content: "\f392";
}

.social-menu a[href*="dribbble.com"]:before,
.post__sharing a[href*="dribbble.com"]:before {
  content: "\f17d";
}

.social-menu a[href*="facebook.com"]:before,
.post__sharing a[href*="facebook.com"]:before {
  content: "\f09a";
}

.social-menu a[href*="instagram.com"]:before,
.post__sharing a[href*="instagram.com"]:before {
  content: "\f16d";
}

.social-menu a[href*="linkedin.com"]:before,
.post__sharing a[href*="linkedin.com"]:before {
  content: "\f08c";
}

.social-menu a[href*="medium.com"]:before,
.post__sharing a[href*="medium.com"]:before {
  content: "\f23a";
}

.social-menu a[href*="ok.ru"]:before,
.post__sharing a[href*="ok.ru"]:before {
  content: "\f263";
}

.social-menu a[href*="pinterest."]:before,
.post__sharing a[href*="pinterest."]:before {
  content: "\f0d2";
}

.social-menu a[href*="quora.com"]:before,
.post__sharing a[href*="quora.com"]:before {
  content: "\f2c4";
}

.social-menu a[href*="reddit.com"]:before,
.post__sharing a[href*="reddit.com"]:before {
  content: "\f281";
}

.social-menu a[href*="skype.com"]:before,
.post__sharing a[href*="skype.com"]:before {
  content: "\f17e";
}

.social-menu a[href*="snapchat.com"]:before,
.post__sharing a[href*="snapchat.com"]:before {
  content: "\f2ac";
}

.social-menu a[href*="soundcloud.com"]:before,
.post__sharing a[href*="soundcloud.com"]:before {
  content: "\f1be";
}

.social-menu a[href*="steampowered.com"]:before,
.post__sharing a[href*="steampowered.com"]:before {
  content: "\f1b6";
}

.social-menu a[href*="t.me"]:before,
.post__sharing a[href*="t.me"]:before {
  content: "\f3fe";
}

.social-menu a[href*="tumblr.com"]:before,
.post__sharing a[href*="tumblr.com"]:before {
  content: "\f173";
}

.social-menu a[href*="twitter.com"]:before,
.post__sharing a[href*="twitter.com"]:before {
  content: "\f099";
}

.social-menu a[href*="twitch.com"]:before,
.post__sharing a[href*="twitch.com"]:before {
  content: "\f1e8";
}

.social-menu a[href*="vimeo.com"]:before,
.post__sharing a[href*="vimeo.com"]:before {
  content: "\f40a";
}

.social-menu a[href*="vk.com"]:before,
.post__sharing a[href*="vk.com"]:before {
  content: "\f189";
}

.social-menu a[href*="wechat.com"]:before,
.post__sharing a[href*="wechat.com"]:before {
  content: "\f1d7";
}

.social-menu a[href*="wx.qq.com"]:before,
.post__sharing a[href*="wx.qq.com"]:before {
  content: "\f1d7";
}

.social-menu a[href*="weibo.com"]:before,
.post__sharing a[href*="weibo.com"]:before {
  content: "\f18a";
}

.social-menu a[href*="whatsapp.com"]:before,
.post__sharing a[href*="whatsapp.com"]:before {
  content: "\f232";
}

.social-menu a[href*="xing.com"]:before,
.post__sharing a[href*="xing.com"]:before {
  content: "\f168";
}

.social-menu a[href*="youtube.com"]:before,
.post__sharing a[href*="youtube.com"]:before {
  content: "\f167";
}

.post__sharing a[href*="discordapp.com"],
.social-menu.social-menu--links a[href*="discordapp.com"] {
  background-color: #7289da;
}

.post__sharing a[href*="dribbble.com"],
.social-menu.social-menu--links a[href*="dribbble.com"] {
  background-color: #ea4c89;
}

.post__sharing a[href*="facebook.com"],
.social-menu.social-menu--links a[href*="facebook.com"] {
  background-color: #224bc0;
}

.post__sharing a[href*="instagram.com"],
.social-menu.social-menu--links a[href*="instagram.com"] {
  background-color: #d42d93;
}

.post__sharing a[href*="linkedin.com"],
.social-menu.social-menu--links a[href*="linkedin.com"] {
  background-color: #0077b5;
}

.post__sharing a[href*="medium.com"],
.social-menu.social-menu--links a[href*="medium.com"] {
  background-color: #12100e;
}

.post__sharing a[href*="ok.ru"],
.social-menu.social-menu--links a[href*="ok.ru"] {
  background-color: #f4731c;
}

.post__sharing a[href*="pinterest."],
.social-menu.social-menu--links a[href*="pinterest."] {
  background-color: #bd081c;
}

.post__sharing a[href*="quora.com"],
.social-menu.social-menu--links a[href*="quora.com"] {
  background-color: #b92b27;
}

.post__sharing a[href*="reddit.com"],
.social-menu.social-menu--links a[href*="reddit.com"] {
  background-color: #ff4500;
}

.post__sharing a[href*="skype.com"],
.social-menu.social-menu--links a[href*="skype.com"] {
  background-color: #00aff0;
}

.post__sharing a[href*="snapchat.com"],
.social-menu.social-menu--links a[href*="snapchat.com"] {
  background-color: #fffc00;
}

.post__sharing a[href*="soundcloud.com"],
.social-menu.social-menu--links a[href*="soundcloud.com"] {
  background-color: #ff3300;
}

.post__sharing a[href*="steampowered.com"],
.social-menu.social-menu--links a[href*="steampowered.com"] {
  background-color: #000000;
}

.post__sharing a[href*="t.me"],
.social-menu.social-menu--links a[href*="t.me"] {
  background-color: #2ca5e0;
}

.post__sharing a[href*="tumblr.com"],
.social-menu.social-menu--links a[href*="tumblr.com"] {
  background-color: #36465d;
}

.post__sharing a[href*="twitter.com"],
.social-menu.social-menu--links a[href*="twitter.com"] {
  background-color: #09aafe;
}

.post__sharing a[href*="twitch.com"],
.social-menu.social-menu--links a[href*="twitch.com"] {
  background-color: #5e22e4;
}

.post__sharing a[href*="vimeo.com"],
.social-menu.social-menu--links a[href*="vimeo.com"] {
  background-color: #1ab7ea;
}

.post__sharing a[href*="vk.com"],
.social-menu.social-menu--links a[href*="vk.com"] {
  background-color: #6383a8;
}

.post__sharing a[href*="wechat.com"],
.social-menu.social-menu--links a[href*="wechat.com"] {
  background-color: #7bb32e;
}

.post__sharing a[href*="wx.qq.com"],
.social-menu.social-menu--links a[href*="wx.qq.com"] {
  background-color: #7bb32e;
}

.post__sharing a[href*="weibo.com"],
.social-menu.social-menu--links a[href*="weibo.com"] {
  background-color: #e6162d;
}

.post__sharing a[href*="whatsapp.com"],
.social-menu.social-menu--links a[href*="whatsapp.com"] {
  background-color: #25d366;
}

.post__sharing a[href*="xing.com"],
.social-menu.social-menu--links a[href*="xing.com"] {
  background-color: #006567;
}

.post__sharing a[href*="youtube.com"],
.social-menu.social-menu--links a[href*="youtube.com"] {
  background-color: #ff0000;
}

.social-menu.social-menu--mobile-bar a[href*="discordapp.com"]:before {
  color: #7289da;
}

.social-menu.social-menu--mobile-bar a[href*="dribbble.com"]:before {
  color: #ea4c89;
}

.social-menu.social-menu--mobile-bar a[href*="facebook.com"]:before {
  color: #224bc0;
}

.social-menu.social-menu--mobile-bar a[href*="instagram.com"]:before {
  color: #d42d93;
}

.social-menu.social-menu--mobile-bar a[href*="linkedin.com"]:before {
  color: #0077b5;
}

.social-menu.social-menu--mobile-bar a[href*="medium.com"]:before {
  color: #12100e;
}

.social-menu.social-menu--mobile-bar a[href*="ok.ru"]:before {
  color: #f4731c;
}

.social-menu.social-menu--mobile-bar a[href*="pinterest."]:before {
  color: #bd081c;
}

.social-menu.social-menu--mobile-bar a[href*="quora.com"]:before {
  color: #b92b27;
}

.social-menu.social-menu--mobile-bar a[href*="reddit.com"]:before {
  color: #ff4500;
}

.social-menu.social-menu--mobile-bar a[href*="skype.com"]:before {
  color: #00aff0;
}

.social-menu.social-menu--mobile-bar a[href*="snapchat.com"]:before {
  color: #fffc00;
}

.social-menu.social-menu--mobile-bar a[href*="soundcloud.com"]:before {
  color: #ff3300;
}

.social-menu.social-menu--mobile-bar a[href*="steampowered.com"]:before {
  color: #000000;
}

.social-menu.social-menu--mobile-bar a[href*="t.me"]:before {
  color: #2ca5e0;
}

.social-menu.social-menu--mobile-bar a[href*="tumblr.com"]:before {
  color: #36465d;
}

.social-menu.social-menu--mobile-bar a[href*="twitter.com"]:before {
  color: #09aafe;
}

.social-menu.social-menu--mobile-bar a[href*="twitch.com"]:before {
  color: #5e22e4;
}

.social-menu.social-menu--mobile-bar a[href*="vimeo.com"]:before {
  color: #1ab7ea;
}

.social-menu.social-menu--mobile-bar a[href*="vk.com"]:before {
  color: #6383a8;
}

.social-menu.social-menu--mobile-bar a[href*="wechat.com"]:before {
  color: #7bb32e;
}

.social-menu.social-menu--mobile-bar a[href*="wx.qq.com"]:before {
  color: #7bb32e;
}

.social-menu.social-menu--mobile-bar a[href*="weibo.com"]:before {
  color: #e6162d;
}

.social-menu.social-menu--mobile-bar a[href*="whatsapp.com"]:before {
  color: #25d366;
}

.social-menu.social-menu--mobile-bar a[href*="xing.com"]:before {
  color: #006567;
}

.social-menu.social-menu--mobile-bar a[href*="youtube.com"]:before {
  color: #ff0000;
}

.social-menu.social-menu--header a[href*="discordapp.com"] {
  border-color: #7289da;
}

.social-menu.social-menu--header a[href*="dribbble.com"] {
  border-color: #ea4c89;
}

.social-menu.social-menu--header a[href*="facebook.com"] {
  border-color: #224bc0;
}

.social-menu.social-menu--header a[href*="instagram.com"] {
  border-color: #d42d93;
}

.social-menu.social-menu--header a[href*="linkedin.com"] {
  border-color: #0077b5;
}

.social-menu.social-menu--header a[href*="medium.com"] {
  border-color: #12100e;
}

.social-menu.social-menu--header a[href*="ok.ru"] {
  border-color: #f4731c;
}

.social-menu.social-menu--header a[href*="pinterest."] {
  border-color: #bd081c;
}

.social-menu.social-menu--header a[href*="quora.com"] {
  border-color: #b92b27;
}

.social-menu.social-menu--header a[href*="reddit.com"] {
  border-color: #ff4500;
}

.social-menu.social-menu--header a[href*="skype.com"] {
  border-color: #00aff0;
}

.social-menu.social-menu--header a[href*="snapchat.com"] {
  border-color: #fffc00;
}

.social-menu.social-menu--header a[href*="soundcloud.com"] {
  border-color: #ff3300;
}

.social-menu.social-menu--header a[href*="steampowered.com"] {
  border-color: #000000;
}

.social-menu.social-menu--header a[href*="t.me"] {
  border-color: #2ca5e0;
}

.social-menu.social-menu--header a[href*="tumblr.com"] {
  border-color: #36465d;
}

.social-menu.social-menu--header a[href*="twitter.com"] {
  border-color: #09aafe;
}

.social-menu.social-menu--header a[href*="twitch.com"] {
  border-color: #5e22e4;
}

.social-menu.social-menu--header a[href*="vimeo.com"] {
  border-color: #1ab7ea;
}

.social-menu.social-menu--header a[href*="vk.com"] {
  border-color: #6383a8;
}

.social-menu.social-menu--header a[href*="wechat.com"] {
  border-color: #7bb32e;
}

.social-menu.social-menu--header a[href*="wx.qq.com"] {
  border-color: #7bb32e;
}

.social-menu.social-menu--header a[href*="weibo.com"] {
  border-color: #e6162d;
}

.social-menu.social-menu--header a[href*="whatsapp.com"] {
  border-color: #25d366;
}

.social-menu.social-menu--header a[href*="xing.com"] {
  border-color: #006567;
}

.social-menu.social-menu--header a[href*="youtube.com"] {
  border-color: #ff0000;
}

.icon-svg {
  font-size: 10px;
}

.icon-svg:before {
  content: ' ';
  vertical-align: middle;
  display: inline-block;
  background-image: url("../img/sprite.svg");
  background-repeat: no-repeat;
  background-size: 2.7em 8.1em;
}

.no-svg .icon-svg:before {
  background-image: url("../img/sprite.svg");
}

.icon-svg.icon-bag:before {
  background-position: 0em 0em;
  width: 2.3em;
  height: 2.7em;
}

.icon-svg.icon-search:before {
  background-position: 0em -2.7em;
  width: 2.7em;
  height: 2.7em;
}

.icon-svg.icon-thumb-up:before {
  background-position: 0em -5.4em;
  width: 2.7em;
  height: 2.7em;
}

@media (min-width: 992px) {
  .content.staff-layout {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 50% 50%;
    grid-auto-columns: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .content.staff-layout {
    grid-auto-columns: calc(100vh - 100px);
  }
}

.staff-member {
  overflow: hidden;
  background-color: #fff;
}

@media (min-width: 576px) {
  .staff-member {
    display: flex;
  }
}

.staff-member:nth-child(odd) .staff-member__thumbnail {
  order: 2;
}

@media (min-width: 576px) {
  .staff-member__thumbnail {
    width: 50%;
    height: 100%;
  }
}

.staff-member__thumbnail img {
  width: 100%;
}

.staff-member__body {
  position: relative;
  padding: 32px 40px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .staff-member__body {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .staff-member__body {
    padding-top: 4.16667vh;
    padding-right: 5.20833vh;
    padding-bottom: 4.16667vh;
    padding-left: 5.20833vh;
  }
}

.staff-member__title {
  padding-right: 20px;
}

@media (min-width: 992px) {
  .staff-member__title {
    margin-bottom: 2.34375vh;
    padding-right: 2.60417vh;
    font-size: 5.98958vh;
  }
}

.staff-member__meta, .staff-member__position {
  font-weight: bold;
  text-transform: uppercase;
}

.staff-member__position,
.staff-member__meta span {
  color: #ced0da;
}

.staff-member__position {
  font-size: 1rem;
  line-height: 2.125;
  letter-spacing: -.06em;
}

@media (min-width: 992px) {
  .staff-member__position {
    font-size: 2.08333vh;
  }
}

.staff-member__meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-top: 2px;
  padding-right: 20px;
  font-size: .75rem;
  line-height: 1;
  letter-spacing: -.03em;
}

@media (min-width: 992px) {
  .staff-member__meta {
    margin-bottom: 3.25521vh;
    padding-top: 0.26042vh;
    padding-right: 2.60417vh;
    font-size: 1.5625vh;
  }
}

.staff-member__meta span {
  display: block;
  margin-bottom: 3px;
  font-size: .625rem;
}

@media (min-width: 992px) {
  .staff-member__meta span {
    margin-bottom: 0.39062vh;
    font-size: 1.30208vh;
  }
}

.staff-member__excerpt {
  font-size: .75rem;
  line-height: 1.667;
}

@media (min-width: 992px) {
  .staff-member__excerpt {
    font-size: 1.5625vh;
  }
}

@media (min-width: 768px) {
  .streams-archive {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .streams-archive {
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-template-rows: 50% 50%;
    grid-auto-columns: calc(84.896vh - 72px);
  }
}

@media (min-width: 1920px) {
  .streams-archive {
    grid-auto-columns: calc(84.896vh - 100px);
  }
}

.streams-filter {
  margin-top: 54px;
  margin-bottom: 0;
  padding: 15px 15px 60px;
  display: flex;
  justify-content: center;
  -webkit-transform: translateZ(0px);
}

@media (min-width: 1920px) {
  .streams-filter {
    margin-top: 80px;
  }
}

.streams-filter li:nth-child(even) button {
  transform: rotate(45deg) translate(45.75%, 45.75%) translateZ(0px);
}

.streams-filter li + li {
  margin-left: -24.5px;
}

@media (min-width: 1920px) {
  .streams-filter li + li {
    margin-left: -33.5px;
  }
}

.streams-filter button {
  position: relative;
  width: 70px;
  height: 70px;
  padding: 0;
  outline: 0;
  border: 6px solid #3d4055;
  transform: rotate(45deg) translateZ(0px);
  overflow: hidden;
  color: #fff;
  background-color: #151720;
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .streams-filter button {
    width: 98px;
    height: 98px;
    border-width: 8.4px;
  }
}

.streams-filter button::before, .streams-filter button::after {
  position: absolute;
  content: "";
  top: -21px;
  left: -21px;
  width: 100px;
  height: 100px;
  z-index: 33;
  transform: rotate(-45deg) translateZ(0px);
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .streams-filter button::before, .streams-filter button::after {
    top: -29.5px;
    left: -29.5px;
    width: 140px;
    height: 140px;
  }
}

.streams-filter button::before {
  z-index: 34;
  mix-blend-mode: multiply;
  background-color: #6b7199;
}

.streams-filter button::after {
  z-index: 35;
  mix-blend-mode: screen;
  background-color: #151720;
}

.streams-filter button img {
  width: 100px;
  height: 100px;
  margin-top: -21px;
  margin-left: -21px;
  object-fit: cover;
  transform: rotate(-45deg) translateZ(0px);
  filter: grayscale(100%);
}

@media (min-width: 1920px) {
  .streams-filter button img {
    width: 140px;
    height: 140px;
    margin-top: -29.5px;
    margin-left: -29.5px;
  }
}

.streams-filter button:hover, .streams-filter button.active {
  position: relative;
  z-index: 1;
  border-color: #a3ff12;
}

.streams-filter button:hover img, .streams-filter button.active img {
  filter: grayscale(0);
}

.streams-filter button:hover::before, .streams-filter button:hover::after, .streams-filter button.active::before, .streams-filter button.active::after {
  opacity: 0;
}

.stream {
  position: relative;
  width: 100%;
  height: 56.25vw;
}

body:not(.cursor-is--active) .stream {
  cursor: pointer;
}

@media (min-width: 768px) {
  .stream {
    width: 50%;
    height: 23.125vw;
  }
}

@media (min-width: 992px) {
  .stream {
    width: calc(84.896vh - 72px);
    height: calc(50vh - 72px / 2);
  }
}

@media (min-width: 1920px) {
  .stream {
    width: calc(84.896vh - 100px);
    height: calc(50vh - 100px / 2);
  }
}

.player-info-page .stream {
  width: 100%;
  height: auto;
}

.stream__thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
}

.stream__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stream__thumbnail:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  transition: background-color 300ms ease;
}

.stream:hover .stream__thumbnail:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.stream__header, .stream__thumbnail:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.stream__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__header {
    padding: 2.60417vh;
  }
}

.stream__info {
  flex: 1 0 auto;
  padding-right: 40px;
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__info {
    padding-right: 5.20833vh;
  }
}

.stream__avatar {
  float: left;
  margin-right: 8px;
  margin-bottom: 40px;
}

@media (min-width: 1920px) {
  .stream__avatar {
    margin-right: 12px;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__avatar {
    margin-right: 1.04167vh;
    margin-bottom: 5.20833vh;
  }
}

.stream__avatar img {
  vertical-align: top;
  width: 26px;
  height: 26px;
}

@media (min-width: 1920px) {
  .stream__avatar img {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__avatar img {
    width: 3.38542vh;
    height: 3.38542vh;
  }
}

.stream__title {
  padding-top: 2px;
  margin-bottom: 2px;
  font-size: .6875rem;
  color: #fff;
}

@media (min-width: 1920px) {
  .stream__title {
    margin-bottom: 3px;
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__title {
    padding-top: 0.26042vh;
    margin-bottom: 0.26042vh;
    font-size: 1.43229vh;
  }
}

.stream__date {
  font-size: .5625rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -.03em;
  line-height: 1;
  color: #c6cbea;
}

@media (min-width: 1920px) {
  .stream__date {
    font-size: .75rem;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__date {
    font-size: 1.17188vh;
  }
}

.stream__icon {
  position: absolute;
  right: 50%;
  bottom: 50%;
  display: block;
  overflow: hidden;
  border-radius: 20%;
  transform: translateX(50%) translateY(75%) rotate(0deg) skewY(30deg) scaleX(0.866) translateZ(0px);
}

.stream__icon, .stream__icon:after, .stream__icon:before {
  width: 1.375rem;
  height: 1.375rem;
}

@media (min-width: 1920px) {
  .stream__icon, .stream__icon:after, .stream__icon:before {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream__icon, .site-layout--horizontal .stream__icon:after, .site-layout--horizontal .stream__icon:before {
    width: 2.86458vh;
    height: 2.86458vh;
  }
}

.stream__icon:after, .stream__icon:before {
  position: absolute;
  background: #fff;
  pointer-events: auto;
  content: "";
}

.stream__icon:after {
  border-radius: 20% 20% 53% 20%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%) translateZ(0px);
}

.stream__icon:before {
  border-radius: 20% 20% 20% 53%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%) translateZ(0px);
}

.stream .btn-twitch {
  font-size: .625rem;
  padding: .6875rem 1.125rem .5rem;
}

@media (min-width: 992px) {
  .site-layout--horizontal .stream .btn-twitch {
    padding-top: 1.43229vh;
    padding-right: 2.34375vh;
    padding-bottom: 1.04167vh;
    padding-left: 2.34375vh;
    font-size: 1.30208vh;
  }
}

.stream .btn-twitch i {
  vertical-align: sub;
}

.badge--live {
  position: relative;
}

.badge--live:before {
  position: absolute;
  left: 100%;
  top: 2px;
  margin-left: 6px;
  padding: 1px 2px;
  content: "live";
  font-size: .375rem;
  letter-spacing: 0;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 2px;
  color: #fff;
}

@media (min-width: 1920px) {
  .badge--live:before {
    top: 4px;
    margin-left: 12px;
    padding-top: 2px;
    font-size: .5625rem;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .badge--live:before {
    top: 0.26042vh;
    margin-left: 0.26042vh;
    padding-top: 0.13021vh;
    padding-right: 0.26042vh;
    padding-bottom: 0.13021vh;
    padding-left: 0.26042vh;
    font-size: 0.78125vh;
    border-radius: 0.26042vh;
  }
}

.badge-live {
  background-color: #ff1c5c;
  border-radius: 2px;
  font-size: .375rem;
  vertical-align: top;
  margin-left: 1em;
  padding-top: .1em;
  padding-bottom: .1em;
}

@media (min-width: 992px) {
  .badge-live {
    font-size: .5625rem;
  }
}

@media (min-width: 1920px) {
  .badge-live {
    font-size: .5625rem;
  }
}

.btn-twitch--advanced i {
  margin: 0;
}

.btn-twitch--advanced .btn__text-inner {
  margin-left: .5em;
}

.world-map {
  position: relative;
  display: block;
  background-size: contain;
  padding-bottom: 53.2%;
}

.world-map::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url("../img/dotted-world.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  opacity: .4;
}

.world-map img,
.world-map svg {
  width: 100%;
}

.world-map-team {
  position: absolute;
  margin: 0 0 -10px -10px;
  padding-bottom: 20px;
}

@media (min-width: 1920px) {
  .world-map-team {
    margin: 0 0 -15px -15px;
    padding-bottom: 30px;
  }
}

.world-map-team__anchor {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #222430;
}

@media (min-width: 1920px) {
  .world-map-team__anchor {
    width: 30px;
    height: 30px;
  }
}

.world-map-team__anchor:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  background-color: #a3ff12;
}

@media (min-width: 1920px) {
  .world-map-team__anchor:before {
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
  }
}

.world-map-team--left .world-map-team__anchor {
  left: 0;
}

.world-map-team--right .world-map-team__anchor {
  right: 0;
}

.world-map-team__logo {
  width: 22px;
}

@media (min-width: 1920px) {
  .world-map-team__logo {
    width: 32px;
  }
}

.world-map-team--right .world-map-team__logo {
  order: 2;
}

.world-map-team figure {
  margin-bottom: 0;
}

.world-map-team figure:not(.world-map-team__logo) {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.world-map-team figcaption {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -.03em;
  line-height: 1;
}

.world-map-team--right .world-map-team__name, .world-map-team--right .world-map-team__country {
  text-align: right;
}

.world-map-team__country {
  font-size: .625rem;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .world-map-team__country {
    font-size: .875rem;
  }
}

.world-map-team--left figcaption {
  margin-left: 8px;
}

.world-map-team--right figcaption {
  margin-right: 8px;
}

.world-map-team--one {
  bottom: 58%;
  left: 26%;
}

.world-map-team--two {
  bottom: 25%;
  right: 12%;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
}

.header-cart-toggle,
.header-search-toggle,
.header-filter-toggle,
.header-social-toggle,
.header-top-bar-toggle,
.header-scroll-arrow,
.header-player-info-navigation {
  transition: display 200ms ease 300ms, opacity 300ms ease, transform 300ms ease;
}

.header-cart-toggle.hide,
.header-search-toggle.hide,
.header-filter-toggle.hide,
.header-social-toggle.hide,
.header-top-bar-toggle.hide,
.header-scroll-arrow.hide,
.header-player-info-navigation.hide {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20%);
}

.header-account {
  transition: display 200ms ease 300ms, opacity 300ms ease, transform 300ms ease;
}

.header-account.hide {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20%);
}

@media (max-width: 767.98px) {
  .header-player-info-navigation:first-child {
    position: relative;
    right: -58px;
  }
}

.header-cart-toggle .df-icon,
.header-search-toggle .df-icon,
.header-filter-toggle .df-icon,
.header-social-toggle .df-icon,
.header-top-bar-toggle .df-icon {
  fill: #fff;
  transition: all 300ms ease;
}

.header-cart-toggle:hover .df-icon,
.header-search-toggle:hover .df-icon,
.header-filter-toggle:hover .df-icon,
.header-social-toggle:hover .df-icon,
.header-top-bar-toggle:hover .df-icon {
  fill: #a3ff12;
}

.header-account .df-icon {
  fill: #fff;
  transition: all 300ms ease;
}

.header-account a:hover .df-icon {
  fill: #a3ff12;
}

.header-account .df-icon,
.header-cart-toggle .df-icon,
.header-search-toggle .df-icon,
.header-social-toggle .df-icon,
.header-top-bar-toggle .df-icon {
  width: 24px;
  height: 24px;
}

@media (min-width: 1920px) {
  .header-account .df-icon,
  .header-cart-toggle .df-icon,
  .header-search-toggle .df-icon,
  .header-social-toggle .df-icon,
  .header-top-bar-toggle .df-icon {
    width: 30px;
    height: 30px;
  }
}

.header-filter-toggle,
.header-menu-toggle,
.header-cart-toggle,
.header-social-toggle,
.header-search-toggle,
.header-top-bar-toggle {
  margin-left: 34px;
}

body:not(.cursor-is--active) .header-filter-toggle, body:not(.cursor-is--active)
.header-menu-toggle, body:not(.cursor-is--active)
.header-cart-toggle, body:not(.cursor-is--active)
.header-social-toggle, body:not(.cursor-is--active)
.header-search-toggle, body:not(.cursor-is--active)
.header-top-bar-toggle {
  cursor: pointer;
}

@media (min-width: 1920px) {
  .header-filter-toggle,
  .header-menu-toggle,
  .header-cart-toggle,
  .header-social-toggle,
  .header-search-toggle,
  .header-top-bar-toggle {
    margin-left: 55px;
  }
}

.header-cart-toggle,
.header-social-toggle,
.header-search-toggle,
.header-top-bar-toggle {
  padding-top: 24px;
  padding-bottom: 24px;
}

@media (min-width: 1920px) {
  .header-cart-toggle,
  .header-social-toggle,
  .header-search-toggle,
  .header-top-bar-toggle {
    padding-top: 34px;
    padding-bottom: 36px;
  }
}

.header-cart-toggle,
.header-search-toggle,
.header-top-bar-toggle {
  position: relative;
}

.header-cart-toggle .df-icon--close,
.header-cart-toggle .df-icon--search-close,
.header-search-toggle .df-icon--close,
.header-search-toggle .df-icon--search-close,
.header-top-bar-toggle .df-icon--close,
.header-top-bar-toggle .df-icon--search-close {
  position: absolute;
  top: 25px;
  left: 0;
  opacity: 0;
  transform: scale(0.3);
  transform-origin: center;
}

@media (min-width: 1920px) {
  .header-cart-toggle .df-icon--close,
  .header-cart-toggle .df-icon--search-close,
  .header-search-toggle .df-icon--close,
  .header-search-toggle .df-icon--search-close,
  .header-top-bar-toggle .df-icon--close,
  .header-top-bar-toggle .df-icon--search-close {
    top: 35px;
    left: 0;
  }
}

.header-cart-toggle.toggled .df-icon--bag {
  opacity: 0;
  transform: scale(0.3);
}

.header-search-toggle.toggled .df-icon--search {
  opacity: 0;
  transform: scale(0.3);
}

.header-top-bar-toggle.toggled .df-icon--joystick {
  opacity: 0;
  transform: scale(0.3);
}

.header-cart-toggle.toggled .df-icon--close,
.header-cart-toggle.toggled .df-icon--search-close,
.header-search-toggle.toggled .df-icon--close,
.header-search-toggle.toggled .df-icon--search-close,
.header-top-bar-toggle.toggled .df-icon--close,
.header-top-bar-toggle.toggled .df-icon--search-close {
  opacity: 1;
  transform: scale(1);
}

.header-social-toggle {
  position: relative;
}

.header-social-toggle__plus {
  position: absolute;
  display: inline-block;
  top: 22px;
  right: -8px;
  width: 6px;
  height: 2px;
  background-color: #a3ff12;
}

.header-social-toggle__plus:before {
  position: absolute;
  top: -2px;
  left: 2px;
  width: 2px;
  height: 6px;
  content: "";
  background-color: #a3ff12;
  transition: opacity 300ms ease;
}

@media (min-width: 1920px) {
  .header-social-toggle__plus {
    top: 32px;
    right: -12px;
  }
}

.header-social-toggle:hover .header-social-toggle__plus:before {
  opacity: 0;
}

.header-cart-toggle__items-count {
  position: absolute;
  display: inline-block;
  top: 18px;
  right: -8px;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  color: #a3ff12;
}

@media (min-width: 1920px) {
  .header-cart-toggle__items-count {
    top: 28px;
    right: -12px;
    font-size: .75em;
  }
}

.header-filter-toggle {
  display: none;
  position: relative;
  padding-top: 27px;
  padding-bottom: 31px;
  margin-left: 0;
}

@media (min-width: 992px) {
  html:not(.touch) .header-filter-toggle {
    display: block;
  }
}

@media (min-width: 1920px) {
  .header-filter-toggle {
    padding-top: 38px;
    padding-bottom: 38px;
  }
}

.header-filter-toggle .df-icon {
  width: 14px;
  height: 14px;
}

@media (min-width: 1920px) {
  .header-filter-toggle .df-icon {
    width: 24px;
    height: 24px;
  }
}

.header-menu-toggle {
  width: 72px;
  height: 72px;
  padding: 29px 27px;
  background-color: #a3ff12;
}

@media (min-width: 1920px) {
  .header-menu-toggle {
    width: 100px;
    height: 100px;
    padding: 38px 36px;
  }
}

.header-menu-toggle__inner {
  background: transparent;
  border-color: transparent;
  position: relative;
  width: 18px;
  height: 14px;
}

@media (min-width: 1920px) {
  .header-menu-toggle__inner {
    width: 28px;
    height: 24px;
  }
}

.header-menu-toggle__inner:hover, .header-menu-toggle__inner:focus, .header-menu-toggle__inner:active {
  outline: none;
  background: transparent;
  border-color: transparent;
}

.header-menu-toggle__inner span {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background: #151720;
  transition: all ease 300ms;
}

@media (min-width: 1920px) {
  .header-menu-toggle__inner span {
    height: 4px;
  }
}

.header-menu-toggle__inner span:first-child {
  top: 0;
}

.header-menu-toggle__inner span:nth-child(2) {
  top: 6px;
}

@media (min-width: 1920px) {
  .header-menu-toggle__inner span:nth-child(2) {
    top: 10px;
  }
}

.header-menu-toggle__inner span:last-child {
  bottom: 0%;
}

.header-menu-toggle.toggled .header-menu-toggle__inner span:first-child {
  transform: rotate(45deg);
  top: 6px;
}

@media (min-width: 1920px) {
  .header-menu-toggle.toggled .header-menu-toggle__inner span:first-child {
    top: 10px;
  }
}

.header-menu-toggle.toggled .header-menu-toggle__inner span:last-child, .header-menu-toggle.toggled .header-menu-toggle__inner span:nth-child(2) {
  transform: rotate(-45deg);
  top: 6px;
}

@media (min-width: 1920px) {
  .header-menu-toggle.toggled .header-menu-toggle__inner span:last-child, .header-menu-toggle.toggled .header-menu-toggle__inner span:nth-child(2) {
    top: 10px;
  }
}

.header-account {
  position: absolute;
  top: 0;
  right: 72px;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .header-account {
    right: 100px;
  }
}

.header-account__avatar {
  width: 32px;
  height: 32px;
  margin-right: 18px;
}

@media (min-width: 1920px) {
  .header-account__avatar {
    width: 40px;
    height: 40px;
    margin-right: 22px;
  }
}

.header-account__avatar img {
  width: 100%;
}

.header-account__body {
  width: 98px;
  font-size: .625rem;
  line-height: 1;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .header-account__body {
    font-size: .75rem;
  }
}

.header-account--guest .header-account__body {
  width: 90px;
}

.header-account__name {
  margin-top: 3px;
  font-size: .75rem;
  color: #fff;
}

@media (min-width: 1920px) {
  .header-account__name {
    font-size: .875rem;
  }
}

.header-account__icon {
  margin-left: 34px;
  margin-right: 34px;
  line-height: 1;
}

.header-account__icon a {
  display: inline-block;
}

.header-account__icon a + a {
  margin-left: 30px;
}

.header-scroll-arrow {
  display: none;
  align-items: center;
  padding: 20px;
}

@media (min-width: 992px) {
  html:not(.touch) .header-scroll-arrow {
    display: flex;
  }
}

.header-scroll-arrow svg {
  width: 7px;
  height: 14px;
  fill: #fff;
  transition: 300ms fill ease;
}

@media (min-width: 1920px) {
  .header-scroll-arrow svg {
    width: 11px;
    height: 22px;
  }
}

.header-scroll-arrow--left {
  margin-left: -10px;
}

@media (min-width: 1920px) {
  .header-scroll-arrow--left {
    padding-right: 30px;
  }
}

.header-scroll-arrow--right {
  margin-right: -10px;
}

@media (min-width: 1920px) {
  .header-scroll-arrow--right {
    padding-left: 30px;
  }
}

.header-scroll-arrow:hover svg {
  fill: #a3ff12;
}

.header-scroll-arrow.disable svg {
  fill: rgba(94, 98, 126, 0.4);
}

.menu-panel {
  position: fixed;
  left: 0;
  z-index: 1102;
  width: 100%;
  height: calc(100% - 72px);
  background-color: #151720;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: visibility 0ms, opacity 320ms ease;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .menu-panel {
    overflow-y: auto;
  }
}

@media (min-width: 1920px) {
  .menu-panel {
    height: calc(100% - 100px);
  }
}

.site-header--top ~ .menu-panel {
  bottom: 0;
}

.site-header--bottom ~ .menu-panel {
  top: 0;
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom ~ .menu-panel {
    top: 72px;
    bottom: 0;
  }
}

.site-wrapper--has-menu-overlay .menu-panel {
  visibility: visible;
  opacity: 1;
}

.site-wrapper--has-menu-overlay .menu-panel__content {
  opacity: 1;
  transform: translateY(0);
  transition: opacity ease 320ms, transform ease 800ms;
}

.menu-panel__top-bar {
  color: #fff;
}

@media (max-width: 767.98px) {
  .menu-panel__top-bar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #151720;
    transform: translate3d(100%, 0, 0);
    transition: transform ease 440ms 0ms;
    z-index: 1;
  }
  .menu-panel__top-bar.toggled {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {
  .menu-panel__top-bar {
    display: flex;
    width: 100%;
    height: 115px;
    border-bottom: 1px solid #242633;
  }
}

@media (max-width: 767.98px) {
  .menu-panel__top-bar, .menu-panel__mobile-bar {
    height: calc(100vh - 72px);
    overflow-y: auto;
  }
}

.menu-panel__content {
  width: 100%;
  display: flex;
  padding: 78px 72px;
  opacity: 0;
  transform: translateY(1rem);
  transition-duration: 0;
}

.menu-panel__content .row + .row {
  margin-top: 3rem;
}

@media (min-width: 992px) {
  .menu-panel__content .row + .row {
    margin-top: 80px;
  }
}

@media (min-width: 1920px) {
  .menu-panel__content .row + .row {
    margin-top: 100px;
  }
}

.menu-panel__navigation, .menu-panel__widget-area {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.top-bar-filter {
  padding-top: 20px;
  padding-bottom: 18px;
  border-right: 1px solid #242633;
}

@media (max-width: 767.98px) {
  .top-bar-filter {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #242633;
  }
}

@media (min-width: 768px) {
  .top-bar-filter {
    width: 181px;
    height: 100%;
    padding-top: 43px;
    padding-bottom: 43px;
  }
}

.top-bar-filter__label {
  display: block;
  margin-bottom: -3px;
  padding: 0 42px 0 24px;
  font-size: .625rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #5e627e;
}

.top-bar-filter .cs-select .cs-placeholder {
  display: block;
  padding: 0 42px 0 24px;
  font-size: .75rem;
  transition: color 300ms ease;
}

.top-bar-filter .cs-select .cs-placeholder:after {
  right: 18px;
  top: 2px;
}

@media (min-width: 768px) {
  .top-bar-filter .cs-select .cs-placeholder:after {
    top: 4px;
  }
}

.top-bar-filter .cs-select .cs-placeholder:before {
  top: 0;
  right: 20px;
}

@media (min-width: 768px) {
  .top-bar-filter .cs-select .cs-placeholder:before {
    top: 2px;
  }
}

.top-bar-filter .cs-select .cs-options {
  width: calc(100% + 1px);
  margin-top: 23px;
  border: 1px solid #242633;
}

@media (min-width: 768px) {
  .top-bar-filter .cs-select .cs-options {
    margin-top: 46px;
  }
}

.top-bar-carousel {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

@media (min-width: 768px) {
  .top-bar-carousel {
    flex-basis: calc(100% - 181px);
    max-width: calc(100% - 181px);
  }
}

.top-bar-carousel .slick-arrow + .slick-list {
  margin: 0 45px;
}

.top-bar-carousel__item:not(.top-bar-carousel__headline) {
  width: 261px;
  height: 114px;
  border-right: 1px solid #242633;
  transition: .3s border-left ease;
}

@media (max-width: 767.98px) {
  .top-bar-carousel__item {
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .top-bar-carousel__item:hover {
    border-left: 2px solid #a3ff12;
  }
}

@media (max-width: 767.98px) {
  .top-bar-carousel > li + li {
    border-top: 1px solid #242633;
  }
}

@media (max-width: 767.98px) {
  .top-bar-carousel > li:last-child {
    border-bottom: 1px solid #242633;
  }
}

.top-bar-carousel__headline {
  display: flex !important;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  font-size: .75rem;
  font-weight: bold;
  letter-spacing: -.03em;
  line-height: .875rem;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 767.98px) {
  .top-bar-carousel__headline {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 16px;
    border-left: 2px solid #a3ff12;
  }
}

@media (min-width: 768px) {
  .top-bar-carousel__headline {
    width: 141px;
    height: 114px;
    border-top: 2px solid #a3ff12;
    border-right: 1px solid #242633;
  }
}

.top-bar-carousel .slick-arrow {
  position: absolute;
  top: 0;
  padding: 50px 18px;
  border: none;
  background-color: transparent;
  font-size: 0;
  outline: 0;
  text-align: center;
}

body:not(.cursor-is--active) .top-bar-carousel .slick-arrow {
  cursor: pointer;
}

.top-bar-carousel .slick-arrow svg {
  width: 8px;
  height: 14px;
  opacity: .4;
  fill: #fff;
  transition: opacity 300ms ease;
}

.top-bar-carousel .slick-arrow:hover svg {
  opacity: 1;
}

.top-bar-carousel .slick-prev {
  left: 0;
  border-right: 1px solid #242633;
}

.top-bar-carousel .slick-next {
  right: 0;
  border-left: 1px solid #242633;
}

.menu-panel__mobile-bar {
  width: 100%;
}

.mobile-bar-item {
  color: #fff;
}

.mobile-bar-item__header {
  position: relative;
  display: block;
  padding: 17px 54px 16px 34px;
  font-size: .875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -.03em;
}

.mobile-bar-item__header, .mobile-bar-item__header:hover, .mobile-bar-item__header:focus {
  color: #fff;
}

.mobile-bar-item__body {
  display: block;
  background-color: #13151e;
  border-top: 1px solid #242633;
}

.mobile-bar-item + .mobile-bar-item {
  border-top: 1px solid #242633;
}

.mobile-bar-item:last-child {
  border-bottom: 1px solid #242633;
}

.mobile-bar-item--info .mobile-bar-item__inner {
  padding: 24px 54px 17px 34px;
}

.mobile-bar-item--info .info-box__content {
  font-size: .75rem;
}

.mobile-bar-item--info .info-box + .info-box {
  margin-top: 17px;
}

.mobile-bar-item--partners .mobile-bar-item__inner {
  padding: 41px 34px;
}

.widget-partners-mobile-carousel {
  padding-left: 0;
}

.widget-partners-mobile-carousel .slick-arrow {
  position: absolute;
  top: 12px;
  width: 20px;
  padding: 2px 0;
  border: none;
  background-color: transparent;
  font-size: 0;
  outline: 0;
  z-index: 1;
  text-align: center;
}

body:not(.cursor-is--active) .widget-partners-mobile-carousel .slick-arrow {
  cursor: pointer;
}

.widget-partners-mobile-carousel .slick-arrow svg {
  display: inline-block;
  fill: #fff;
  width: 6px;
  height: 10px;
  transition: fill 300ms ease;
}

.widget-partners-mobile-carousel .slick-arrow:hover svg {
  fill: #a3ff12;
}

.widget-partners-mobile-carousel .slick-prev {
  left: -10px;
}

.widget-partners-mobile-carousel .slick-next {
  right: -10px;
}

.widget-partners-mobile-carousel .slick-slide img {
  margin-left: auto;
  margin-right: auto;
}

.cart-panel {
  position: fixed;
  overflow-y: scroll;
  z-index: 1101;
  left: 100%;
  height: calc(100% - 72px);
  width: 100%;
  padding: 30px 15px;
  text-align: left;
  transition: transform ease 440ms 0ms;
  transition-delay: 0;
  color: #fff;
  background-color: #222430;
}

@media (min-width: 768px) {
  .cart-panel {
    width: 80%;
    padding: 48px 40px 50px;
  }
}

@media (min-width: 992px) {
  .cart-panel {
    width: 700px;
  }
}

@media (min-width: 1920px) {
  .cart-panel {
    height: calc(100% - 100px);
    width: 960px;
    padding: 58px 60px 60px;
  }
}

.site-header--top ~ .cart-panel {
  bottom: 0;
}

.site-header--bottom ~ .cart-panel {
  top: 0;
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom ~ .cart-panel {
    top: 72px;
    bottom: 0;
  }
}

.site-wrapper--has-overlay .cart-panel {
  transform: translateX(-100%);
  transition-delay: 100ms;
}

.cart-panel__title {
  margin-bottom: 31px;
}

@media (min-width: 1920px) {
  .cart-panel__title {
    margin-bottom: 47px;
    font-size: 2.125rem;
  }
}

.cart-panel__items-count {
  color: #a3ff12;
}

.cart-panel__content .table {
  margin-bottom: 22px;
}

@media (min-width: 1920px) {
  .cart-panel__content .table {
    margin-bottom: 33px;
  }
}

.cart-panel__content .table thead th {
  padding: 0 0 12px;
  font-size: .625rem;
  letter-spacing: -.03em;
  text-transform: uppercase;
  border: none;
}

@media (min-width: 1920px) {
  .cart-panel__content .table thead th {
    padding-bottom: 18px;
    font-size: .875rem;
  }
}

.cart-panel__content .table thead,
.cart-panel__content .table tbody td {
  border-top: none;
  border-bottom: 1px solid rgba(94, 98, 126, 0.2);
}

.cart-panel .product__info {
  min-width: 258px;
  padding: 18px 15px 18px 0;
}

@media (min-width: 576px) {
  .cart-panel .product__info {
    min-width: 260px;
  }
}

@media (min-width: 1920px) {
  .cart-panel .product__info {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

.cart-panel .product__info-content {
  overflow: hidden;
  padding-left: 15px;
}

@media (min-width: 1920px) {
  .cart-panel .product__info-content {
    padding-left: 22px;
  }
}

.cart-panel .product__total, .cart-panel .product__quantity {
  display: none;
}

@media (min-width: 576px) {
  .cart-panel .product__total, .cart-panel .product__quantity {
    display: table-cell;
  }
}

.cart-panel .product__thumb {
  float: left;
  width: 44px;
  margin-bottom: 0;
}

.cart-panel .product__thumb img {
  width: 100%;
}

@media (min-width: 1920px) {
  .cart-panel .product__thumb {
    width: 60px;
  }
}

.cart-panel .product__name {
  margin-top: 2px;
  margin-bottom: 1px;
  font-size: .875rem;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .cart-panel .product__name {
    font-size: 1.25rem;
  }
}

.cart-panel .product__name a {
  color: #fff;
  transition: color 300ms ease;
}

.cart-panel .product__name a:hover {
  color: #a3ff12;
}

.cart-panel .product__cat, .cart-panel .product__info-details {
  display: block;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .cart-panel .product__cat, .cart-panel .product__info-details {
    font-size: .875rem;
  }
}

.cart-panel .product__cat {
  color: #c6cbea;
}

@media (min-width: 1920px) {
  .cart-panel .product__cat {
    margin-top: 2px;
  }
}

.cart-panel .product__info-details {
  margin-top: 1px;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .cart-panel .product__info-details {
    margin-top: 3px;
  }
}

.cart-panel .product__total:not(th),
.cart-panel .product__quantity input {
  font-size: .875rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
}

@media (min-width: 1920px) {
  .cart-panel .product__total:not(th),
  .cart-panel .product__quantity input {
    font-size: 1.125rem;
  }
}

.cart-panel .product__quantity {
  min-width: 56px;
  width: 56px;
  padding: 23px 0 0;
}

@media (min-width: 1920px) {
  .cart-panel .product__quantity {
    width: 68px;
  }
}

.cart-panel .product__quantity input,
.cart-panel .product__quantity button {
  border: none;
  background-color: transparent;
  vertical-align: bottom;
}

.cart-panel .product__quantity input:focus,
.cart-panel .product__quantity button:focus {
  outline: none;
}

.cart-panel .product__quantity button {
  position: relative;
  display: inline-block;
  padding: 0;
  width: 6px;
  height: 20px;
  color: #a3ff12;
}

body:not(.cursor-is--active) .cart-panel .product__quantity button {
  cursor: pointer;
}

.cart-panel .product__quantity button:after, .cart-panel .product__quantity button:before {
  position: absolute;
  content: "";
  background-color: #a3ff12;
  transition: opacity 300ms ease;
}

.cart-panel .product__quantity .quantity-control__minus:before {
  top: 8px;
  left: 0;
  width: 6px;
  height: 2px;
}

.cart-panel .product__quantity .quantity-control__plus:before {
  top: 8px;
  left: 0;
  width: 6px;
  height: 2px;
}

.cart-panel .product__quantity .quantity-control__plus:after {
  top: 6px;
  left: 2px;
  width: 2px;
  height: 6px;
}

.cart-panel .product__quantity input {
  display: inline-block;
  width: 36px;
  height: 20px;
  padding: 0;
  text-align: center;
  color: #fff;
}

.cart-panel .product__quantity input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cart-panel .product__quantity input[type="number"] {
  -moz-appearance: textfield;
}

.cart-panel .product__total:not(th) {
  color: #a3ff12;
}

.cart-panel .product__total {
  min-width: 96px;
  width: 146px;
  padding: 30px 0 0;
  text-align: center;
}

@media (min-width: 1920px) {
  .cart-panel .product__total {
    padding-top: 39px;
  }
}

.cart-panel .product__total-currency {
  color: #fff;
}

.cart-panel .product__remove {
  width: 55px;
  padding: 0 0 0 7px;
  vertical-align: middle;
}

@media (min-width: 576px) {
  .cart-panel .product__remove {
    vertical-align: top;
    padding-top: 24px;
  }
}

@media (min-width: 1920px) {
  .cart-panel .product__remove {
    padding-top: 31px;
  }
}

.cart-panel .product__remove-icon {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #5e627e;
  transition: border-color 300ms ease;
}

@media (min-width: 1920px) {
  .cart-panel .product__remove-icon {
    width: 30px;
    height: 30px;
  }
}

.cart-panel .product__remove-icon:after, .cart-panel .product__remove-icon:before {
  position: absolute;
  top: 9px;
  left: 6px;
  width: 8px;
  height: 2px;
  content: "";
  background-color: #5e627e;
  transition: background-color 300ms ease;
}

@media (min-width: 1920px) {
  .cart-panel .product__remove-icon:after, .cart-panel .product__remove-icon:before {
    top: 12px;
    left: 8px;
    width: 10px;
  }
}

.cart-panel .product__remove-icon:after {
  transform: rotate(45deg);
}

.cart-panel .product__remove-icon:before {
  transform: rotate(-45deg);
}

.cart-panel .product__remove-icon:hover {
  border-color: #fff;
}

.cart-panel .product__remove-icon:hover:after, .cart-panel .product__remove-icon:hover:before {
  background-color: #fff;
}

.cart-panel__totals {
  text-align: right;
}

.cart-panel__totals .table {
  margin-bottom: 32px;
}

@media (min-width: 1920px) {
  .cart-panel__totals .table {
    margin-bottom: 50px;
  }
}

.cart-panel__totals th, .cart-panel__totals td {
  padding: 0;
  border: none;
}

.cart-panel__subtotal, .cart-panel__shipping, .cart-panel__order-total {
  font-weight: 700;
  text-transform: uppercase;
}

.cart-panel__subtotal, .cart-panel__shipping {
  font-size: .625rem;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .cart-panel__subtotal, .cart-panel__shipping {
    font-size: .875rem;
  }
}

.cart-panel__shipping th, .cart-panel__shipping td {
  padding-top: 8px;
}

@media (min-width: 1920px) {
  .cart-panel__shipping th, .cart-panel__shipping td {
    padding-top: 11px;
  }
}

.cart-panel__order-total {
  font-size: .875rem;
}

@media (min-width: 1920px) {
  .cart-panel__order-total {
    font-size: 1.125rem;
  }
}

.cart-panel__order-total th, .cart-panel__order-total td {
  padding-top: 5px;
}

@media (min-width: 1920px) {
  .cart-panel__order-total th, .cart-panel__order-total td {
    padding-top: 10px;
  }
}

.cart-panel__order-total .amount {
  color: #a3ff12;
}

.cart-panel__order-total .amount span {
  color: #fff;
}

.search-panel {
  position: fixed;
  left: 0;
  z-index: 1103;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 72px);
  padding: 2rem;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0ms, opacity 320ms ease;
}

body:not(.cursor-is--active) .search-panel {
  cursor: pointer;
}

@media (min-width: 1920px) {
  .search-panel {
    height: calc(100% - 100px);
  }
}

.site-header--top ~ .search-panel {
  bottom: 0;
}

.site-header--bottom ~ .search-panel {
  top: 0;
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom ~ .search-panel {
    top: 72px;
    bottom: 0;
  }
}

.search-panel__content {
  width: 100%;
  max-width: 570px;
  text-align: center;
  opacity: 0;
  transform: translateY(1rem);
  transition-duration: 0;
}

@media (min-width: 1920px) {
  .search-panel__content {
    max-width: 782px;
  }
}

.site-wrapper--has-search-overlay .search-panel {
  visibility: visible;
  opacity: 1;
  background-color: rgba(21, 23, 32, 0.96);
}

.site-wrapper--has-search-overlay .search-panel__content {
  opacity: 1;
  transform: translateY(0);
  transition: opacity ease 320ms, transform ease 800ms;
}

.widger-area--before-loop {
  width: 100%;
}

@media (min-width: 992px) {
  .widger-area--before-loop {
    width: 536px;
    height: 100%;
  }
}

@media (min-width: 1920px) {
  .widger-area--before-loop {
    width: 740px;
  }
}

.widget__title {
  margin-bottom: 30px;
  font-size: 1.25rem;
}

.menu-panel .widget__title {
  color: #fff;
}

@media (min-width: 1920px) {
  .menu-panel .widget__title {
    font-size: 1.75rem;
    margin-bottom: 1.5em;
  }
}

.menu-panel .widget__content {
  font-size: .75rem;
  color: #c6cbea;
}

@media (min-width: 1920px) {
  .menu-panel .widget__content {
    font-size: 1rem;
    line-height: 1.625em;
  }
}

.widget.slick-slider {
  position: relative;
  height: calc(100vh - 72px);
}

@media (min-width: 1920px) {
  .widget.slick-slider {
    height: calc(100vh - 100px);
  }
}

.widget.slick-slider .slick-dots {
  padding-left: 0;
  list-style: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8px;
  margin-bottom: 0;
  text-align: center;
}

@media (min-width: 1920px) {
  .widget.slick-slider .slick-dots {
    bottom: 45px;
  }
}

.widget.slick-slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 10px;
  margin: 0 2px;
  border: none;
  outline: none;
  font-size: 0;
}

@media (min-width: 1920px) {
  .widget.slick-slider .slick-dots li {
    width: 30px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

body:not(.cursor-is--active) .widget.slick-slider .slick-dots li {
  cursor: pointer;
}

.widget.slick-slider .slick-dots li:before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: .3s all ease;
}

@media (min-width: 1920px) {
  .widget.slick-slider .slick-dots li:before {
    height: 4px;
  }
}

.widget.slick-slider .slick-dots li button {
  display: none;
}

.widget.slick-slider .slick-dots li:hover:before, .widget.slick-slider .slick-dots li.slick-active:before {
  background-color: #a3ff12;
}

.widget.slick-slider .slick-dots li:hover:before {
  height: 4px;
}

@media (min-width: 1920px) {
  .widget.slick-slider .slick-dots li:hover:before {
    height: 8px;
  }
}

.widget-featured-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 991.98px) {
  .widget-featured-product {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .widget-featured-product {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .widget-featured-product {
    height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .widget-featured-product {
    height: calc(100vh - 100px);
  }
}

.widget-featured-product .widget__price, .widget-featured-product .widget__title, .widget-featured-product .widget__subtitle {
  margin-bottom: 1px;
  color: #fff;
}

.widget-featured-product .widget__title {
  margin-bottom: 24px;
  font-size: 4.5rem;
}

@media (max-width: 767.98px) {
  .widget-featured-product .widget__title {
    font-size: 2.125rem;
  }
}

@media (min-width: 1920px) {
  .widget-featured-product .widget__title {
    margin-bottom: 26px;
    font-size: 5.125rem;
  }
}

.widget-featured-product .widget__title span {
  display: block;
}

.widget-featured-product .widget__subtitle {
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .widget-featured-product .widget__subtitle {
    font-size: 1.75rem;
  }
}

.widget-featured-product .widget__thumbnail {
  position: relative;
}

.widget-featured-product .widget__thumbnail img {
  width: 100%;
}

.widget-featured-product .widget__price {
  position: absolute;
  top: 27px;
  right: 20px;
}

@media (min-width: 375px) {
  .widget-featured-product .widget__price {
    left: 76.5%;
    right: auto;
  }
}

.widget-featured-product .widget__price-label, .widget-featured-product .widget__price-count {
  font-weight: bold;
  letter-spacing: -.03em;
  text-align: left;
  text-transform: uppercase;
}

.widget-featured-product .widget__price-count {
  font-size: 1.625rem;
  line-height: .7;
}

@media (min-width: 1920px) {
  .widget-featured-product .widget__price-count {
    font-size: 2.25rem;
  }
}

.widget-featured-product .add-icon {
  position: absolute;
  top: 12px;
  right: calc(100% + 10px);
}

@media (min-width: 1920px) {
  .widget-featured-product .add-icon {
    right: calc(100% + 15px);
  }
}

.widget-loop {
  color: #fff;
}

@media (max-width: 575.98px) {
  .blog-layout--style-4 .widget-loop {
    min-height: 100vw;
  }
}

@media (max-width: 767.98px) {
  .widget-loop {
    min-height: 50vw;
    width: 100%;
  }
}

@media (min-width: 576px) {
  .blog-layout--style-1 .widget-loop,
  .blog-layout--style-2 .widget-loop {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .blog-layout--style-4 .widget-loop {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-layout--style-3 .widget-loop {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .widget-loop {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .widget-loop {
    font-size: 1.82292vh;
  }
}

.widget-loop.widget-twitch a, .widget-loop.widget-twitter a, .widget-loop.widget-facebook a {
  color: #fff;
}

.widget-loop .widget-twitch__message a,
.widget-loop .widget-twitter__message a,
.widget-loop .widget-facebook__message a {
  font-weight: bold;
}

.widget-loop.widget-facebook {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #224bc0;
}

.widget-loop .widget-facebook__icon {
  flex: 0 1 45%;
  padding-right: 28px;
  font-size: 2.25rem;
  text-align: right;
}

@media (min-width: 992px) {
  .widget-loop .widget-facebook__icon {
    padding-right: 3.64583vh;
    font-size: 4.6875vh;
  }
}

.widget-loop .widget-facebook__body {
  flex: 0 1 55%;
}

.widget-loop .widget-facebook__count {
  margin-bottom: 2px;
  color: #fff;
}

@media (min-width: 992px) {
  .widget-loop .widget-facebook__count {
    margin-bottom: 0.26042vh;
    font-size: 4.16667vh;
  }
}

.widget-loop .widget-facebook__label {
  letter-spacing: -.03em;
}

.widget-loop.widget-twitter {
  display: flex;
  justify-content: center;
  background-color: #09aafe;
}

.blog-layout--style-3 .widget-loop.widget-twitter {
  align-items: center;
}

.blog-layout--style-4 .widget-loop.widget-twitter {
  flex-direction: column;
}

.widget-loop .widget-twitter__icon {
  font-size: 2.25rem;
  text-align: center;
}

@media (min-width: 992px) {
  .widget-loop .widget-twitter__icon {
    font-size: 4.6875vh;
  }
}

.blog-layout--style-3 .widget-loop .widget-twitter__icon {
  flex: 0 0 90px;
  padding-bottom: .5em;
}

@media (min-width: 992px) {
  .blog-layout--style-3 .widget-loop .widget-twitter__icon {
    flex-basis: 11.71875vh;
  }
}

.blog-layout--style-3 .widget-loop .widget-twitter__body {
  flex: 0 1 auto;
}

.blog-layout--style-4 .widget-loop .widget-twitter__body {
  padding: 15px 40px;
  text-align: center;
}

@media (min-width: 768px) {
  .blog-layout--style-4 .widget-loop .widget-twitter__body {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-4 .widget-loop .widget-twitter__body {
    padding-top: 4.16667vh;
    padding-right: 5.20833vh;
    padding-bottom: 4.16667vh;
    padding-left: 5.20833vh;
  }
}

.widget-loop .widget-twitter__message {
  letter-spacing: -.03em;
  color: #fff;
}

.blog-layout--style-3 .widget-loop .widget-twitter__message,
.blog-layout--style-4 .widget-loop .widget-twitter__message {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .blog-layout--style-3 .widget-loop .widget-twitter__message,
  .blog-layout--style-4 .widget-loop .widget-twitter__message {
    margin-bottom: 2.60417vh;
  }
}

.blog-layout--style-3 .widget-loop .widget-twitter__message {
  padding-right: 20%;
  line-height: 1.285;
}

.blog-layout--style-4 .widget-loop .widget-twitter__message {
  font-size: 1rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .blog-layout--style-4 .widget-loop .widget-twitter__message {
    font-size: 1.25rem;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-4 .widget-loop .widget-twitter__message {
    font-size: 2.60417vh;
  }
}

.widget-loop .widget-twitter__date {
  letter-spacing: -.03em;
}

.blog-layout--style-3 .widget-loop .widget-twitter__date {
  font-size: .625rem;
}

@media (min-width: 992px) {
  .blog-layout--style-3 .widget-loop .widget-twitter__date {
    font-size: 1.30208vh;
  }
}

.widget-loop.widget-twitch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #5d22e4;
}

.widget-loop.widget-twitch .countdown-counter {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .widget-loop.widget-twitch .countdown-counter {
    margin-top: 32px;
  }
}

@media (min-width: 992px) {
  .widget-loop.widget-twitch .countdown-counter {
    margin-top: 4.16667vh;
  }
}

.widget-loop.widget-twitch .countdown-counter__item {
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -.03em;
  color: #ffcc00;
}

@media (min-width: 992px) {
  .widget-loop.widget-twitch .countdown-counter__item {
    font-size: 3.38542vh;
  }
}

.widget-loop.widget-twitch .countdown-counter__item:before, .widget-loop.widget-twitch .countdown-counter__label {
  color: #fff;
}

.widget-loop.widget-twitch .countdown-counter__label {
  margin-top: 6px;
  font-size: .6875rem;
  font-weight: 500;
}

@media (min-width: 992px) {
  .widget-loop.widget-twitch .countdown-counter__label {
    margin-top: 0.78125vh;
    font-size: 1.43229vh;
  }
}

.widget-loop .widget-twitch__icon svg {
  display: block;
  margin: 0 auto;
}

.widget-loop .widget-twitch__body {
  padding: 25px 40px 0;
}

@media (min-width: 768px) {
  .widget-loop .widget-twitch__body {
    padding-top: 38px;
    padding-bottom: 38px;
  }
}

@media (min-width: 992px) {
  .widget-loop .widget-twitch__body {
    padding-top: 4.94792vh;
    padding-bottom: 4.94792vh;
    padding-right: 5.20833vh;
    padding-left: 5.20833vh;
  }
}

.widget-loop .widget-twitch__title {
  margin-bottom: 12px;
  color: #fff;
}

@media (min-width: 992px) {
  .widget-loop .widget-twitch__title {
    margin-bottom: 1.5625vh;
    font-size: 2.86458vh;
  }
}

.widget-loop .widget-twitch__message {
  line-height: 1.285;
}

.widget-carousel__item {
  display: block;
  position: relative;
  height: calc(100vh - 72px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 1920px) {
  .widget-carousel__item {
    height: calc(100vh - 100px);
  }
}

.widget-partners-carousel {
  position: relative;
}

.widget-partners-carousel .widget__title {
  display: inline-block;
}

.widget-partners-carousel ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-partners-carousel ul img {
  max-width: 165px;
}

.widget-partners-carousel .slick-arrow {
  position: absolute;
  top: 0;
  width: 20px;
  padding: 2px 0;
  border: none;
  background-color: transparent;
  font-size: 0;
  outline: 0;
  z-index: 1;
  text-align: center;
}

body:not(.cursor-is--active) .widget-partners-carousel .slick-arrow {
  cursor: pointer;
}

.widget-partners-carousel .slick-arrow svg {
  display: inline-block;
  fill: #fff;
  width: 8px;
  height: 14px;
  transition: fill 300ms ease;
}

.widget-partners-carousel .slick-arrow:hover svg {
  fill: #a3ff12;
}

.widget-partners-carousel .slick-next {
  margin-left: 28px;
}

.widget-carousel .player {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: calc( ( 100vh - 72px) * 0.12);
}

.widget-carousel .player h1, .widget-carousel .player .h1,
.widget-carousel .player h2, .widget-carousel .player .h2,
.widget-carousel .player h3, .widget-carousel .player .h3,
.widget-carousel .player h4, .widget-carousel .player .h4,
.widget-carousel .player h5, .widget-carousel .player .h5,
.widget-carousel .player h6, .widget-carousel .player .h6 {
  color: #fff;
}

.widget-carousel .player__body, .widget-carousel .player__title, .widget-carousel .player__subtitle {
  text-align: center;
}

.widget-carousel .player__title {
  margin-bottom: 10px;
  font-size: 3.5rem;
  text-transform: none;
}

@media (min-width: 1920px) {
  .widget-carousel .player__title {
    font-size: 5.125rem;
  }
}

.widget-carousel .player__subtitle {
  margin-top: auto;
  margin-bottom: 8px;
  font-weight: 500;
}

@media (min-width: 1920px) {
  .widget-carousel .player__subtitle {
    margin-bottom: 10px;
    font-size: 1.75rem;
  }
}

.widget-carousel .player__body {
  margin-bottom: 60px;
}

.widget-carousel .player__body h5, .widget-carousel .player__body .h5 {
  font-size: 1.5rem;
}

@media (min-width: 1920px) {
  .widget-carousel .player__body h5, .widget-carousel .player__body .h5 {
    font-size: 2.25rem;
  }
}

.widget-carousel .player__stats {
  padding-left: 0;
  list-style: none;
}

.widget-carousel .player__stats li {
  display: inline-block;
  margin: 0 23px;
}

.widget-carousel .player__thumbnail {
  position: relative;
  width: 100%;
  height: 62.931%;
  max-height: 448px;
  margin-top: auto;
}

@media (min-width: 1920px) {
  .widget-carousel .player__thumbnail {
    max-height: 624px;
  }
}

.widget-carousel .player__thumbnail img {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.widget-carousel .player__thumbnail .more-info-link {
  position: absolute;
  top: 18%;
  left: calc(74% + 32px);
  font-size: .875rem;
  color: #fff;
}

@media (min-width: 1920px) {
  .widget-carousel .player__thumbnail .more-info-link {
    font-size: 1.125rem;
  }
}

.widget-carousel .player__thumbnail .more-info-link:hover {
  color: #a3ff12;
}

.widget-carousel .player__thumbnail .more-info-link .add-icon {
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
  margin-top: -1px;
}

@media (min-width: 1920px) {
  .widget-carousel .player__thumbnail .more-info-link .add-icon {
    right: calc(100% + 23px);
  }
}

.widget-carousel .post__thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
}

.widget-carousel .post__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.widget-carousel .post__thumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 23, 32, 0.4);
}

.widget-carousel .post__body {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 40px 60px;
}

@media (min-width: 1920px) {
  .widget-carousel .post__body {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.widget-carousel .post__cats, .widget-carousel .post__meta {
  font-weight: bold;
  text-transform: uppercase;
}

.widget-carousel .post__cats {
  margin-bottom: 6px;
  font-size: 1.125rem;
  letter-spacing: -.06rem;
}

@media (min-width: 1920px) {
  .widget-carousel .post__cats {
    font-size: 1.5rem;
  }
}

.widget-carousel .post__meta {
  font-size: .75rem;
  letter-spacing: -.03rem;
}

.widget-carousel .post__meta,
.widget-carousel .post__meta a {
  color: #fff;
}

@media (min-width: 1920px) {
  .widget-carousel .post__meta {
    margin-top: 3px;
    font-size: 1.125rem;
  }
}

.widget-carousel .post__title {
  margin-bottom: 14px;
}

.widget-carousel .post__title a {
  color: #fff;
}

.widget-carousel .post__title a:hover {
  color: #a3ff12;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #222430;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg--landing {
  background-image: url(../img/bg-landing.jpg);
}

.bg--texture-01 {
  background-image: url(../img/bg-texture-01.jpg);
}

.bg--matches-01 {
  background-image: url(../img/bg-matches-01.jpg);
}

.bg--matches-02 {
  background-image: url(../img/bg-matches-02.jpg);
}

.bg--matches-03 {
  background-image: url(../img/bg-matches-03.jpg);
}

.bg--ph-01 {
  background-image: url(../img/page-heading-img-01.jpg);
}

.bg--ph-02 {
  background-image: url(../img/page-heading-img-02.jpg);
}

.bg--ph-03 {
  background-image: url(../img/page-heading-img-03.jpg);
}

.bg--ph-04 {
  background-image: url(../img/page-heading-img-04.jpg);
}

.bg--ph-05 {
  background-image: url(../img/page-heading-img-05.jpg);
}

.bg--ph-06 {
  background-image: url(../img/page-heading-img-06.jpg);
}

.bg--product-color-01 {
  background-color: #222430;
}

.bg--product-color-02 {
  background-color: #ff1c5c;
}

.bg--product-color-03 {
  background-color: #c6cbea;
}

.bg--checkout-login {
  background-image: url(../img/checkout-login-bg.jpg);
}

.bg--checkout-redeem {
  background-image: url(../img/checkout-redeem-bg.jpg);
}

.bg--dotted-3x3::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url(../img/3x3-black.gif);
  background-repeat: repeat;
  background-position: 50% 0;
  z-index: -1;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .bg--dotted-3x3::before {
    background-image: url(../img/3x3-black@2x.gif);
    background-size: 3px 3px;
  }
}

.bc-color-pallete-01 {
  border-color: #151720;
}

.bc-color-pallete-02 {
  border-color: #222430;
}

.bc-color-pallete-03 {
  border-color: #5e627e;
}

.bc-color-pallete-04 {
  border-color: #ced0da;
}

.bc-color-pallete-05 {
  border-color: #ffcc00;
}

.bc-color-pallete-06 {
  border-color: #ff1c5c;
}

.bc-color-pallete-07 {
  border-color: #88df00;
}

.bc-color-pallete-08 {
  border-color: #a3ff12;
}

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
}

.cursor--small {
  width: 5px;
  height: 5px;
  left: -2.5px;
  top: -2.5px;
  border-radius: 50%;
  z-index: 1100000;
  background-color: #a3ff12;
}

.cursor--small:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  background-color: rgba(163, 255, 18, 0.5);
  transition: .2s all ease;
}

.cursor--small.cursor--active {
  background-color: transparent;
}

.cursor--small.cursor--active:before {
  opacity: 1;
}

.cursor--canvas {
  width: 100vw;
  height: 100vh;
  z-index: 1200000;
  color: rgba(163, 255, 18, 0.5);
  transition: .2s all ease;
}

.cursor--canvas.cursor--active {
  opacity: 0;
}

.cursor-is--active,
.cursor-is--active a,
.cursor-is--active button,
.cursor-is--active .btn:not(:disabled):not(.disabled) {
  cursor: none;
}

.site-header--top, .site-header--bottom {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  height: 72px;
  background-color: #151720;
  z-index: 1105;
}

@media (min-width: 1920px) {
  .site-header--top, .site-header--bottom {
    height: 100px;
  }
}

.site-header--top .main-nav, .site-header--bottom .main-nav {
  padding-left: 202px;
}

@media (min-width: 1920px) {
  .site-header--top .main-nav, .site-header--bottom .main-nav {
    padding-left: 326px;
  }
}

.site-header--top .header-actions, .site-header--bottom .header-actions {
  margin-left: auto;
}

.site-header--top {
  top: 0;
}

.site-header--bottom {
  bottom: 0;
}

.site-header--landing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 43px;
}

@media (min-width: 992px) {
  .site-layout--landing .site-header--landing {
    padding-top: 5.598958vh;
  }
}

@media (max-width: 991.98px) {
  html.touch .site-header {
    top: 0;
    bottom: auto;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@media (max-width: 991.98px) {
  html.touch .site-header.unpinned {
    transform: translateY(-100%);
  }
  html.touch .site-header.unpinned .header-logo {
    opacity: 0;
  }
}

@media (max-width: 991.98px) {
  html.touch .site-wrapper--has-overlay .site-header,
  html.touch .site-wrapper--has-menu-overlay .site-header,
  html.touch .site-wrapper--has-search-overlay .site-header {
    transform: translateY(0);
  }
}

body {
  position: relative;
  overflow: hidden;
  background-color: #222430;
}

body.scroll-is--active {
  overflow-y: initial;
}

body.site-layout--horizontal {
  background: url(../img/bg-texture-01.jpg) center/cover no-repeat fixed;
}

.row {
  min-width: 100%;
}

@media (min-width: 1920px) {
  .container--large {
    max-width: 1640px;
  }
}

.site-wrapper {
  min-height: 100vh;
}

.site-layout--horizontal .site-wrapper {
  overflow-x: initial;
  overflow-y: initial;
  max-height: 100vh;
}

@media (min-width: 992px) {
  .site-layout--horizontal .site-wrapper {
    overflow-y: hidden;
  }
}

.site-layout--landing .site-wrapper {
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .site-layout--landing .site-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.site-content {
  transition: 0.7s 0.6s transform cubic-bezier(0.86, 0, 0.07, 1);
}

.site-content--center .container {
  min-height: calc(100vh - 78px - 40px - 72px);
}

@media (min-width: 576px) {
  .site-content--center .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .site-content--center .container {
    min-height: calc(100vh - 78px - 80px - 72px);
  }
}

@media (min-width: 1920px) {
  .site-content--center .container {
    min-height: calc(100vh - 78px - 80px - 100px);
  }
}

.site-header--top + .site-content {
  padding-top: 72px;
}

@media (min-width: 1920px) {
  .site-header--top + .site-content {
    padding-top: 100px;
  }
}

.site-header--bottom + .site-content {
  padding-bottom: 72px;
}

@media (min-width: 1920px) {
  .site-header--bottom + .site-content {
    padding-bottom: 100px;
  }
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom + .site-content {
    padding-bottom: 0;
    padding-top: 72px;
  }
}

@media (min-width: 992px) {
  .site-layout--horizontal .site-content {
    width: max-content;
    position: relative;
    display: flex;
    height: 100vh;
    min-width: 100%;
  }
}

.preloader-is--active .site-content {
  overflow: hidden;
  transform: scale(1.5);
}

@media (min-width: 992px) {
  .scroll-is--active .site-layout--horizontal .site-content {
    transition: transform 1200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

@media (min-width: 768px) {
  .site-layout--landing .site-content {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.site-content--product-single {
  background-color: #f2f3f7;
}

.site-content.login-page {
  text-align: center;
}

.site-content.login-page h2 {
  width: 100%;
  text-align: center;
}

@media (min-width: 1440px) {
  .site-content.login-page h2 {
    max-width: 320px;
  }
}

@media (min-width: 1920px) {
  .site-content.login-page h2 {
    max-width: 440px;
  }
}

.site-content.login-page .row {
  min-height: calc(100vh - 72px);
}

@media (min-width: 1920px) {
  .site-content.login-page .row {
    min-height: calc(100vh - 100px);
  }
}

.site-content.login-page .row > * {
  padding: 48px 34px;
}

@media (min-width: 1440px) {
  .site-content.login-page .row > * {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .site-content.login-page .row > * {
    min-height: calc(100vh - 100px);
  }
}

.site-content.login-page .row > *:nth-child(odd) {
  background-color: #fff;
}

@media (min-width: 1440px) {
  .site-content.login-page .row > *:nth-child(odd) {
    padding: 78px 180px 78px 50px;
  }
  .site-content.login-page .row > *:nth-child(odd) > * {
    margin-left: auto;
  }
}

@media (min-width: 1920px) {
  .site-content.login-page .row > *:nth-child(odd) {
    padding-right: 260px;
  }
}

.site-content.login-page .row > *:nth-child(even) {
  background-color: #f7f8fa;
}

@media (min-width: 1440px) {
  .site-content.login-page .row > *:nth-child(even) {
    padding: 78px 50px 78px 180px;
  }
  .site-content.login-page .row > *:nth-child(even) > * {
    margin-right: auto;
  }
}

@media (min-width: 1920px) {
  .site-content.login-page .row > *:nth-child(even) {
    padding-left: 260px;
  }
}

.site-layout--default .site-content {
  min-height: 100vh;
}

.site-header--top + .site-content.page {
  padding-top: calc(40px + 72px);
  padding-bottom: 78px;
}

@media (min-width: 768px) {
  .site-header--top + .site-content.page {
    padding-top: calc(80px + 72px);
  }
}

@media (min-width: 1920px) {
  .site-header--top + .site-content.page {
    padding-top: calc(80px + 100px);
  }
}

.site-header--bottom + .site-content.page {
  padding-top: 78px;
  padding-bottom: calc(40px + 72px);
}

@media (min-width: 768px) {
  .site-header--bottom + .site-content.page {
    padding-bottom: calc(80px + 72px);
  }
}

@media (min-width: 1920px) {
  .site-header--bottom + .site-content.page {
    padding-bottom: calc(80px + 100px);
  }
}

@media (max-width: 991.98px) {
  html.touch .site-header--bottom + .site-content.page {
    padding-top: calc(100px + 72px);
    padding-bottom: 78px;
  }
}

@media (max-width: 767.98px) {
  html.touch .site-header--bottom + .site-content.page {
    padding-top: calc(40px + 72px);
  }
}

.site-layout--landing .site-content__center {
  position: relative;
  padding-top: 40px;
  padding-bottom: 66px;
  z-index: 1;
}

@media (min-width: 992px) {
  .site-layout--landing .site-content__center {
    padding-top: 5.208333vh;
    padding-bottom: 8.59375vh;
  }
}

.site-content__inner {
  min-height: calc(100vh - 72px);
}

@media (min-width: 1920px) {
  .site-content__inner {
    min-height: calc(100vh - 100px);
  }
}

.site-layout--default .site-content__inner {
  margin-left: auto;
  padding: 60px 24px;
  background-color: #fff;
}

@media (min-width: 992px) {
  .site-layout--default .site-content__inner {
    width: 606px;
    padding: 68px 90px 100px 70px;
    box-sizing: border-box;
  }
}

@media (min-width: 1440px) {
  .site-layout--default .site-content__inner {
    width: 61.4585%;
  }
  .site-layout--default .site-content__inner .site-content__holder {
    max-width: 675px;
  }
}

@media (min-width: 1920px) {
  .site-layout--default .site-content__inner {
    padding: 95px 120px;
  }
  .site-layout--default .site-content__inner .site-content__holder {
    max-width: 920px;
  }
}

@media (min-width: 1440px) {
  .site-layout--default .contact-us-page .site-content__inner {
    width: 860px;
  }
}

@media (min-width: 1920px) {
  .site-layout--default .contact-us-page .site-content__inner {
    font-size: 1rem;
  }
}

.page-thumbnail {
  margin: -60px -24px 40px;
}

.page-thumbnail img {
  width: 100%;
}

@media (min-width: 992px) {
  .page-thumbnail {
    position: fixed;
    left: 0;
    width: calc(100% - 606px);
    height: calc(100vh - 72px);
    margin: 0;
  }
  .site-header--top ~ .site-content .page-thumbnail {
    bottom: 0;
  }
  .site-header--bottom ~ .site-content .page-thumbnail {
    top: 0;
  }
  .page-thumbnail a {
    display: block;
    width: 100%;
  }
  .page-thumbnail img:not(.page-bg-logo) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1440px) {
  .page-thumbnail {
    width: 38.5415%;
  }
}

.page-thumbnail--default {
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
  background: url(../img/page-bg-base.jpg) center/cover no-repeat;
}

@media (min-width: 992px) {
  .page-thumbnail--default {
    position: fixed;
    padding-bottom: 0;
  }
}

.page-thumbnail--default:after, .page-thumbnail--default:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(-40%, 100%, 0);
  transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
}

.page-thumbnail--default:before {
  background: url(../img/page-bg-detail-1.png) center/cover no-repeat;
}

.page-thumbnail--default:after {
  background: url(../img/page-bg-detail-2.png) center/cover no-repeat;
  transition-delay: 150ms;
}

.page-thumbnail--default .page-bg-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  z-index: 1;
  opacity: 0;
  transform: scale(1.2) translate3d(-50%, -50%, 0);
  transition: all 500ms ease 400ms;
}

@media (min-width: 1920px) {
  .page-thumbnail--default .page-bg-logo {
    width: 62%;
  }
}

.scroll-is--active .page-thumbnail--default:after, .scroll-is--active .page-thumbnail--default:before {
  transform: translate3d(0, 0, 0);
}

.scroll-is--active .page-thumbnail--default .page-bg-logo {
  opacity: 1;
  transform: scale(1) translate3d(-50%, -50%, 0);
}

.page-title {
  margin-bottom: 50px;
}

@media (min-width: 1920px) {
  .page-title {
    margin-bottom: 86px;
  }
}

.page-content > *:last-child {
  margin-bottom: 0;
}

.page-content > p:first-child {
  margin-top: -6px;
}

.preloader {
  margin-left: auto;
  margin-right: auto;
}

.preloader svg {
  fill: #5e627e;
}

.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #151720;
  z-index: 999999;
  transform: translateY(100%);
  transition: 0.7s 0.55s all cubic-bezier(0.86, 0, 0.07, 1);
}

.preloader-is--active .preloader-overlay {
  transform: translateY(0);
}

.preloader-inner {
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.pong-loader {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 36.5%;
  height: 20px;
  width: 3px;
  background-color: transparent;
  animation: paddles 0.75s ease-out infinite;
  transform: translate3d(0, 0, 0);
}

.pong-loader:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 7.5px;
  width: 5px;
  height: 5px;
  background-color: #a3ff12;
  border-radius: 50%;
  animation: ballbounce 0.6s ease-out infinite;
}

@keyframes paddles {
  0% {
    box-shadow: -12.5px -5px 0px #fff, 12.5px 5px 0px #fff;
  }
  50% {
    box-shadow: -12.5px 8px 0px #fff, 12.5px -5px 0px #fff;
  }
  100% {
    box-shadow: -12.5px -5px 0px #fff, 12.5px 5px 0px #fff;
  }
}

@keyframes ballbounce {
  0% {
    transform: translateX(-10px) scale(1, 1.2);
  }
  25% {
    transform: scale(1.2, 1);
  }
  50% {
    transform: translateX(7.5px) scale(1, 1.2);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: translateX(-10px);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.footer {
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .footer {
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .footer {
    padding-bottom: 16.53675vh;
  }
}

@media (min-width: 576px) {
  .content.blog-layout--style-1, .content.blog-layout--style-2, .content.blog-layout--style-3, .content.blog-layout--style-4 {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .content.blog-layout--style-1, .content.blog-layout--style-2, .content.blog-layout--style-3, .content.blog-layout--style-4 {
    display: grid;
    grid-auto-flow: column;
  }
}

@media (min-width: 992px) {
  .content.blog-layout--style-1, .content.blog-layout--style-2 {
    grid-auto-columns: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .content.blog-layout--style-1, .content.blog-layout--style-2 {
    grid-auto-columns: calc(100vh - 100px);
  }
}

@media (min-width: 992px) {
  .content.blog-layout--style-3, .content.blog-layout--style-4 {
    grid-auto-columns: calc(50vh - 72px / 2);
  }
}

@media (min-width: 1920px) {
  .content.blog-layout--style-3, .content.blog-layout--style-4 {
    grid-auto-columns: calc(50vh - 100px / 2);
  }
}

@media (min-width: 992px) {
  .content.blog-layout--style-1, .content.blog-layout--style-4 {
    grid-template-rows: 50% 50%;
  }
}

@media (min-width: 992px) {
  .content.blog-layout--style-2, .content.blog-layout--style-3 {
    grid-template-rows: 25% 25% 25% 25%;
  }
}

.blog-layout--style-1 .post,
.blog-layout--style-2 .post,
.blog-layout--style-3 .post,
.blog-layout--style-4 .post {
  overflow: hidden;
  background-color: #fff;
}

.blog-layout--style-1 .post,
.blog-layout--style-2 .post {
  display: block;
}

@media (min-width: 576px) {
  .blog-layout--style-1 .post,
  .blog-layout--style-2 .post {
    display: flex;
  }
}

.blog-layout--style-3 .post {
  display: flex;
}

.blog-layout--style-1 .post,
.blog-layout--style-2 .post {
  width: 100%;
}

.blog-layout--style-1 .post:nth-child(odd) .post__thumbnail {
  order: 2;
}

.blog-layout--style-2 .post:nth-child(even) .post__thumbnail,
.blog-layout--style-3 .post:nth-child(even) .post__thumbnail {
  order: 2;
}

.blog-layout--style-3 .post {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-layout--style-3 .post:nth-child(even) .post__thumbnail {
    order: initial;
  }
}

@media (min-width: 768px) {
  .blog-layout--style-3 .post {
    width: 50%;
  }
}

.blog-layout--style-4 .post {
  width: 100%;
}

@media (min-width: 576px) {
  .blog-layout--style-4 .post {
    width: 50%;
  }
}

.blog-layout--style-3 .post,
.blog-layout--style-4 .post {
  position: relative;
}

@media (min-width: 992px) {
  .blog-layout--style-3 .post,
  .blog-layout--style-4 .post {
    width: 100%;
  }
}

.post--single .post__header {
  margin-bottom: 43px;
}

@media (max-width: 991.98px) {
  .post--single .post__header {
    padding-top: 40px;
  }
}

@media (min-width: 1920px) {
  .post--single .post__header {
    margin-bottom: 59px;
  }
}

.blog-layout--style-1 .post__body,
.blog-layout--style-2 .post__body,
.blog-layout--style-3 .post__body {
  overflow: hidden;
}

.blog-layout--style-1 .post__body,
.blog-layout--style-2 .post__body,
.blog-layout--style-4 .post__body {
  padding: 32px 40px;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__body,
  .blog-layout--style-2 .post__body,
  .blog-layout--style-4 .post__body {
    padding-top: 4.16667vh;
    padding-right: 5.20833vh;
    padding-bottom: 4.16667vh;
    padding-left: 5.20833vh;
  }
}

@media (min-width: 576px) {
  .blog-layout--style-1 .post__body,
  .blog-layout--style-2 .post__body {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .blog-layout--style-1 .post__body,
  .blog-layout--style-2 .post__body {
    padding: 22px 30px;
  }
}

.blog-layout--style-1 .post__body {
  position: relative;
}

.blog-layout--style-1 .post__body:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  content: "";
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 50%);
}

.blog-layout--style-3 .post__body {
  width: 50%;
  padding: 10px 16px;
}

@media (min-width: 375px) {
  .blog-layout--style-3 .post__body {
    padding: 19px 24px;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-3 .post__body {
    padding-top: 2.47396vh;
    padding-right: 3.125vh;
    padding-bottom: 2.47396vh;
    padding-left: 3.125vh;
  }
}

.blog-layout--style-4 .post__body {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: transform 700ms ease;
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post__body {
    transform: translateY(100px);
  }
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post__body {
    transform: translateY(13.02083vh);
  }
}

.post--single .post__body p {
  margin-bottom: 1.375rem;
}

@media (min-width: 1920px) {
  .post--single .post__body p {
    margin-bottom: 1.75rem;
  }
}

.post--single .post__body p:last-child {
  margin-bottom: 0;
}

.post--single .post__body figure {
  margin-top: 52px;
  margin-bottom: 52px;
}

@media (min-width: 1920px) {
  .post--single .post__body figure {
    margin-top: 69px;
    margin-bottom: 69px;
  }
}

.post--single .post__body figure img {
  width: 100%;
}

.post--single .post__body figure figcaption {
  position: relative;
  padding: 13px 16px 10px 56px;
  font-size: .625rem;
  font-weight: 700;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
  background-color: #222430;
}

@media (min-width: 1920px) {
  .post--single .post__body figure figcaption {
    padding: 19px 46px 17px 86px;
    font-size: .875rem;
  }
}

.post--single .post__body figure figcaption:after, .post--single .post__body figure figcaption:before {
  position: absolute;
}

.post--single .post__body figure figcaption:before {
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  content: "";
  background-color: #a3ff12;
}

@media (min-width: 1920px) {
  .post--single .post__body figure figcaption:before {
    width: 60px;
  }
}

.post--single .post__body figure figcaption:after {
  top: 50%;
  left: 9px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: .75rem;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  content: "i";
  background-color: #222430;
  transform: translateY(-50%);
}

@media (min-width: 1920px) {
  .post--single .post__body figure figcaption:after {
    left: 14px;
    width: 32px;
    height: 32px;
    font-size: 1.125rem;
    line-height: 34px;
  }
}

.post:hover .post__body {
  transform: translateY(0px);
}

.post__thumbnail {
  display: block;
}

.blog-layout--style-1 .post__thumbnail,
.blog-layout--style-2 .post__thumbnail,
.blog-layout--style-3 .post__thumbnail {
  height: 100%;
  background-color: #f2f3f7;
}

.blog-layout--style-3 .post__thumbnail {
  width: 50%;
}

@media (min-width: 576px) {
  .blog-layout--style-1 .post__thumbnail,
  .blog-layout--style-2 .post__thumbnail {
    width: 50%;
  }
}

.post:not(.post--video) .post__thumbnail > a {
  position: relative;
  display: block;
  overflow: hidden;
}

.post:not(.post--video) .post__thumbnail > a img {
  transition: .4s transform ease;
}

.post:not(.post--video) .post__thumbnail > a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: .4s opacity ease;
  z-index: 1;
}

.post__thumbnail img {
  width: 100%;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .blog-layout--style-2 .post__thumbnail img {
    height: 100%;
    object-fit: cover;
  }
}

.blog-layout--style-4 .post__thumbnail {
  position: relative;
}

.blog-layout--style-4 .post__thumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.post--single .post__thumbnail {
  margin: -60px -24px 0;
}

.post--single .post__thumbnail img {
  width: 100%;
}

@media (min-width: 992px) {
  .post--single .post__thumbnail {
    position: fixed;
    left: 0;
    width: calc(100% - 606px);
    height: calc(100vh - 72px);
    margin: 0;
  }
  .site-header--top ~ .site-content .post--single .post__thumbnail {
    bottom: 0;
  }
  .site-header--bottom ~ .site-content .post--single .post__thumbnail {
    top: 0;
  }
  .post--single .post__thumbnail a {
    display: block;
    width: 100%;
  }
  .post--single .post__thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1440px) {
  .post--single .post__thumbnail {
    width: 38.5415%;
  }
}

.blog-layout--style-1 .post--video .post__thumbnail > a,
.blog-layout--style-2 .post--video .post__thumbnail > a,
.blog-layout--style-3 .post--video .post__thumbnail > a {
  position: relative;
  display: block;
  overflow: hidden;
}

.blog-layout--style-1 .post--video .post__thumbnail > a:before,
.blog-layout--style-2 .post--video .post__thumbnail > a:before,
.blog-layout--style-3 .post--video .post__thumbnail > a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: .4 background-color ease;
  z-index: 1;
}

.blog-layout--style-1 .post--video .post__thumbnail > a img,
.blog-layout--style-2 .post--video .post__thumbnail > a img,
.blog-layout--style-3 .post--video .post__thumbnail > a img {
  transition: .4s transform ease;
}

.blog-layout--style-1 .post--video .post__thumbnail-icon,
.blog-layout--style-2 .post--video .post__thumbnail-icon,
.blog-layout--style-3 .post--video .post__thumbnail-icon,
.blog-layout--style-4 .post--video .post__thumbnail-icon {
  border-radius: 50%;
  background-color: #ff1c5c;
}

.blog-layout--style-1 .post--video .post__thumbnail-icon,
.blog-layout--style-2 .post--video .post__thumbnail-icon,
.blog-layout--style-3 .post--video .post__thumbnail-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  margin-left: -22px;
  z-index: 2;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post--video .post__thumbnail-icon,
  .blog-layout--style-2 .post--video .post__thumbnail-icon,
  .blog-layout--style-3 .post--video .post__thumbnail-icon {
    width: 5.72917vh;
    height: 5.72917vh;
    margin-top: -2.86458vh;
    margin-left: -2.86458vh;
  }
}

.blog-layout--style-4 .post--video .post__thumbnail-icon {
  float: left;
  top: 3px;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post--video .post__thumbnail-icon {
    top: 0.39062vh;
    width: 2.08333vh;
    height: 2.08333vh;
    margin-right: 1.04167vh;
  }
}

.blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon,
.blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon,
.blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon,
.blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon {
  right: 47.5%;
}

.blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon,
.blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon,
.blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon, .blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon:after,
.blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon:after,
.blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon:after, .blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon:before,
.blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon:before,
.blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon:before {
  width: .875rem;
  height: .875rem;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon,
  .blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon,
  .blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon, .blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon:after,
  .blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon:after,
  .blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon:after, .blog-layout--style-1 .post--video .post__thumbnail-icon .lightbox__icon:before,
  .blog-layout--style-2 .post--video .post__thumbnail-icon .lightbox__icon:before,
  .blog-layout--style-3 .post--video .post__thumbnail-icon .lightbox__icon:before {
    width: 1.82292vh;
    height: 1.82292vh;
  }
}

.blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon, .blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon:after, .blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon:before {
  width: .375rem;
  height: .375rem;
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon, .blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon:after, .blog-layout--style-4 .post--video .post__thumbnail-icon .lightbox__icon:before {
    width: 0.78125vh;
    height: 0.78125vh;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .blog-layout--style-1 .post__title,
  .blog-layout--style-2 .post__title {
    font-size: 1.5rem;
  }
}

.blog-layout--style-1 .post__title,
.blog-layout--style-4 .post__title {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__title,
  .blog-layout--style-4 .post__title {
    margin-bottom: 1.30208vh;
    font-size: 4.16667vh;
  }
}

.blog-layout--style-2 .post__title {
  margin-bottom: 5px;
  font-size: 1.5rem;
  line-height: .92;
}

@media (min-width: 992px) {
  .blog-layout--style-2 .post__title {
    margin-bottom: 0.65104vh;
    font-size: 3.125vh;
  }
}

.blog-layout--style-3 .post__title {
  margin-bottom: 3px;
}

@media (max-width: 374px) {
  .blog-layout--style-3 .post__title {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-3 .post__title {
    margin-bottom: 0.39062vh;
    font-size: 2.34375vh;
  }
}

.blog-layout--style-4 .post__title a:hover {
  color: #a3ff12;
}

.blog-layout--style-1 .post__title a,
.blog-layout--style-2 .post__title a,
.blog-layout--style-3 .post__title a {
  color: #222430;
}

.blog-layout--style-4 .post__title a {
  color: #fff;
}

.post--single .post__cats, .post--single .post__title {
  margin-bottom: 11px;
}

@media (min-width: 1920px) {
  .post--single .post__cats, .post--single .post__title {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__cats,
  .blog-layout--style-2 .post__cats,
  .blog-layout--style-3 .post__cats,
  .blog-layout--style-4 .post__cats {
    font-size: 1.82292vh;
  }
}

.blog-layout--style-1 .post__cats,
.blog-layout--style-4 .post__cats {
  margin-bottom: 4px;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__cats,
  .blog-layout--style-4 .post__cats {
    margin-bottom: 0.52083vh;
  }
}

.blog-layout--style-2 .post__cats,
.blog-layout--style-3 .post__cats {
  margin-bottom: 0;
}

.blog-layout--style-1 .post__cats-item,
.blog-layout--style-2 .post__cats-item,
.blog-layout--style-3 .post__cats-item,
.blog-layout--style-4 .post__cats-item {
  font-weight: bold;
  letter-spacing: -.06em;
  text-transform: uppercase;
}

.blog-layout--style-1 .post__cats-item,
.blog-layout--style-2 .post__cats-item {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__cats-item,
  .blog-layout--style-2 .post__cats-item {
    font-size: 2.08333vh;
  }
}

.blog-layout--style-3 .post__cats-item {
  font-size: .75rem;
}

@media (min-width: 992px) {
  .blog-layout--style-3 .post__cats-item {
    font-size: 1.5625vh;
  }
}

.post__cats-item a {
  color: inherit;
}

.blog-layout--style-1 .post__meta,
.blog-layout--style-3 .post__meta {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__meta,
  .blog-layout--style-3 .post__meta {
    margin-bottom: 2.60417vh;
  }
}

.blog-layout--style-2 .post__meta,
.blog-layout--style-4 .post__meta {
  margin-bottom: 0;
}

.blog-layout--style-1 .post__meta-item,
.blog-layout--style-2 .post__meta-item,
.blog-layout--style-3 .post__meta-item,
.blog-layout--style-4 .post__meta-item {
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

.blog-layout--style-1 .post__meta-item,
.blog-layout--style-2 .post__meta-item,
.blog-layout--style-4 .post__meta-item {
  font-size: .75rem;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__meta-item,
  .blog-layout--style-2 .post__meta-item,
  .blog-layout--style-4 .post__meta-item {
    font-size: 1.5625vh;
  }
}

.blog-layout--style-3 .post__meta-item {
  font-size: .625rem;
}

@media (min-width: 992px) {
  .blog-layout--style-3 .post__meta-item {
    font-size: 1.30208vh;
  }
}

.post__meta-item a {
  color: #222430;
}

.post__meta-item a:hover {
  color: inherit;
}

.blog-layout--style-4 .post__meta-item a {
  color: #fff;
}

.blog-layout--style-1 .post__excerpt,
.blog-layout--style-2 .post__excerpt,
.blog-layout--style-3 .post__excerpt,
.blog-layout--style-4 .post__excerpt {
  font-size: .75rem;
  line-height: 1.667;
}

@media (min-width: 992px) {
  .blog-layout--style-1 .post__excerpt,
  .blog-layout--style-2 .post__excerpt,
  .blog-layout--style-3 .post__excerpt,
  .blog-layout--style-4 .post__excerpt {
    font-size: 1.5625vh;
  }
}

@media (max-width: 991.98px) {
  .blog-layout--style-4 .post__excerpt {
    display: none;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post__excerpt {
    margin-top: 22px;
    color: #c6cbea;
    opacity: 0;
    transition: opacity 500ms ease;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post__excerpt {
    margin-top: 2.86458vh;
  }
}

@media (min-width: 992px) {
  .blog-layout--style-4 .post:hover .post__excerpt {
    opacity: 1;
  }
}

.blog-layout--style-1 .post:not(.post--video):hover .post__thumbnail > a:before,
.blog-layout--style-2 .post:not(.post--video):hover .post__thumbnail > a:before,
.blog-layout--style-3 .post:not(.post--video):hover .post__thumbnail > a:before {
  opacity: 1;
}

.blog-layout--style-1 .post:not(.post--video):hover .post__thumbnail > a img,
.blog-layout--style-2 .post:not(.post--video):hover .post__thumbnail > a img,
.blog-layout--style-3 .post:not(.post--video):hover .post__thumbnail > a img {
  transform: scale(1.025);
}

.blog-layout--style-1 .post--video:hover .post__thumbnail > a:before,
.blog-layout--style-2 .post--video:hover .post__thumbnail > a:before,
.blog-layout--style-3 .post--video:hover .post__thumbnail > a:before {
  background-color: rgba(0, 0, 0, 0.6);
}

.blog-layout--style-1 .post--video:hover .post__thumbnail > a img,
.blog-layout--style-2 .post--video:hover .post__thumbnail > a img,
.blog-layout--style-3 .post--video:hover .post__thumbnail > a img {
  transform: scale(1.025);
}

.blog-layout--style-1 .post:hover .post__title a,
.blog-layout--style-2 .post:hover .post__title a,
.blog-layout--style-3 .post:hover .post__title a {
  color: #5e627e;
}

.post--single .meta-item {
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .post--single .meta-item {
    font-size: 1.125rem;
  }
}

.post__sharing {
  padding-left: 0;
  list-style: none;
}

@media (max-width: 991.98px) {
  .post__sharing {
    display: flex;
    justify-content: flex-end;
    margin-right: -24px;
    margin-bottom: -24px;
  }
}

@media (min-width: 992px) {
  .post__sharing {
    position: fixed;
    right: 0;
    margin-bottom: 0;
  }
  .site-header--top ~ .site-content .post__sharing {
    bottom: 0;
  }
  .site-header--bottom ~ .site-content .post__sharing {
    top: 0;
  }
}

.post__sharing li {
  width: 40px;
  height: 40px;
}

@media (min-width: 1920px) {
  .post__sharing li {
    width: 60px;
    height: 60px;
  }
}

.post__sharing-item {
  transition: .3s opacity ease;
}

.post__sharing-item--menu {
  position: relative;
  background-color: #151720;
}

.post__sharing-item--menu a {
  display: block;
  height: 100%;
}

.post__sharing-item--menu i {
  display: block;
  position: relative;
  height: 16px;
  width: 16px;
  transition: background 0.3s;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0px);
}

@media (min-width: 1920px) {
  .post__sharing-item--menu i {
    height: 32px;
    width: 32px;
  }
}

body:not(.cursor-is--active) .post__sharing-item--menu i {
  cursor: pointer;
}

.post__sharing-item--menu i:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0px);
  height: 2px;
  width: 2px;
  background-color: #fff;
  box-shadow: -4px -4px 0 0 #fff, 0px -4px 0 0 #fff, 4px -4px 0 0 #fff, -4px 0px 0 0 #fff, 4px 0px 0 0 #fff, -4px 4px 0 0 #fff, 0px 4px 0 0 #fff, 4px 4px 0 0 #fff;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 1920px) {
  .post__sharing-item--menu i:after {
    height: 4px;
    width: 4px;
    box-shadow: -8px -8px 0 0 #fff, 0px -8px 0 0 #fff, 8px -8px 0 0 #fff, -8px 0px 0 0 #fff, 8px 0px 0 0 #fff, -8px 8px 0 0 #fff, 0px 8px 0 0 #fff, 8px 8px 0 0 #fff;
  }
}

.post__sharing-item--menu a:hover i:after {
  background: none;
  box-shadow: -6px -6px 0 0 transparent, 2px -4px 0 0 #fff, 6px -6px 0 0 transparent, 2px 4px 0 0 #fff, 0px -2px 0 0 #fff, -6px 6px 0 0 transparent, 0px 2px 0 0 #fff, 6px 6px 0 0 transparent, -2px 0px 0 0 #fff;
}

@media (min-width: 1920px) {
  .post__sharing-item--menu a:hover i:after {
    box-shadow: -12px -12px 0 0 transparent, 4px -8px 0 0 #fff, 12px -12px 0 0 transparent, 4px 8px 0 0 #fff, 0px -4px 0 0 #fff, -12px 12px 0 0 transparent, 0px 4px 0 0 #fff, 12px 12px 0 0 transparent, -4px 0px 0 0 #fff;
  }
}

.post__sharing-item--comments {
  background-color: #5e627e;
}

.post__sharing-item--comments a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 46px;
  text-align: center;
}

@media (min-width: 1920px) {
  .post__sharing-item--comments a {
    line-height: 66px;
  }
}

.post__sharing-item--comments span {
  position: absolute;
  bottom: 3px;
  right: 9px;
  font-size: .5rem;
  font-weight: bold;
  color: #fff;
}

@media (min-width: 1920px) {
  .post__sharing-item--comments span {
    bottom: 6px;
    right: 12px;
    font-size: .75rem;
  }
}

.post__sharing-item--comments svg {
  width: 10px;
  height: 10px;
  fill: #fff;
}

@media (min-width: 1920px) {
  .post__sharing-item--comments svg {
    width: 15px;
    height: 15px;
  }
}

.post__sharing-item:not(.post__sharing-item--menu):hover {
  opacity: .75;
}

.post__sharing a {
  display: block;
}

.post__sharing a:before {
  display: block;
  font-size: .75rem;
  line-height: 40px;
  text-align: center;
  color: #fff;
}

@media (min-width: 1920px) {
  .post__sharing a:before {
    font-size: 1rem;
    line-height: 60px;
  }
}

.post-comments__title {
  margin-bottom: 75px;
}

@media (min-width: 1920px) {
  .post-comments__title {
    margin-bottom: 93px;
  }
}

.post + .post-comments {
  margin-top: 90px;
}

@media (min-width: 1920px) {
  .post + .post-comments {
    margin-top: 105px;
  }
}

.comments,
.comments .children {
  padding-left: 0;
  list-style: none;
}

.comment__avatar {
  float: left;
  width: 60px;
  margin-right: 24px;
}

@media (min-width: 1920px) {
  .comment__avatar {
    width: 80px;
    margin-right: 40px;
  }
}

.comment__avatar img {
  width: 100%;
}

.comment__body {
  overflow: hidden;
}

.comment__body p {
  margin-bottom: 14px;
}

.comment__author {
  margin-bottom: 13px;
  font-size: .875rem;
}

@media (min-width: 1920px) {
  .comment__author {
    margin-top: -3px;
    margin-bottom: 19px;
    font-size: 1.25rem;
  }
}

.comment__meta > * {
  display: inline-block;
}

.comment__meta > * + * {
  margin-left: 22px;
}

@media (min-width: 1920px) {
  .comment__meta > * + * {
    margin-left: 36px;
  }
}

.comment__date,
.comment__reply a {
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .comment__date,
  .comment__reply a {
    font-size: .875rem;
  }
}

.comment__reply a {
  color: #88df00;
  transition: color 300ms ease;
}

.comment__reply a:hover {
  color: #222430;
}

.children .comment {
  padding-left: 28px;
}

.comment + .comment,
.children .comment {
  margin-top: 50px;
}

@media (min-width: 1920px) {
  .comment + .comment,
  .children .comment {
    margin-top: 70px;
  }
}

.post-comments-form {
  margin-top: 90px;
}

@media (min-width: 1920px) {
  .post-comments-form {
    margin-top: 105px;
  }
}

.post-comments-form__title {
  margin-bottom: 68px;
}

@media (min-width: 1920px) {
  .post-comments-form__title {
    margin-bottom: 82px;
  }
}

.account-page {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}

.account-page .h4 + form {
  margin-top: 38px;
}

@media (min-width: 768px) {
  .account-page .h4 + form {
    margin-top: 68px;
  }
}

@media (min-width: 1920px) {
  .account-page .h4 + form {
    margin-top: 80px;
  }
}

.account-page .h4 + .account-orders {
  margin-top: 44px;
}

@media (min-width: 768px) {
  .account-page .h4 + .account-orders {
    margin-top: 55px;
  }
}

@media (min-width: 1920px) {
  .account-page .h4 + .account-orders {
    margin-top: 68px;
  }
}

@media (min-width: 992px) {
  .account-page:before {
    content: "";
    flex: 0 0 33.33333%;
    display: block;
    max-width: 620px;
    min-height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .account-page:before {
    min-height: calc(100vh - 100px);
  }
}

@media (min-width: 992px) {
  .account-content,
  .account-navigation {
    min-height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .account-content,
  .account-navigation {
    min-height: calc(100vh - 100px);
  }
}

.account-navigation {
  width: 100%;
  padding: 34px 24px;
  background-color: #f7f8fa;
}

@media (min-width: 576px) {
  .account-navigation {
    padding: 34px;
  }
}

@media (min-width: 992px) {
  .account-navigation {
    position: fixed;
    width: 33.33333%;
    max-width: 620px;
    min-height: calc(100vh - 72px);
    padding: 40px;
  }
}

@media (min-width: 1920px) {
  .account-navigation {
    min-height: calc(100vh - 100px);
    padding: 60px;
  }
}

.account-navigation__header {
  position: relative;
  font-weight: bold;
  letter-spacing: -.03em;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}

.account-navigation__logout {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
}

@media (min-width: 1920px) {
  .account-navigation__logout {
    width: 34px;
    height: 34px;
  }
}

.account-navigation__logout svg {
  width: 100%;
  height: 100%;
  fill: #222430;
  transition: transform 300ms ease;
}

.account-navigation__logout .df-icon--logout-arrow {
  position: absolute;
  top: 0;
  left: 0;
  fill: #88df00;
}

.account-navigation__logout:hover .df-icon--logout-arrow {
  transform: translateX(-3px);
}

.account-navigation__avatar {
  position: relative;
  display: inline-block;
  width: 60px;
  margin-bottom: 15px;
}

.account-navigation__avatar img {
  width: 100%;
}

@media (min-width: 1920px) {
  .account-navigation__avatar {
    margin-bottom: 22px;
    width: 80px;
  }
}

.account-navigation__browse-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: #88df00;
}

@media (min-width: 1920px) {
  .account-navigation__browse-icon {
    width: 20px;
    height: 20px;
  }
}

.account-navigation__browse-icon:after, .account-navigation__browse-icon:before {
  position: absolute;
  top: 6px;
  left: 4px;
  width: 6px;
  height: 2px;
  content: "";
  background-color: #fff;
}

@media (min-width: 1920px) {
  .account-navigation__browse-icon:after, .account-navigation__browse-icon:before {
    top: 8px;
    left: 5px;
    width: 10px;
    height: 3px;
  }
}

.account-navigation__browse-icon:after {
  transform: rotate(-90deg);
}

.account-navigation__subtitle {
  margin-bottom: 3px;
}

@media (min-width: 1920px) {
  .account-navigation__subtitle {
    margin-bottom: 2px;
  }
}

.account-navigation__name {
  margin-bottom: 27px;
}

@media (min-width: 1920px) {
  .account-navigation__name {
    margin-bottom: 33px;
    font-size: 2.125rem;
  }
}

.account-navigation__subtitle,
.account-navigation__meta span {
  font-size: .625rem;
  color: #ced0da;
}

@media (min-width: 1920px) {
  .account-navigation__subtitle,
  .account-navigation__meta span {
    font-size: .875rem;
  }
}

.account-navigation__meta {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 22px 44px;
  margin-bottom: 0;
  border-top: 1px solid #e7e9ed;
  border-bottom: 1px solid #e7e9ed;
}

@media (min-width: 1920px) {
  .account-navigation__meta {
    padding: 29px 60px;
    font-size: 1.125rem;
  }
}

.account-navigation__meta span {
  display: block;
  margin-top: 3px;
}

.account-navigation__menu {
  padding-left: 0;
  list-style: none;
  font-weight: bold;
  letter-spacing: -.06em;
  text-transform: uppercase;
  counter-reset: section;
}

.account-navigation__menu li {
  border-bottom: 1px solid #e7e9ed;
}

.account-navigation__menu a {
  display: block;
  padding-top: 21px;
  padding-bottom: 20px;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .account-navigation__menu a {
    padding-top: 30px;
    padding-bottom: 29px;
  }
}

.account-navigation__menu a:before {
  counter-increment: section;
  content: counter(section, decimal-leading-zero) ". ";
  transition: color 300ms ease;
}

.account-navigation__menu a:hover, .account-navigation__menu a.active {
  color: #222430;
}

.account-navigation__menu a:hover:before, .account-navigation__menu a.active:before {
  color: #88df00;
}

.account-content {
  width: 100%;
  padding: 50px 24px;
}

@media (min-width: 576px) {
  .account-content {
    padding: 50px calc(34px + 15px) 64px;
  }
}

@media (min-width: 768px) {
  .account-content {
    padding: 78px;
  }
}

@media (min-width: 992px) {
  .account-content {
    flex: 1 0 66.66666%;
  }
}

@media (min-width: 1920px) {
  .account-content {
    padding: 98px 118px;
  }
}

@media (min-width: 1920px) {
  .account-content .h4 {
    font-size: 2.875rem;
  }
}

.account-orders {
  padding-left: 0;
  list-style: none;
}

.account-order {
  margin-bottom: 40px;
}

.account-order:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .account-order:last-child {
    margin-bottom: 40px;
  }
}

.account-order__title, .account-order__header, .account-order__details {
  font-size: .75rem;
}

@media (min-width: 1920px) {
  .account-order__title, .account-order__header {
    font-size: 1.125rem;
  }
}

.account-order__header {
  padding-top: 27px;
  padding-bottom: 25px;
  line-height: 1;
  border-top: 1px solid #dfe0e5;
  border-bottom: 1px solid #dfe0e5;
}

@media (max-width: 575px) {
  .account-order__header > .row > div:nth-child(n+2) {
    margin-top: 20px;
  }
}

@media (min-width: 1920px) {
  .account-order__header {
    padding-top: 37px;
    padding-bottom: 35px;
  }
}

.account-order__title {
  margin-bottom: 24px;
}

@media (min-width: 1920px) {
  .account-order__title {
    margin-bottom: 29px;
  }
}

.account-order__title ~ div + div {
  margin-top: 17px;
}

@media (min-width: 1920px) {
  .account-order__title ~ div + div {
    margin-top: 26px;
  }
}

.account-order__title, .account-order__header {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -.03em;
}

.account-order__details {
  padding-top: 39px;
  padding-bottom: 34px;
  line-height: 1.5;
  border-bottom: 1px solid #dfe0e5;
}

@media (min-width: 1920px) {
  .account-order__details {
    padding-top: 58px;
    padding-bottom: 50px;
    font-size: 1rem;
    line-height: 1.625;
  }
}

.account-order__details a {
  color: #222430;
}

.account-order__details a:hover {
  color: #a3ff12;
}

@media (max-width: 767px) {
  .account-order__details > .row > div:nth-child(n+3) {
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .account-order__details > .row > div:nth-child(n+2) {
    margin-top: 30px;
  }
}

.account-order__label {
  display: block;
  margin-bottom: 3px;
  font-size: .625rem;
  color: #ced0da;
}

@media (min-width: 1920px) {
  .account-order__label {
    font-size: .875rem;
  }
}

.checkout-page {
  background-color: #fff;
}

@media (min-width: 992px) {
  .checkout-page > .row > * {
    min-height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .checkout-page > .row > * {
    min-height: calc(100vh - 100px);
  }
}

.checkout-page > .row > *:nth-child(odd) {
  padding: 50px 34px;
}

@media (min-width: 768px) {
  .checkout-page > .row > *:nth-child(odd) {
    padding: 80px;
  }
}

.checkout-page > .row > *:nth-child(even) {
  padding: 48px 34px 94px;
  background-color: #f7f8fa;
}

@media (min-width: 992px) {
  .checkout-page > .row > *:nth-child(even) {
    padding: 38px 40px;
  }
}

@media (min-width: 1920px) {
  .checkout-page > .row > *:nth-child(even) {
    padding: 58px 60px;
  }
}

.checkout-page .h4 + form {
  margin-top: 38px;
}

@media (min-width: 768px) {
  .checkout-page .h4 + form {
    margin-top: 68px;
  }
}

.checkout-page * + .h4 {
  margin-top: 33px;
}

@media (min-width: 768px) {
  .checkout-page * + .h4 {
    margin-top: 63px;
  }
}

.checkout-login,
.checkout-redeem,
.checkout-redeem-popup__header {
  display: block;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.checkout-login__tiny,
.checkout-redeem__tiny,
.checkout-redeem-popup__tiny {
  display: inline-block;
  padding: 4px 6px 3px;
  font-size: .625rem;
  line-height: 1;
  color: #222430;
}

.checkout-login,
.checkout-redeem {
  margin-bottom: 30px;
  padding: 36px 18px 40px;
}

@media (min-width: 768px) {
  .checkout-login,
  .checkout-redeem {
    margin-bottom: 0;
  }
}

.checkout-login:hover,
.checkout-redeem:hover {
  color: #fff;
}

.checkout-login__title,
.checkout-redeem__title {
  margin-bottom: 2px;
  font-size: 2.75rem;
  line-height: 1;
}

.checkout-login__subtitle,
.checkout-redeem__subtitle {
  line-height: 1;
}

.checkout-login__tiny,
.checkout-redeem__tiny {
  transition: transform 400ms ease;
}

.checkout-login:hover .checkout-login__tiny,
.checkout-login:hover .checkout-redeem__tiny,
.checkout-redeem:hover .checkout-login__tiny,
.checkout-redeem:hover .checkout-redeem__tiny {
  transform: translateY(4px);
}

.checkout-login__title span {
  color: #a3ff12;
}

.checkout-login__tiny {
  background-color: #a3ff12;
}

@media (min-width: 992px) {
  .checkout-login {
    margin-right: 8px;
  }
}

.checkout-redeem__title span,
.checkout-redeem-popup__title span {
  color: #fff60d;
}

.checkout-redeem__tiny,
.checkout-redeem-popup__tiny {
  background-color: #fff60d;
}

@media (min-width: 992px) {
  .checkout-redeem {
    margin-left: 8px;
  }
}

.checkout-redeem-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 290px;
  transform: translate(-50%, -40%);
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0;
  z-index: 1107;
}

@media (min-width: 576px) {
  .checkout-redeem-popup {
    width: 370px;
  }
}

.checkout-redeem-popup__header {
  padding: 38px 30px;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.checkout-redeem-popup__title {
  margin-bottom: 5px;
  color: #fff;
}

.checkout-redeem-popup__body {
  padding: 32px;
}

.site-wrapper--has-redeem-overlay .checkout-redeem-popup {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity ease 320ms, transform ease 800ms;
}

.checkout-redeem-popup .form-group {
  margin-bottom: 26px;
}

.checkout-payment-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 70px;
}

.checkout-payment-method {
  padding-bottom: 24px;
  border-bottom: 1px solid #dfe0e5;
}

.checkout-payment-method button {
  padding: 0;
  outline: 0;
  border: none;
  background-color: transparent;
}

.checkout-payment-method .checkbox {
  margin-bottom: 4px;
}

.checkout-payment-method__body {
  margin-top: 16px;
}

.checkout-payment-method + .checkout-payment-method {
  margin-top: 28px;
}

.checkout-order__title {
  margin-bottom: 35px;
  font-size: 1.5rem;
}

@media (min-width: 1920px) {
  .checkout-order__title {
    margin-bottom: 54px;
    font-size: 2.125rem;
  }
}

.checkout-order .product__info {
  width: 85%;
  padding: 18px 15px 18px 0;
}

@media (min-width: 1920px) {
  .checkout-order .product__info {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

.checkout-order .product__info-content {
  overflow: hidden;
  padding-left: 15px;
}

@media (min-width: 1920px) {
  .checkout-order .product__info-content {
    padding-left: 22px;
  }
}

.checkout-order .product__thumb {
  float: left;
  width: 44px;
  margin-bottom: 0;
}

.checkout-order .product__thumb img {
  width: 100%;
}

@media (min-width: 1920px) {
  .checkout-order .product__thumb {
    width: 60px;
  }
}

.checkout-order .product__name {
  margin-top: 2px;
  margin-bottom: 1px;
  font-size: .875rem;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .checkout-order .product__name {
    font-size: 1rem;
  }
}

.checkout-order .product__name a {
  color: #222430;
}

.checkout-order .product__name a:hover {
  color: #a3ff12;
}

.checkout-order .product__cat, .checkout-order .product__info-details {
  display: block;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

@media (min-width: 1920px) {
  .checkout-order .product__cat, .checkout-order .product__info-details {
    font-size: .875rem;
  }
}

.checkout-order .product__cat, .checkout-order .product__info-details {
  color: #5e627e;
}

@media (min-width: 1920px) {
  .checkout-order .product__cat {
    margin-top: 2px;
  }
}

.checkout-order .product__info-details {
  margin-top: 1px;
}

@media (min-width: 1920px) {
  .checkout-order .product__info-details {
    margin-top: 3px;
  }
}

.checkout-order .product__total:not(th) {
  font-size: .875rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
}

.checkout-order .product__total {
  padding: 20px 0 0;
  text-align: right;
}

@media (min-width: 1920px) {
  .checkout-order .product__total {
    padding-top: 29px;
  }
}

.checkout-order table td {
  border-color: #e7e9ed;
}

.checkout-order tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}

.checkout-order .shop-table {
  margin-bottom: 26px;
}

@media (min-width: 1920px) {
  .checkout-order .shop-table {
    margin-bottom: 33px;
  }
}

.checkout-order__totals {
  text-align: right;
}

.checkout-order__totals .table {
  margin-bottom: 32px;
}

.checkout-order__totals th, .checkout-order__totals td {
  padding: 0;
  border: none;
}

.checkout-order__totals tr:last-child td {
  border: none;
}

.checkout-order__taxes, .checkout-order__promo, .checkout-order__subtotal, .checkout-order__shipping, .checkout-order__order-total {
  font-weight: 700;
  text-transform: uppercase;
}

.checkout-order__taxes, .checkout-order__promo, .checkout-order__subtotal, .checkout-order__shipping {
  font-size: .625rem;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .checkout-order__taxes, .checkout-order__promo, .checkout-order__subtotal, .checkout-order__shipping {
    font-size: .875rem;
  }
}

.checkout-order__taxes th, .checkout-order__taxes td, .checkout-order__promo th, .checkout-order__promo td, .checkout-order__shipping th, .checkout-order__shipping td {
  padding-top: 8px;
}

@media (min-width: 1920px) {
  .checkout-order__taxes th, .checkout-order__taxes td, .checkout-order__promo th, .checkout-order__promo td, .checkout-order__shipping th, .checkout-order__shipping td {
    padding-top: 11px;
  }
}

.checkout-order__promo .amount {
  color: #88df00;
}

.checkout-order__order-total {
  font-size: .875rem;
}

@media (min-width: 1920px) {
  .checkout-order__order-total {
    font-size: 1.125rem;
  }
}

.checkout-order__order-total th, .checkout-order__order-total td {
  padding-top: 5px;
}

@media (min-width: 1920px) {
  .checkout-order__order-total th, .checkout-order__order-total td {
    padding-top: 10px;
  }
}

.checkout-order__button {
  margin-top: 42px;
  letter-spacing: -.03em;
  color: #fff;
  background-color: #222430;
}

@media (min-width: 768px) {
  .checkout-order__button {
    margin-top: 72px;
  }
}

@media (min-width: 1920px) {
  .checkout-order__button {
    margin-top: 70px;
  }
}

.checkout-order__button:hover {
  background-color: #373a4e;
}

@media (min-width: 992px) {
  .content.shop-layout--style-1 {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 50% 50%;
    grid-auto-columns: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .content.shop-layout--style-1 {
    grid-auto-columns: calc(100vh - 100px);
  }
}

@media (min-width: 576px) {
  .content.shop-layout--style-2 {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .content.shop-layout--style-2 {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 100%;
  }
}

.product {
  background-color: #fff;
}

.product--single {
  margin-bottom: 98px;
}

.shop-layout--style-1 .product:hover .product__title a,
.shop-layout--style-2 .product:hover .product__title a {
  color: #5e627e;
}

.shop-layout--style-1 .product {
  overflow: hidden;
}

@media (min-width: 768px) {
  .shop-layout--style-1 .product {
    display: flex;
  }
}

@media (min-width: 768px) {
  .shop-layout--style-1 .product:nth-child(odd) .product__thumbnail {
    order: 2;
  }
}

.shop-layout--style-1 .product:hover .product__thumbnail > a:before {
  opacity: 1;
}

.shop-layout--style-1 .product:hover .product__thumbnail > a img {
  transform: scale(1.025);
}

@media (min-width: 576px) {
  .shop-layout--style-2 .product {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product {
    width: 45vh;
  }
}

.shop-layout--style-1 .product__body,
.shop-layout--style-2 .product__body {
  padding: 32px 40px;
  overflow: hidden;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__body,
  .shop-layout--style-2 .product__body {
    padding-top: 4.16667vh;
    padding-right: 5.20833vh;
    padding-bottom: 4.16667vh;
    padding-left: 5.20833vh;
  }
}

.shop-layout--style-1 .product__body {
  position: relative;
}

@media (min-width: 768px) {
  .shop-layout--style-1 .product__body {
    width: 50%;
  }
}

.shop-layout--style-1 .product__thumbnail,
.shop-layout--style-2 .product__thumbnail {
  background-color: #f2f3f7;
}

.shop-layout--style-1 .product__thumbnail img,
.shop-layout--style-2 .product__thumbnail img {
  width: 100%;
}

.shop-layout--style-1 .product__thumbnail {
  height: 100%;
}

@media (min-width: 768px) {
  .shop-layout--style-1 .product__thumbnail {
    width: 50%;
  }
}

.shop-layout--style-2 .product__thumbnail {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vw;
  overflow: hidden;
}

@media (min-width: 576px) {
  .shop-layout--style-2 .product__thumbnail {
    height: 50vw;
  }
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product__thumbnail {
    height: 68vh;
  }
}

.product__thumbnail > a {
  position: relative;
  display: block;
  overflow: hidden;
}

.product__thumbnail > a img {
  transition: .4s transform ease;
}

.product__thumbnail > a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: .4s opacity ease;
  z-index: 1;
}

.product--single .product__thumbnail {
  margin: -60px -24px 0;
}

.product--single .product__thumbnail img {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 992px) {
  .product--single .product__thumbnail {
    position: fixed;
    left: 0;
    width: calc(100% - 606px);
    height: calc(100vh - 72px);
    margin: 0;
    overflow: hidden;
  }
  .site-header--top ~ .site-content .product--single .product__thumbnail {
    bottom: 0;
  }
  .site-header--bottom ~ .site-content .product--single .product__thumbnail {
    top: 0;
  }
  .product--single .product__thumbnail-item {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 606px);
    height: calc(100vh - 72px);
  }
}

@media (min-width: 1440px) {
  .product--single .product__thumbnail {
    width: 38.5415%;
  }
  .product--single .product__thumbnail-item {
    width: 38.5415%;
  }
}

@media (min-width: 1920px) {
  .product--single .product__thumbnail {
    height: calc(100vh - 100px);
  }
}

.product__cats {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .product__cats {
    font-size: 1.82292vh;
  }
}

.product__cats a {
  font-weight: bold;
  letter-spacing: -.06em;
  text-transform: uppercase;
  color: #ced0da;
}

.shop-layout--style-1 .product__cats a,
.shop-layout--style-2 .product__cats a {
  line-height: 2.125;
}

.shop-layout--style-1 .product__cats a {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__cats a {
    font-size: 2.08333vh;
  }
}

.shop-layout--style-2 .product__cats a {
  font-size: .75rem;
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product__cats a {
    font-size: 1.5625vh;
  }
}

.product--single .product__cats a {
  font-size: 1.125rem;
  line-height: 2.25;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__title,
  .shop-layout--style-2 .product__title {
    font-size: 4.16667vh;
  }
}

.shop-layout--style-1 .product__title {
  margin-bottom: 13px;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__title {
    margin-bottom: 1.69271vh;
  }
}

.shop-layout--style-2 .product__title {
  margin-bottom: 9px;
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product__title {
    margin-bottom: 1.17188vh;
    font-size: 3.125vh;
  }
}

.product__title a {
  color: #222430;
}

.product__meta {
  display: flex;
}

.shop-layout--style-1 .product__meta,
.shop-layout--style-2 .product__meta {
  margin-bottom: 22px;
  align-items: center;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__meta,
  .shop-layout--style-2 .product__meta {
    margin-bottom: 2.86458vh;
  }
}

.product--single .product__meta {
  margin-bottom: 43px;
  align-items: baseline;
}

.product__price {
  margin-right: 10px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #222430;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__price,
  .shop-layout--style-2 .product__price {
    margin-right: 1.30208vh;
  }
}

.shop-layout--style-1 .product__price {
  font-size: 1.375rem;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__price {
    font-size: 2.86458vh;
  }
}

.shop-layout--style-2 .product__price {
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product__price {
    font-size: 2.34375vh;
  }
}

.product--single .product__price {
  font-size: 1.875rem;
}

.product .star-rating {
  position: relative;
  line-height: 1;
  letter-spacing: 4px;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product .star-rating,
  .shop-layout--style-2 .product .star-rating {
    letter-spacing: 0.52083vh;
  }
}

.product .star-rating:before {
  content: '\f005\f005\f005\f005\f005';
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  color: #ced0da;
}

.product .star-rating span:first-child {
  overflow: hidden;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}

.product .star-rating span:first-child:before {
  content: '\f005\f005\f005\f005\f005';
  position: absolute;
  top: 0;
  left: 0;
  color: #ffcc00;
}

.shop-layout--style-1 .product .star-rating,
.shop-layout--style-2 .product .star-rating {
  overflow: hidden;
}

.shop-layout--style-1 .product .star-rating {
  width: 72px;
  height: 10px;
  font-size: 10px;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product .star-rating {
    width: 9.375vh;
    height: 1.30208vh;
    font-size: 1.30208vh;
  }
}

.shop-layout--style-1 .product .star-rating span {
  height: 10px;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product .star-rating span {
    height: 1.30208vh;
  }
}

.shop-layout--style-2 .product .star-rating {
  width: 62px;
  height: 9px;
  font-size: 8px;
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product .star-rating {
    width: 8.07292vh;
    height: 1.17188vh;
    font-size: 1.04167vh;
  }
}

.shop-layout--style-2 .product .star-rating span {
  height: 9px;
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product .star-rating span {
    height: 1.17188vh;
  }
}

.product--single .star-rating {
  width: 72px;
  font-size: 10px;
  padding-top: 11px;
}

.product--single .star-rating span:first-child {
  height: 10px;
}

.product--single .star-rating__label {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: .625rem;
  letter-spacing: -.03em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #ced0da;
}

.product--single .product__rating {
  margin-top: 4px;
  line-height: 1;
}

.shop-layout--style-1 .product__excerpt,
.shop-layout--style-2 .product__excerpt {
  font-size: .75rem;
  line-height: 1.667;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__excerpt,
  .shop-layout--style-2 .product__excerpt {
    font-size: 1.5625vh;
  }
}

.shop-layout--style-1 .product__excerpt + .btn,
.shop-layout--style-2 .product__excerpt + .btn {
  margin-top: 33px;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product__excerpt + .btn,
  .shop-layout--style-2 .product__excerpt + .btn {
    margin-top: 4.29688vh;
  }
}

.product--single .product__excerpt {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product .btn,
  .shop-layout--style-2 .product .btn {
    padding-top: 1.82292vh;
    padding-right: 3.90625vh;
    padding-bottom: 1.82292vh;
    padding-left: 3.90625vh;
    font-size: 1.5625vh;
  }
}

.shop-layout--style-1 .product .btn {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

@media (min-width: 992px) {
  .shop-layout--style-1 .product .btn {
    padding-left: 4.55729vh;
    padding-right: 4.55729vh;
  }
}

.shop-layout--style-2 .product .btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  background-color: #222430;
  transition: all 500ms ease;
}

@media (min-width: 992px) {
  .shop-layout--style-2 .product .btn {
    transform: translateY(102%);
  }
}

.shop-layout--style-2 .product .btn:hover {
  background-color: #373a4e;
}

.shop-layout--style-2 .product:hover .btn {
  transform: translateY(0);
}

.product__options {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  margin-bottom: 74px;
}

.product__options .color-control {
  padding-left: 0;
  list-style: none;
}

.product__options .color-control li {
  float: left;
}

.product__options .color-control li + li {
  margin-left: 10px;
}

.product__options .color-control a {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
}

.product__options .color-control a.active:before {
  content: "";
  position: absolute;
  top: 100%;
  margin-top: 2px;
  height: 2px;
  width: 100%;
  background: inherit;
}

.product__options .color-control:after {
  content: "";
  display: table;
  clear: left;
}

.product__options .quantity-control {
  margin-top: -4px;
}

.product__options .quantity-control input,
.product__options .quantity-control button {
  vertical-align: middle;
  border: none;
  background-color: transparent;
}

.product__options .quantity-control input:focus,
.product__options .quantity-control button:focus {
  outline: none;
}

.product__options .quantity-control button {
  position: relative;
  display: inline-block;
  padding: 0;
  width: 6px;
  height: 6px;
  color: #88df00;
}

body:not(.cursor-is--active) .product__options .quantity-control button {
  cursor: pointer;
}

.product__options .quantity-control button:after, .product__options .quantity-control button:before {
  position: absolute;
  content: "";
  background-color: #88df00;
  transition: opacity 300ms ease;
}

.product__options .quantity-control__minus:before {
  top: 2px;
  left: 0;
  width: 6px;
  height: 2px;
}

.product__options .quantity-control__plus:before {
  top: 2px;
  left: 0;
  width: 6px;
  height: 2px;
}

.product__options .quantity-control__plus:after {
  top: 0;
  left: 2px;
  width: 2px;
  height: 6px;
}

.product__options .quantity-control input {
  display: inline-block;
  width: 36px;
  height: 18px;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
  text-align: center;
}

.product__options .quantity-control input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.product__options .size-control {
  position: relative;
  margin-top: 0px;
}

.product__options .size-control select {
  display: block;
  width: auto;
  padding-right: 20px;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: 0;
  background-color: transparent;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
}

body:not(.cursor-is--active) .product__options .size-control select {
  cursor: pointer;
}

.product__options .size-control:after, .product__options .size-control:before {
  content: "";
  position: absolute;
  background-color: #88df00;
  transition: opacity 300ms ease;
}

.product__options .size-control:before {
  top: 7px;
  right: 0;
  width: 6px;
  height: 2px;
}

.product__options .size-control:after {
  top: 5px;
  right: 2px;
  width: 2px;
  height: 6px;
}

.product__option-label {
  display: block;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #ced0da;
}

.product__option-label + * {
  margin-top: -2px;
}

.product__button {
  color: #fff;
  background-color: #222430;
}

.product__button:hover {
  color: #fff;
  background-color: #373a4e;
}

.product--single .slick-slider .slick-arrow {
  position: absolute;
  top: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 0;
  outline: 0;
  opacity: .4;
  z-index: 1;
  transition: opacity 300ms ease;
}

body:not(.cursor-is--active) .product--single .slick-slider .slick-arrow {
  cursor: pointer;
}

@media (min-width: 576px) {
  .product--single .slick-slider .slick-arrow {
    top: 40px;
  }
}

.product--single .slick-slider .slick-arrow:hover {
  opacity: 1;
}

.product--single .slick-slider .slick-arrow svg {
  width: 8px;
  height: 14px;
  fill: #222430;
}

.product--single .slick-slider .slick-prev {
  right: 66px;
}

@media (min-width: 576px) {
  .product--single .slick-slider .slick-prev {
    right: 98px;
  }
}

.product--single .slick-slider .slick-next {
  right: 24px;
}

@media (min-width: 576px) {
  .product--single .slick-slider .slick-next {
    right: 56px;
  }
}

.product-review-form__title {
  margin-bottom: 68px;
}

.product-reviews__title {
  margin-bottom: 68px;
}

.product-review-form + .product-reviews {
  margin-top: 98px;
}

.reviews {
  padding-left: 0;
  list-style: none;
}

.review + .review {
  margin-top: 47px;
}

.review__header {
  margin-bottom: 8px;
}

.review__body {
  margin-bottom: 13px;
}

.review__meta, .review__title {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -.03em;
}

.review__title {
  margin-bottom: -1px;
}

.review .star-rating {
  position: relative;
  display: inline-block;
  width: 72px;
  height: 9px;
  font-size: 9px;
  letter-spacing: 4px;
  overflow: hidden;
}

.review .star-rating:before {
  content: '\f005\f005\f005\f005\f005';
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  color: #ced0da;
}

.review .star-rating span:first-child {
  overflow: hidden;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  height: 9px;
}

.review .star-rating span:first-child:before {
  content: '\f005\f005\f005\f005\f005';
  position: absolute;
  top: 0;
  left: 0;
  color: #ffcc00;
}

.review__meta {
  padding-left: 0;
  list-style: none;
  font-size: .625rem;
}

.review__meta li {
  display: inline-block;
}

.review__meta li + li {
  margin-left: 24px;
}

.review__date {
  color: #ced0da;
}

.match-overview__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
}

@media (min-width: 1920px) {
  .match-overview__body {
    max-width: 1200px;
  }
}

@media (max-width: 767.98px) {
  .match-overview__body > * {
    max-width: 33.33333%;
  }
}

.match-overview .match-overview__table {
  width: 100%;
  max-width: 230px;
  margin: 10px auto 0;
  font-size: .625rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 767.98px) {
  .match-overview .match-overview__table {
    display: none;
  }
}

@media (min-width: 992px) {
  .match-overview .match-overview__table {
    margin-top: 31px;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-overview__table {
    max-width: 280px;
    margin-top: 38px;
    font-size: .875rem;
  }
}

.match-overview .match-overview__table thead {
  color: #5e627e;
}

.match-overview .match-overview__table tbody {
  color: #fff;
  background-color: #151720;
}

.match-overview .match-overview__table tr + tr {
  border-top: 1px solid rgba(94, 98, 126, 0.2);
}

.match-overview .match-overview__table td + td {
  border-left: 1px solid rgba(94, 98, 126, 0.2);
}

.match-overview .match-overview__table td:first-child,
.match-overview .match-overview__table th:first-child {
  padding-left: 14px;
  padding-right: 14px;
  text-align: left;
}

.match-overview .match-overview__table td {
  min-width: 30px;
  padding: 8px 9px 7px;
}

@media (min-width: 1920px) {
  .match-overview .match-overview__table td {
    min-width: 40px;
    padding: 12px 9px 11px;
  }
}

.match-overview .match-overview__table th {
  padding-bottom: 2px;
}

@media (min-width: 1920px) {
  .match-overview .match-overview__table th {
    padding-bottom: 5px;
  }
}

.match-overview__footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: -30px;
}

@media (min-width: 768px) {
  .match-overview__footer {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .match-overview__footer {
    margin-top: 105px;
  }
}

@media (min-width: 1920px) {
  .match-overview__footer {
    margin-top: 93px;
  }
}

.match-overview__footer li {
  width: 100%;
  margin-bottom: 30px;
  font-size: .9375rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -.03em;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

@media (min-width: 992px) {
  .match-overview__footer li {
    font-size: 1.125rem;
  }
}

@media (min-width: 576px) {
  .match-overview__footer li {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .match-overview__footer li {
    width: 20%;
  }
}

@media (min-width: 1920px) {
  .match-overview__footer li {
    font-size: 1.75rem;
  }
}

.match-overview__footer li span {
  display: block;
  margin-bottom: 3px;
  font-size: .75rem;
  color: #5e627e;
}

@media (min-width: 992px) {
  .match-overview__footer li span {
    margin-bottom: 5px;
    font-size: .875rem;
  }
}

@media (min-width: 1920px) {
  .match-overview__footer li span {
    font-size: 1.25rem;
  }
}

.match-overview__footer li:nth-child(1) {
  width: 100%;
}

@media (min-width: 768px) {
  .match-overview__footer li:nth-child(1) {
    width: 20%;
  }
}

.match-replay {
  width: 100%;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 40px 60px 0px rgba(0, 2, 0, 0.2);
  height: calc(62.5vw - 30px);
}

@media (min-width: 576px) {
  .match-replay {
    height: 286px;
  }
}

@media (min-width: 768px) {
  .match-replay {
    height: 326px;
  }
}

@media (min-width: 992px) {
  .matches-tabs .match-replay {
    margin-bottom: -14px;
  }
}

.match-replay a {
  position: relative;
  width: 100%;
  height: 100%;
}

.match-replay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.match-result {
  font-weight: bold;
  letter-spacing: -.03em;
  text-align: center;
  text-transform: uppercase;
}

.match-score .match-result {
  flex: 2 0 auto;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.match-score-inline .match-result {
  margin-top: 4px;
  font-size: .75rem;
}

.match-score-inline .match-result:nth-child(2) {
  margin-right: 14px;
}

.match-score-inline .match-result:last-child {
  min-width: 30px;
}

.match-stats-widget .match-result {
  padding-bottom: 2px;
}

.match-result__score {
  display: block;
  line-height: 1;
  color: #fff;
}

.match-score .match-result__score {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .match-score .match-result__score {
    font-size: 1.125rem;
  }
}

@media (min-width: 1920px) {
  .match-score .match-result__score {
    font-size: 1.75rem;
  }
}

.match-overview .match-result__score {
  font-size: 2rem;
}

@media (min-width: 576px) {
  .match-overview .match-result__score {
    font-size: 3rem;
  }
}

@media (min-width: 768px) {
  .match-overview .match-result__score {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .match-overview .match-result__score {
    font-size: 4.5rem;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-result__score {
    font-size: 5.875rem;
  }
}

.match-stats-widget .match-result__score {
  font-size: .875rem;
  margin-bottom: 11px;
}

.match-result--detailed .match-result__score {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.match-result--winner-left .match-result__score,
.match-result--winner-right .match-result__score {
  position: relative;
  display: inline-block;
}

.match-result--winner-left .match-result__score:before,
.match-result--winner-right .match-result__score:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.match-score .match-result--winner-left .match-result__score:before,
.match-score-inline .match-result--winner-left .match-result__score:before,
.match-stats-widget .match-result--winner-left .match-result__score:before, .match-score
.match-result--winner-right .match-result__score:before,
.match-score-inline
.match-result--winner-right .match-result__score:before,
.match-stats-widget
.match-result--winner-right .match-result__score:before {
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

@media (min-width: 1920px) {
  .match-score .match-result--winner-left .match-result__score:before,
  .match-score-inline .match-result--winner-left .match-result__score:before,
  .match-stats-widget .match-result--winner-left .match-result__score:before, .match-score
  .match-result--winner-right .match-result__score:before,
  .match-score-inline
  .match-result--winner-right .match-result__score:before,
  .match-stats-widget
  .match-result--winner-right .match-result__score:before {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }
}

.match-score .match-result--winner-left .match-result__score:before, .match-score
.match-result--winner-right .match-result__score:before {
  top: 4px;
}

@media (min-width: 992px) {
  .match-score .match-result--winner-left .match-result__score:before, .match-score
  .match-result--winner-right .match-result__score:before {
    top: 5px;
  }
}

@media (min-width: 1920px) {
  .match-score .match-result--winner-left .match-result__score:before, .match-score
  .match-result--winner-right .match-result__score:before {
    top: 9px;
  }
}

.match-score-inline .match-result--winner-left .match-result__score:before, .match-score-inline
.match-result--winner-right .match-result__score:before {
  top: 2px;
}

.match-stats-widget .match-result--winner-left .match-result__score:before, .match-stats-widget
.match-result--winner-right .match-result__score:before {
  top: 3px;
}

.match-overview .match-result--winner-left .match-result__score:before, .match-overview
.match-result--winner-right .match-result__score:before {
  top: 10px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

@media (min-width: 576px) {
  .match-overview .match-result--winner-left .match-result__score:before, .match-overview
  .match-result--winner-right .match-result__score:before {
    top: 16px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
}

@media (min-width: 768px) {
  .match-overview .match-result--winner-left .match-result__score:before, .match-overview
  .match-result--winner-right .match-result__score:before {
    top: 19px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
}

@media (min-width: 992px) {
  .match-overview .match-result--winner-left .match-result__score:before, .match-overview
  .match-result--winner-right .match-result__score:before {
    top: 23px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-result--winner-left .match-result__score:before, .match-overview
  .match-result--winner-right .match-result__score:before {
    top: 30px;
    border-top-width: 13px;
    border-bottom-width: 13px;
  }
}

.match-score .match-result--winner-left .match-result__score:before,
.match-score-inline .match-result--winner-left .match-result__score:before,
.match-stats-widget .match-result--winner-left .match-result__score:before {
  border-left: 4px solid #a3ff12;
}

@media (min-width: 1920px) {
  .match-score .match-result--winner-left .match-result__score:before,
  .match-score-inline .match-result--winner-left .match-result__score:before,
  .match-stats-widget .match-result--winner-left .match-result__score:before {
    border-left-width: 6px;
  }
}

.match-score .match-result--winner-left .match-result__score:before,
.match-score-inline .match-result--winner-left .match-result__score:before {
  left: -13px;
}

@media (min-width: 1920px) {
  .match-score .match-result--winner-left .match-result__score:before,
  .match-score-inline .match-result--winner-left .match-result__score:before {
    left: -18px;
  }
}

.match-stats-widget .match-result--winner-left .match-result__score:before {
  left: -12px;
}

.match-overview .match-result--winner-left .match-result__score:before {
  left: -16px;
  border-left: 6px solid #a3ff12;
}

@media (min-width: 576px) {
  .match-overview .match-result--winner-left .match-result__score:before {
    left: -20px;
    border-left: 8px solid #a3ff12;
  }
}

@media (min-width: 768px) {
  .match-overview .match-result--winner-left .match-result__score:before {
    left: -22px;
    border-left: 10px solid #a3ff12;
  }
}

@media (min-width: 992px) {
  .match-overview .match-result--winner-left .match-result__score:before {
    left: -35px;
    border-left: 14px solid #a3ff12;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-result--winner-left .match-result__score:before {
    left: -54px;
    border-left-width: 18px;
  }
}

.match-score .match-result--winner-right .match-result__score:before,
.match-score-inline .match-result--winner-right .match-result__score:before,
.match-stats-widget .match-result--winner-right .match-result__score:before {
  border-right: 4px solid #a3ff12;
}

@media (min-width: 1920px) {
  .match-score .match-result--winner-right .match-result__score:before,
  .match-score-inline .match-result--winner-right .match-result__score:before,
  .match-stats-widget .match-result--winner-right .match-result__score:before {
    border-right-width: 6px;
  }
}

.match-score .match-result--winner-right .match-result__score:before,
.match-score-inline .match-result--winner-right .match-result__score:before {
  right: -13px;
}

@media (min-width: 1920px) {
  .match-score .match-result--winner-right .match-result__score:before,
  .match-score-inline .match-result--winner-right .match-result__score:before {
    right: -18px;
  }
}

.match-stats-widget .match-result--winner-right .match-result__score:before {
  right: -12px;
}

.match-overview .match-result--winner-right .match-result__score:before {
  right: -16px;
  border-right: 6px solid #a3ff12;
}

@media (min-width: 576px) {
  .match-overview .match-result--winner-right .match-result__score:before {
    right: -20px;
    border-right: 8px solid #a3ff12;
  }
}

@media (min-width: 768px) {
  .match-overview .match-result--winner-right .match-result__score:before {
    right: -22px;
    border-right: 10px solid #a3ff12;
  }
}

@media (min-width: 992px) {
  .match-overview .match-result--winner-right .match-result__score:before {
    right: -35px;
    border-right: 14px solid #a3ff12;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-result--winner-right .match-result__score:before {
    right: -54px;
    border-right-width: 18px;
  }
}

.match-result__score--sm {
  font-size: .625rem;
}

.match-result__score--sm .team-cyan {
  color: #0fadff;
}

.match-result__score--sm .team-yellow {
  color: #ffe373;
}

.match-result__label {
  display: inline-block;
  color: #5e627e;
}

.match-score .match-result__label {
  margin-top: 6px;
  font-size: .625rem;
}

@media (min-width: 576px) {
  .match-score .match-result__label {
    font-size: .75rem;
  }
}

@media (min-width: 1920px) {
  .match-score .match-result__label {
    margin-top: 5px;
    font-size: 1.25rem;
  }
}

.match-score-inline__body-inner:first-child .match-result__label {
  color: #fff;
}

.match-overview .match-result__label {
  font-size: .6875rem;
}

@media (max-width: 767.98px) {
  .match-overview .match-result__label {
    margin-top: 4px;
    line-height: 1;
  }
}

@media (min-width: 576px) {
  .match-overview .match-result__label {
    margin-top: 6px;
    font-size: .75rem;
  }
}

@media (min-width: 768px) {
  .match-overview .match-result__label {
    font-size: .875rem;
  }
}

@media (min-width: 992px) {
  .match-overview .match-result__label {
    margin-top: 10px;
    font-size: 1rem;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-result__label {
    margin-top: 16px;
    font-size: 1.5rem;
  }
}

.match-result--live .match-result__label {
  position: relative;
  width: auto;
  color: #fff;
}

.match-result--live .match-result__label:before {
  content: "";
  position: absolute;
  top: 3px;
  right: calc(100% + 6px);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ff1c5c;
}

@media (min-width: 576px) {
  .match-result--live .match-result__label:before {
    width: 4px;
    height: 4px;
  }
}

@media (min-width: 1920px) {
  .match-result--live .match-result__label:before {
    top: 7px;
    width: 6px;
    height: 6px;
  }
  .top-bar-carousel .match-result--live .match-result__label:before {
    top: 2px;
  }
}

@media (min-width: 576px) {
  .match-overview .match-result--live .match-result__label:before {
    width: 4px;
    height: 4px;
  }
}

@media (min-width: 768px) {
  .match-overview .match-result--live .match-result__label:before {
    top: 9px;
    right: calc(100% + 10px);
    width: 5px;
    height: 5px;
  }
}

@media (min-width: 992px) {
  .match-overview .match-result--live .match-result__label:before {
    top: 10px;
    width: 6px;
    height: 6px;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-result--live .match-result__label:before {
    top: 16px;
    right: calc(100% + 16px);
  }
}

.match-result--winner-left .match-result__label,
.match-result--winner-right .match-result__label {
  display: block;
}

.match-score {
  position: relative;
  margin-bottom: 30px;
  font-weight: bold;
  letter-spacing: -.03em;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  background-color: #13151e;
}

@media (max-width: 374px) {
  .match-score {
    margin-bottom: 15px;
  }
}

.team-carousel-tabs .match-score {
  width: 100%;
}

.team-carousel-tabs .match-score:last-child {
  margin-bottom: 0;
}

.match-score__body, .match-score__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
}

@media (min-width: 576px) {
  .match-score__body, .match-score__header {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1920px) {
  .match-score__body, .match-score__header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.top-bar-carousel .match-score__body, .top-bar-carousel .match-score__header {
  flex-direction: column;
}

.top-bar-carousel .match-score__body-inner, .top-bar-carousel .match-score__header-inner {
  display: flex;
}

.match-score__header {
  font-size: .625rem;
}

@media (min-width: 576px) {
  .match-score__header {
    font-size: .75rem;
  }
}

@media (min-width: 1920px) {
  .match-score__header {
    font-size: 1.25rem;
  }
}

.match-score__header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #151720;
  border-bottom: 1px solid rgba(94, 98, 126, 0.2);
}

@media (min-width: 576px) {
  .match-score__header {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

@media (min-width: 1920px) {
  .match-score__header {
    padding-top: 20px;
    padding-bottom: 18px;
  }
}

.match-score__body {
  padding-top: 18px;
  padding-bottom: 18px;
  transition: all 500ms ease 450ms;
}

@media (min-width: 576px) {
  .match-score__body {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}

@media (min-width: 1920px) {
  .match-score__body {
    padding-top: 42px;
    padding-bottom: 42px;
  }
}

.match-score__competition {
  color: #5e627e;
}

.match-score__footer {
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .match-score__footer {
    border-top: 1px solid rgba(94, 98, 126, 0.2);
  }
}

@media (min-width: 992px) {
  .match-score__footer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 41px 24px 0;
  }
}

@media (min-width: 1920px) {
  .match-score__footer {
    padding: 62px 40px 0;
  }
}

@media (min-width: 992px) {
  .match-score:hover .match-score__body {
    opacity: 0;
    transform: translateY(-20px);
    transition-delay: 0ms;
  }
  .match-score:hover .match-score__logo {
    opacity: 1;
    transform: translateY(0);
    transition: all 700ms ease 150ms;
  }
}

.matches-scores__navigation {
  padding-left: 0;
  list-style: none;
  max-width: calc(100% - 54px);
  margin-bottom: 26px;
}

@media (min-width: 576px) {
  .matches-scores__navigation {
    max-width: 50%;
    margin-bottom: 38px;
  }
}

@media (min-width: 768px) {
  .matches-scores__navigation {
    margin-top: -38px;
    margin-bottom: 62px;
  }
}

@media (min-width: 992px) {
  .matches-scores__navigation {
    max-width: 546px;
    margin-bottom: 69px;
  }
}

@media (min-width: 1920px) {
  .matches-scores__navigation {
    max-width: 880px;
    margin-top: -9px;
    margin-bottom: 80px;
  }
}

.matches-scores__navigation li {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
}

body:not(.cursor-is--active) .matches-scores__navigation li {
  cursor: pointer;
}

@media (min-width: 576px) {
  .matches-scores__navigation li {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

@media (min-width: 1920px) {
  .matches-scores__navigation li {
    font-size: 1.75rem;
  }
}

.matches-scores__navigation li span {
  display: block;
  padding-bottom: 3px;
  font-size: .75rem;
  line-height: 1;
  color: #5e627e;
  transition: 300ms all ease;
}

@media (min-width: 576px) {
  .matches-scores__navigation li span {
    font-size: .875rem;
    line-height: 1.375rem;
  }
}

@media (min-width: 1920px) {
  .matches-scores__navigation li span {
    padding-bottom: 8px;
    font-size: 1.25rem;
  }
}

.matches-scores__navigation li:hover span, .matches-scores__navigation li.active span {
  color: #a3ff12;
}

.matches-scores__navigation.slick-slider .slick-arrow {
  position: absolute;
  bottom: 6px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 0;
  outline: 0;
  z-index: 1;
  transition: opacity 300ms ease;
}

@media (min-width: 1920px) {
  .matches-scores__navigation.slick-slider .slick-arrow {
    bottom: 2px;
  }
}

body:not(.cursor-is--active) .matches-scores__navigation.slick-slider .slick-arrow {
  cursor: pointer;
}

.matches-scores__navigation.slick-slider .slick-arrow:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Left_Arrow_Icon' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' y='0px' width='8px' height='14px' viewBox='0 0 8 14' enable-background='new 0 0 8 14' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23FFFFFF' d='M0,7l6.68-7.009l1.336,1.402L2.672,7l5.344,5.605L6.68,14.008L0,7z '/%3E%3C/svg%3E") center/contain no-repeat;
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .matches-scores__navigation.slick-slider .slick-arrow:before {
    height: 20px;
  }
}

.matches-scores__navigation.slick-slider .slick-arrow:hover:before {
  background: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Left_Arrow_Icon' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' y='0px' width='8px' height='14px' viewBox='0 0 8 14' enable-background='new 0 0 8 14' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23A3FF12' d='M0,7l6.68-7.009l1.336,1.402L2.672,7l5.344,5.605L6.68,14.008L0,7z '/%3E%3C/svg%3E") center/contain no-repeat;
}

@media (min-width: 1920px) {
  .matches-scores__navigation.slick-slider .slick-arrow:hover:before {
    height: 20px;
  }
}

.matches-scores__navigation.slick-slider .slick-prev {
  left: calc(100% + 4px);
}

.matches-scores__navigation.slick-slider .slick-next {
  left: calc(100% + 42px);
  transform: rotate(180deg);
}

@media (min-width: 1920px) {
  .matches-scores__navigation.slick-slider .slick-next {
    left: calc(100% + 82px);
  }
}

.matches-scores__navigation ~ .matches-scores {
  padding-top: 6px;
}

.match-score-inline {
  position: relative;
  margin-bottom: 30px;
  font-weight: bold;
  letter-spacing: -.03em;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
}

.match-score-inline__body {
  padding: 23px 24px;
  transition: all 500ms ease 450ms;
}

.match-score-inline__body-inner {
  display: flex;
  justify-content: space-between;
}

.match-score-inline__body-inner + .match-score-inline__body-inner {
  margin-top: 8px;
}

.match-score-inline__footer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.match-score-inline:hover .match-score-inline__body {
  opacity: 0;
  transform: translateY(-20px);
  transition-delay: 0ms;
}

.match-stats-links {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.match-stats-links li a {
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 2px solid #5e627e;
  border-radius: 100%;
  text-align: center;
}

@media (min-width: 1920px) {
  .match-stats-links li a {
    width: 44px;
    height: 44px;
    line-height: 42px;
    border-width: 3px;
  }
}

.match-stats-links li a svg {
  display: inline-block;
  width: 12px;
  height: 12px;
  fill: #5e627e;
  transition: fill 300ms ease;
}

@media (min-width: 1920px) {
  .match-stats-links li a svg {
    width: 16px;
    height: 16px;
  }
}

.match-stats-links li a:hover, .match-stats-links li a.active {
  border-color: #a3ff12;
}

.match-stats-links li a:hover svg, .match-stats-links li a.active svg {
  fill: #fff;
}

@media (max-width: 991.98px) {
  .match-stats-links--main {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .match-stats-links--main {
    position: fixed;
    top: 50%;
    left: 70px;
    transform: translateY(-50%);
  }
}

.match-stats-links--main li {
  display: inline-block;
}

@media (min-width: 992px) {
  .match-stats-links--main li {
    display: block;
  }
}

.match-stats-links--main li + li {
  position: relative;
}

@media (max-width: 991.98px) {
  .match-stats-links--main li + li {
    margin-left: 34px;
  }
}

@media (min-width: 992px) {
  .match-stats-links--main li + li {
    margin-top: 38px;
  }
}

@media (min-width: 1920px) {
  .match-stats-links--main li + li {
    margin-top: 50px;
  }
}

.match-stats-links--main li + li:before {
  position: absolute;
  content: "";
}

@media (max-width: 991.98px) {
  .match-stats-links--main li + li:before {
    width: 30px;
    top: 16px;
    right: calc(100% + 4px);
    border-top: 2px dotted #5e627e;
  }
}

@media (min-width: 992px) {
  .match-stats-links--main li + li:before {
    bottom: calc(100% + 4px);
    left: 16px;
    height: 30px;
    border-left: 2px dotted #5e627e;
  }
}

@media (min-width: 1920px) {
  .match-stats-links--main li + li:before {
    bottom: calc(100% + 9px);
    left: 20px;
    height: 32px;
    border-width: 4px;
  }
}

.match-score .match-stats-links,
.match-score-inline .match-stats-links {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.match-score .match-stats-links li,
.match-score-inline .match-stats-links li {
  display: inline-block;
}

.match-score .match-stats-links li + li,
.match-score-inline .match-stats-links li + li {
  margin-left: 14px;
}

.match-score .match-stats-links li a,
.match-score-inline .match-stats-links li a {
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 2px solid #5e627e;
  border-radius: 100%;
  text-align: center;
}

@media (min-width: 1920px) {
  .match-score .match-stats-links li a,
  .match-score-inline .match-stats-links li a {
    width: 44px;
    height: 44px;
    line-height: 42px;
    border-width: 3px;
  }
}

.match-score .match-stats-links li a svg,
.match-score-inline .match-stats-links li a svg {
  display: inline-block;
  width: 12px;
  height: 12px;
  fill: #5e627e;
  transition: fill 300ms ease;
}

@media (min-width: 1920px) {
  .match-score .match-stats-links li a svg,
  .match-score-inline .match-stats-links li a svg {
    width: 16px;
    height: 16px;
  }
}

.match-score .match-stats-links li a:hover,
.match-score-inline .match-stats-links li a:hover {
  border-color: #a3ff12;
}

.match-score .match-stats-links li a:hover svg,
.match-score-inline .match-stats-links li a:hover svg {
  fill: #fff;
}

.match-score .match-stats-links li:nth-child(1),
.match-score-inline .match-stats-links li:nth-child(1) {
  transition-delay: 0;
}

.match-score .match-stats-links li:nth-child(2),
.match-score-inline .match-stats-links li:nth-child(2) {
  transition-delay: 150ms;
}

.match-score .match-stats-links li:nth-child(3),
.match-score-inline .match-stats-links li:nth-child(3) {
  transition-delay: 250ms;
}

.match-score .match-stats-links li:nth-child(4),
.match-score-inline .match-stats-links li:nth-child(4) {
  transition-delay: 350ms;
}

@media (min-width: 992px) {
  .match-score .match-stats-links li {
    opacity: 0;
    transform: translateY(20px);
    transition: all 700ms ease;
  }
}

.match-score-inline .match-stats-links li {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: all 700ms ease;
}

.match-score:hover .match-stats-links li,
.match-score-inline:hover .match-stats-links li {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 700ms ease;
}

.match-score:hover .match-stats-links li:nth-child(1),
.match-score-inline:hover .match-stats-links li:nth-child(1) {
  transition-delay: 150ms;
}

.match-score:hover .match-stats-links li:nth-child(2),
.match-score-inline:hover .match-stats-links li:nth-child(2) {
  transition-delay: 250ms;
}

.match-score:hover .match-stats-links li:nth-child(3),
.match-score-inline:hover .match-stats-links li:nth-child(3) {
  transition-delay: 350ms;
}

.match-score:hover .match-stats-links li:nth-child(4),
.match-score-inline:hover .match-stats-links li:nth-child(4) {
  transition-delay: 450ms;
}

.match-stats-links--color-light li a {
  border-color: rgba(255, 255, 255, 0.3);
}

.match-stats-links--color-light li a svg {
  fill: rgba(255, 255, 255, 0.3);
}

@media (max-width: 991.98px) {
  .match-stats-links--color-light li + li::before {
    border-top-color: rgba(255, 255, 255, 0.3);
  }
}

@media (min-width: 992px) {
  .match-stats-links--color-light li + li::before {
    border-left-color: rgba(255, 255, 255, 0.3);
  }
}

.match-player {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  overflow: hidden;
}

.match-player__avatar {
  position: relative;
  margin-bottom: 0;
}

.match-player--small .match-player__avatar {
  margin-right: 12px;
}

.lineups-table td:last-child .match-player--small .match-player__avatar {
  margin-right: 0;
  margin-left: 12px;
}

.match-player:not(.match-player--small) .match-player__avatar:before {
  position: absolute;
  top: -50%;
  right: 40%;
  bottom: -50%;
  left: -50%;
  content: "";
  background-color: #5e627e;
  transform: rotate(20deg);
}

.team-1:not(.match-player--small) .match-player__avatar:before {
  background-color: #a3ff12;
}

.team-2:not(.match-player--small) .match-player__avatar:before {
  background-color: #6e4aff;
}

.team-3:not(.match-player--small) .match-player__avatar:before {
  background-color: #d51633;
}

.team-4:not(.match-player--small) .match-player__avatar:before {
  background-color: #faca21;
}

.team-5:not(.match-player--small) .match-player__avatar:before {
  background-color: #0090ff;
}

.match-player__avatar img {
  position: relative;
  width: 100%;
  z-index: 1;
}

.match-player--small .match-player__avatar img {
  margin-bottom: 3px;
}

.lineups-table .match-player--small .match-player__avatar img {
  margin-bottom: 1px;
}

.match-player__avatar svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: #a3ff12;
}

.match-player__name {
  display: block;
  font-size: .625rem;
  color: #5e627e;
}

.lineups-table .match-player__name {
  text-transform: uppercase;
}

.match-player__skills {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  margin-bottom: 3px;
}

.lineups-table .match-player__skills {
  margin-bottom: 1px;
}

.match-player--small .match-player__skills {
  margin-left: -10px;
}

.lineups-table td:last-child .match-player--small .match-player__skills {
  order: 1;
  margin-left: 12px;
  margin-right: -10px;
}

.match-player__skills img + img {
  margin-top: 2px;
}

.match-player__level {
  position: absolute;
  bottom: 1px;
  right: 0;
  width: 10px;
  height: 10px;
  font-size: .5rem;
  line-height: 12px;
  text-align: center;
  background-color: #5e627e;
  z-index: 1;
}

.lineups-table td:last-child .match-player__level {
  right: auto;
  left: 0;
}

.team-blue .match-player__level {
  background-color: #197aff;
}

.team-red .match-player__level {
  background-color: #ff1c5c;
}

.match-player__nickname {
  font-size: .875rem;
  text-transform: none;
}

.lineups-table td:first-child .match-player--small {
  padding-left: 24px;
}

.lineups-table td:last-child .match-player--small {
  padding-right: 24px;
}

.match-stats-progress {
  display: flex;
  flex-wrap: wrap;
}

.match-stats-progress--icon, .match-stats-progress--double {
  flex-wrap: nowrap;
}

.match-stats-progress--icon {
  align-items: center;
}

.match-stats-progress--icon > div:last-child {
  margin-left: auto;
  width: 56%;
}

@media (max-width: 991.98px) {
  .match-stats-progress--icon > div:last-child {
    min-width: 136px;
  }
}

.match-stats-widget .match-stats-progress {
  padding-bottom: 1px;
}

.match-stats-widget-tabs .match-stats-progress {
  display: block;
}

@media (max-width: 991.98px) {
  .match-stats-widget-tabs .match-stats-progress {
    width: 32%;
  }
}

.match-stats-progress__icon {
  width: 24px;
  height: 24px;
}

@media (min-width: 1440px) {
  .match-stats-progress__icon {
    margin-left: 6px;
  }
}

.match-stats-progress__label {
  width: 100%;
  margin-bottom: 13px;
  text-align: center;
}

.match-stats-progress--double .match-stats-progress__label {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.match-stats-progress--icon .match-stats-progress__label {
  width: auto;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 15px;
  text-align: left;
}

.match-stats-progress__score {
  flex-basis: 36px;
  min-width: 36px;
}

.match-stats-progress__bar-group .match-stats-progress__score {
  flex-basis: 30px;
  min-width: 30px;
}

@media (min-width: 1440px) {
  .match-stats-progress__bar-group .match-stats-progress__score {
    flex-basis: 40px;
    min-width: 40px;
  }
}

.match-stats-widget-tabs .match-stats-progress__score {
  display: block;
  margin-bottom: 3px;
  font-size: .625rem;
  text-align: right;
}

.match-stats-progress__bar-group {
  display: flex;
}

.match-stats-progress__bar-group + .match-stats-progress__bar-group {
  margin-top: 6px;
}

.match-stats-progress__bar {
  position: relative;
  display: block;
  flex-basis: calc(100% - 72px);
  height: 10px;
  overflow: hidden;
}

.match-stats-progress__bar-group .match-stats-progress__bar {
  flex-basis: 100%;
}

@media (max-width: 991.98px) {
  .match-stats-progress__bar-group .match-stats-progress__bar {
    min-width: 94px;
  }
}

.match-stats-widget-tabs .match-stats-progress__bar {
  min-width: 78px;
  margin-right: -1px;
}

.match-stats-progress--double .match-stats-progress__bar {
  flex-basis: 19%;
  min-width: 46px;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .match-stats-progress--double .match-stats-progress__bar {
    flex-basis: 35%;
  }
}

.match-stats-progress__bar:before,
.match-stats-progress__bar span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1000px;
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #5e627e 2px, #5e627e 4px);
}

.match-stats-progress--double .match-stats-progress__bar span, .match-stats-progress--double .match-stats-progress__bar:before,
.match-stats-progress--double .match-stats-progress__bar span:before {
  left: auto;
  right: 0;
}

.match-stats-progress--double .match-stats-progress__bar:before {
  background-image: repeating-linear-gradient(to left, transparent, transparent 2px, #5e627e 2px, #5e627e 4px);
}

.match-stats-progress--double .match-stats-progress__bar ~ .match-stats-progress__bar span, .match-stats-progress--double .match-stats-progress__bar ~ .match-stats-progress__bar:before,
.match-stats-progress--double .match-stats-progress__bar ~ .match-stats-progress__bar span:before {
  left: 0;
  right: auto;
}

.match-stats-progress--double .match-stats-progress__bar ~ .match-stats-progress__bar:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #5e627e 2px, #5e627e 4px);
}

.match-stats-progress__bar span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.match-stats-progress__bar span:first-child {
  z-index: 1;
}

.match-stats-progress__bar .team-1:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #a3ff12 2px, #a3ff12 4px);
}

.match-stats-progress__bar .team-2:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #6e4aff 2px, #6e4aff 4px);
}

.match-stats-progress__bar .team-3:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #d51633 2px, #d51633 4px);
}

.match-stats-progress__bar .team-4:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #faca21 2px, #faca21 4px);
}

.match-stats-progress__bar .team-5:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #0090ff 2px, #0090ff 4px);
}

.match-stats-progress__bar .team-blue:before,
.team-blue .match-stats-progress__bar span:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #197aff 2px, #197aff 4px);
}

.match-stats-progress__bar .team-red:before,
.team-red .match-stats-progress__bar span:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #ff1c5c 2px, #ff1c5c 4px);
}

.match-stats-progress--double .match-stats-progress__bar .team-blue:before {
  background-image: repeating-linear-gradient(to left, transparent, transparent 2px, #197aff 2px, #197aff 4px);
}

.match-stats-progress--double .match-stats-progress__bar .team-red:before {
  background-image: repeating-linear-gradient(to left, transparent, transparent 2px, #ff1c5c 2px, #ff1c5c 4px);
}

.match-stats-progress--double .match-stats-progress__bar ~ .match-stats-progress__bar .team-blue:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #197aff 2px, #197aff 4px);
}

.match-stats-progress--double .match-stats-progress__bar ~ .match-stats-progress__bar .team-red:before {
  background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #ff1c5c 2px, #ff1c5c 4px);
}

.match-stats-progress__bar ~ .match-stats-progress__score {
  text-align: right;
}

@media (min-width: 992px) {
  .matches-tabs {
    display: flex;
    flex-direction: column;
  }
}

.matches-tabs__navigation {
  padding-left: 0;
  list-style: none;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 30px;
  font-size: .875rem;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

@media (min-width: 992px) {
  .matches-tabs__navigation {
    order: 1;
    margin-top: 62px;
    margin-bottom: 0;
  }
}

.matches-tabs__navigation li {
  display: inline-block;
}

.matches-tabs__navigation li a {
  color: #5e627e;
}

.matches-tabs__navigation li a:hover, .matches-tabs__navigation li a.active {
  color: #a3ff12;
}

.matches-tabs__navigation li + li {
  margin-left: 20px;
}

@media (min-width: 576px) {
  .matches-tabs__navigation li + li {
    margin-left: 40px;
  }
}

@media (min-width: 768px) {
  .matches-tabs__navigation li + li {
    margin-left: 50px;
  }
}

@media (min-width: 992px) {
  .matches-tabs__navigation li + li {
    margin-left: 70px;
  }
}

@media (min-width: 992px) {
  .matches-tabs__content.tab-content > .active {
    display: flex;
  }
}

.matches-tabs__navigation--color-light li a {
  color: rgba(255, 255, 255, 0.3);
}

.match-stats-widget-table {
  text-align: center;
}

.match-stats-widget-table thead tr,
.match-stats-widget-table tbody tr {
  display: flex;
}

.match-stats-widget-table thead {
  display: block;
  padding-right: 24px;
  background-color: #151720;
}

.match-stats-widget-table tbody {
  background-color: #13151e;
  border-top: 1px solid rgba(94, 98, 126, 0.2);
  width: 100%;
}

.match-stats-widget-table tbody tr + tr {
  border-top: 1px solid rgba(94, 98, 126, 0.2);
}

.match-stats-widget-table th, .match-stats-widget-table td {
  width: 71px;
}

.match-stats-widget-table th:first-child, .match-stats-widget-table td:first-child {
  min-width: 124px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
}

@media (min-width: 576px) {
  .match-stats-widget-table th:first-child, .match-stats-widget-table td:first-child {
    min-width: 344px;
  }
}

@media (min-width: 768px) {
  .match-stats-widget-table th:first-child, .match-stats-widget-table td:first-child {
    min-width: 524px;
  }
}

@media (min-width: 992px) {
  .match-stats-widget-table th:first-child, .match-stats-widget-table td:first-child {
    min-width: 94px;
  }
}

@media (min-width: 1440px) {
  .match-stats-widget-table th:first-child, .match-stats-widget-table td:first-child {
    min-width: 124px;
  }
}

.match-stats-widget-table th {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: .875rem;
}

.match-stats-widget-table th > span {
  display: block;
  font-size: .625rem;
}

.match-stats-widget-table th > span .team-cyan {
  color: #0fadff;
}

.match-stats-widget-table th > span .team-yellow {
  color: #ffe373;
}

.match-stats-widget-table th:first-child {
  padding-top: 24px;
}

.match-stats-widget-table td {
  display: flex;
  align-items: center;
  color: #5e627e;
}

.match-stats-widget-table td:first-child {
  padding-top: 19px;
  padding-bottom: 17px;
}

.match-stats-widget-table td + td {
  vertical-align: middle;
  border-left: 1px solid rgba(94, 98, 126, 0.2);
}

.match-stats-widget-table td:last-child {
  border-right: 1px solid rgba(94, 98, 126, 0.2);
}

.match-stats-widget-table svg {
  max-width: 70px;
  height: 22px;
  vertical-align: middle;
  fill: #5e627e;
}

.match-stats-widget-table svg.team-cyan {
  fill: #0fadff;
}

.match-stats-widget-table svg.team-yellow {
  fill: #ffe373;
}

.match-stats-widget-table svg.df-icon--explosion {
  height: 25px;
}

.match-stats-widget-table.nano {
  width: 100%;
  height: 266px;
}

.match-stats-widget-table.nano .nano-content {
  width: 100%;
}

.match-stats-widget-table.nano .nano-pane {
  top: 71px;
  width: 23px;
  padding: 8px 0px 18px 6px;
  background-color: #13151e;
  opacity: 1;
  border-radius: 0;
  overflow: hidden;
}

.match-stats-widget-table.nano .nano-slider {
  margin: 0;
  width: 11px;
  border-radius: 0;
  background-color: #222430;
}

.match-stats-widget-table.nano .nano-slider:before {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 5px;
  height: 12px;
  margin-top: -7px;
  content: "";
  background-image: repeating-linear-gradient(to bottom, transparent, transparent 2px, #5e627e 2px, #5e627e 4px);
}

.match-stats-widget-table.nano tbody {
  margin-top: 70px;
}

.match-stats-widget-tabs__header,
.match-stats-widget-tabs__body ul > li {
  padding: 19px 24px 17px;
  vertical-align: middle;
}

.match-stats-widget-tabs__body ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: rgba(94, 98, 126, 0.2);
}

.match-stats-widget-tabs__body ul > li + * {
  border-top-width: 1px;
  border-top-style: solid;
}

.match-stats-widget-tabs__body ul > li:first-child {
  border-top: none;
}

.match-stats-widget-tabs__body ul > li:nth-child(odd) {
  background-color: #13151e;
}

.match-stats-widget-tabs__body ul > li:nth-child(even) {
  background-color: #151720;
}

.match-stats-widget-tabs__header {
  margin-bottom: 0;
  background-color: #151720;
  border-bottom: 1px solid rgba(94, 98, 126, 0.2);
}

.match-stats-widget-tabs__header li {
  display: inline-block;
}

.match-stats-widget-tabs__header li a {
  color: #5e627e;
}

.match-stats-widget-tabs__header li a:hover, .match-stats-widget-tabs__header li a.active {
  color: #a3ff12;
}

.match-stats-widget-tabs__header li + li {
  margin-left: 17px;
}

.match-stats-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .match-stats-container {
    display: flex;
    justify-content: center;
  }
}

.match-stats-widget {
  width: 100%;
  font-size: .75rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
}

@media (min-width: 992px) {
  .match-stats-container .match-stats-widget {
    margin-left: 7px;
    margin-right: 7px;
    max-width: 250px;
  }
}

@media (min-width: 1200px) {
  .match-stats-container .match-stats-widget {
    margin-left: 25px;
    margin-right: 25px;
    max-width: 290px;
  }
}

@media (max-width: 991.98px) {
  .match-stats-widget + .match-stats-widget {
    margin-top: 30px;
  }
}

.match-stats-widget__header {
  margin-bottom: 11px;
  font-size: .875rem;
  letter-spacing: -.06em;
}

.match-stats-widget__item, .match-stats-widget__body {
  padding-left: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 0;
}

.match-stats-widget__item > li, .match-stats-widget__body > li {
  padding: 19px 24px 17px;
  vertical-align: middle;
  border-color: rgba(94, 98, 126, 0.2);
}

.match-stats-widget__item > li + li, .match-stats-widget__body > li + li {
  border-top-width: 1px;
  border-top-style: solid;
}

.match-stats-widget__item > li:first-child, .match-stats-widget__body > li:first-child {
  border-top: none;
}

.match-stats-widget__item > li:nth-child(odd), .match-stats-widget__body > li:nth-child(odd) {
  background-color: #151720;
}

.match-stats-widget__item > li:nth-child(even), .match-stats-widget__body > li:nth-child(even) {
  background-color: #13151e;
}

.match-stats-widget__item > li > span:last-child, .match-stats-widget__body > li > span:last-child {
  text-align: right;
}

.match-stats-widget__item > li > span:last-child > span, .match-stats-widget__body > li > span:last-child > span {
  display: block;
  font-size: .625rem;
}

.match-stats-widget li {
  position: relative;
}

.match-stats-widget li:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  content: "";
}

.match-stats-widget li.team-blue:before {
  background-color: #197aff;
}

.match-stats-widget li.team-red:before {
  background-color: #ff1c5c;
}

.match-stats-widget--general .match-stats-widget__body > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.match-stats-widget--general .match-stats-widget__body > li > span:first-child {
  color: #5e627e;
}

.match-stats-widget--general .match-team:last-child {
  text-align: right;
}

.match-stats-widget--leaders .match-stats-widget__item > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.match-stats-widget--leaders .match-stats-widget__item > li:first-child {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.match-stats-widget--leaders .match-stats-widget__item > li > span:first-child {
  color: #5e627e;
}

.match-stats-widget--leaders .match-stats-widget__item + * {
  margin-top: 14px;
}

.match-stats-widget__achievements {
  display: flex;
  justify-content: space-between;
}

.match-stats-widget .match-stats-widget__achievements {
  padding-top: 22px;
  padding-bottom: 20px;
}

.match-stats-widget__achievements ul {
  padding-left: 0;
  list-style: none;
}

.match-stats-widget__achievements ul li {
  display: inline-block;
}

.match-stats-widget__achievements ul img {
  margin-top: -2px;
}

.match-stats-widget__achievements ul:first-child li + li {
  margin-left: 8px;
}

.match-stats-widget__achievements ul:first-child img {
  margin-right: 6px;
}

.match-stats-widget__achievements ul:last-child li,
.match-stats-widget__achievements ul:last-child img {
  float: right;
}

.match-stats-widget__achievements ul:last-child li + li {
  margin-right: 8px;
}

.match-stats-widget__achievements ul:last-child img {
  margin-left: 6px;
}

.match-stats-widget__details ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.match-stats-widget__details ul svg {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 1px;
  margin-bottom: 11px;
  fill: #fff;
}

.match-stats-widget__details ul .team-blue {
  color: #197aff;
}

.match-stats-widget__details ul .team-red {
  color: #ff1c5c;
}

.match-stats-widget__details ul .team-1 {
  color: #a3ff12;
}

.match-stats-widget__details ul .team-2 {
  color: #6e4aff;
}

.match-stats-widget__details ul .team-3 {
  color: #d51633;
}

.match-stats-widget__details ul .team-4 {
  color: #faca21;
}

.match-stats-widget__details ul .team-5 {
  color: #0090ff;
}

.match-team {
  display: inline-block;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
}

.match-overview .match-team {
  text-align: center;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .match-overview .match-team {
    margin: 0 auto;
  }
}

.standings-table .match-team {
  width: 100%;
}

.match-score .match-team {
  max-width: 33%;
  flex: 1 0 33%;
}

@media (min-width: 576px) {
  .match-score .match-team {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .match-score .match-team {
    max-width: 40%;
    flex: 1 0 40%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
  .match-score .match-team {
    display: block;
  }
}

.lineups-table .match-team,
.match-stats-widget .match-team,
.match-score-inline .match-team,
.team-carousel-tabs .match-team {
  display: flex;
  align-items: center;
}

.match-stats-widget .match-team {
  padding-bottom: 2px;
}

.match-team, .match-team-logo {
  margin-bottom: 0;
}

.match-team-logo {
  display: inline-block;
  position: relative;
}

.match-overview .match-team-logo {
  width: 80px;
}

@media (min-width: 576px) {
  .match-overview .match-team-logo {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .match-overview .match-team-logo {
    width: 180px;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-team-logo {
    width: 260px;
  }
}

.standings-table .match-team-logo {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 12px;
}

@media (min-width: 1920px) {
  .standings-table .match-team-logo {
    width: 42px;
    height: 42px;
    margin-right: 14px;
  }
}

.upcoming-table .match-team-logo {
  width: 42px;
  height: 42px;
  margin-right: 12px;
}

@media (min-width: 1920px) {
  .upcoming-table .match-team-logo {
    width: 60px;
    height: 60px;
  }
}

.match-score .match-team-logo {
  width: 42px;
}

@media (min-width: 1920px) {
  .match-score .match-team-logo {
    width: 60px;
  }
}

.match-score-inline .match-team-logo {
  width: 30px;
}

.lineups-table th .match-team-logo {
  margin-bottom: 3px;
}

@media (max-width: 575.98px) {
  .lineups-table th .match-team-logo {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .lineups-table th .match-team-logo {
    display: none;
  }
}

.match-team-logo img {
  position: relative;
  width: 100%;
}

.match-team-logo:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  width: 70%;
  height: 70%;
  border-style: solid;
  border-color: #5e627e;
}

.match-overview .match-team-logo:before {
  border-width: 4px;
}

@media (min-width: 576px) {
  .match-overview .match-team-logo:before {
    border-width: 7px;
  }
}

@media (min-width: 992px) {
  .match-overview .match-team-logo:before {
    border-width: 10px;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-team-logo:before {
    border-width: 15px;
  }
}

.lineups-table .match-team-logo:before,
.standings-table .match-team-logo:before,
.match-stats-widget .match-team-logo:before,
.team-carousel-tabs .match-team-logo:before,
.match-score-inline .match-team-logo:before {
  border-width: 2px;
}

.match-team-logo--team-1:before {
  border-color: #a3ff12;
}

.match-team-logo--team-2:before {
  border-color: #6e4aff;
}

.match-team-logo--team-3:before {
  border-color: #d51633;
}

.match-team-logo--team-4:before {
  border-color: #faca21;
}

.match-team-logo--team-5:before {
  border-color: #0090ff;
}

.upcoming-table .match-team figcaption {
  display: inline-block;
  vertical-align: middle;
}

.match-score .match-team figcaption {
  padding-top: 8px;
}

@media (min-width: 576px) {
  .match-score .match-team figcaption {
    padding-top: 2px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
  .match-score .match-team figcaption {
    padding-top: 8px;
  }
}

.match-overview .match-team__name,
.standings-table .match-team__name,
.match-stats-widget .match-team__name,
.team-carousel-tabs .match-team__name {
  font-size: .875rem;
}

.match-overview .match-team__name {
  margin-top: 12px;
  line-height: 1;
}

@media (min-width: 576px) {
  .match-overview .match-team__name {
    margin-top: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  .match-overview .match-team__name {
    margin-top: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-team__name {
    margin-top: 34px;
    margin-bottom: -1px;
    font-size: 2.25rem;
  }
}

.standings-table .match-team__name {
  margin-top: 3px;
  margin-bottom: 1px;
}

@media (min-width: 1920px) {
  .standings-table .match-team__name {
    font-size: 1.25rem;
  }
}

.match-score .match-team__name {
  word-wrap: break-word;
  font-size: .75rem;
}

@media (min-width: 576px) {
  .match-score .match-team__name {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .match-score .match-team__name {
    font-size: 1.125rem;
  }
}

@media (min-width: 1920px) {
  .match-score .match-team__name {
    font-size: 1.75rem;
  }
}

.match-score-inline .match-team__name {
  font-size: .75rem;
}

.match-team__country {
  color: #a3ff12;
}

@media (min-width: 1920px) {
  .standings-table .match-team__country {
    font-size: .875rem;
  }
}

.match-overview .match-team__country {
  font-size: .75rem;
  color: #a3ff12;
}

@media (min-width: 576px) {
  .match-overview .match-team__country {
    font-size: .875rem;
  }
}

@media (min-width: 992px) {
  .match-overview .match-team__country {
    font-size: 1rem;
  }
}

@media (min-width: 1920px) {
  .match-overview .match-team__country {
    font-size: 1.5rem;
  }
}

.upcoming-table .match-team__country {
  display: block;
  margin-top: 6px;
  font-size: .75rem;
}

@media (min-width: 1920px) {
  .upcoming-table .match-team__country {
    margin-top: 5px;
    font-size: 1.25rem;
  }
}

.match-score .match-team__country {
  margin-top: 2px;
  font-size: .625rem;
}

@media (min-width: 576px) {
  .match-score .match-team__country {
    margin-top: 6px;
    font-size: .75rem;
  }
}

@media (min-width: 1920px) {
  .match-score .match-team__country {
    margin-top: 5px;
    font-size: 1.25rem;
  }
}

.match-score-inline .match-team__country {
  font-size: .625rem;
}

.lineups-table .match-team__country,
.match-stats-widget .match-team__country {
  font-size: .625rem;
}

.match-team__result {
  font-size: .625rem;
  color: #5e627e;
}

.match-team--winner .match-team__result {
  color: #a3ff12;
}

.match-team__result.team-blue {
  color: #197aff;
}

.match-team__result.team-red {
  color: #ff1c5c;
}

.match-score .match-team:first-child {
  justify-content: flex-start;
}

@media (min-width: 576px) {
  .match-score .match-team:first-child .match-team-logo {
    margin-right: 12px;
  }
}

@media (min-width: 768px) {
  .match-score .match-team:first-child .match-team-logo {
    margin-right: 23px;
  }
}

@media (min-width: 1920px) {
  .match-score .match-team:first-child .match-team-logo {
    margin-right: 16px;
  }
}

.match-score .match-team:first-child figcaption {
  text-align: left;
}

.match-score .match-team:last-child {
  justify-content: flex-end;
}

.match-score .match-team:last-child .match-team-logo {
  order: 1;
}

@media (max-width: 575px) {
  .match-score .match-team:last-child .match-team-logo {
    float: right;
  }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
  .match-score .match-team:last-child .match-team-logo {
    float: right;
    margin-left: 0;
  }
}

@media (min-width: 576px) {
  .match-score .match-team:last-child .match-team-logo {
    margin-left: 12px;
  }
}

@media (min-width: 768px) {
  .match-score .match-team:last-child .match-team-logo {
    margin-left: 23px;
  }
}

@media (min-width: 1920px) {
  .match-score .match-team:last-child .match-team-logo {
    margin-left: 16px;
  }
}

.match-score .match-team:last-child figcaption {
  text-align: right;
}

@media (max-width: 575px) {
  .match-score .match-team:last-child figcaption {
    clear: right;
  }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
  .match-score .match-team:last-child figcaption {
    clear: right;
  }
}

.match-score-inline .match-team {
  justify-content: flex-start;
}

.match-score-inline .match-team .match-team-logo {
  margin-right: 11px;
}

.match-stats-widget .match-team:first-child,
.team-carousel-tabs .match-team:first-child {
  justify-content: flex-start;
}

.match-stats-widget .match-team:first-child .match-team-logo,
.team-carousel-tabs .match-team:first-child .match-team-logo {
  margin-right: 12px;
}

.match-stats-widget .match-team:first-child figcaption,
.team-carousel-tabs .match-team:first-child figcaption {
  text-align: left;
}

.match-stats-widget .match-team:last-child,
.team-carousel-tabs .match-team:last-child {
  justify-content: flex-end;
}

.match-stats-widget .match-team:last-child .match-team-logo,
.team-carousel-tabs .match-team:last-child .match-team-logo {
  order: 1;
  float: right;
  margin-left: 12px;
}

.match-stats-widget .match-team:last-child figcaption,
.team-carousel-tabs .match-team:last-child figcaption {
  text-align: right;
  clear: right;
}

.lineups-table th:first-child .match-team {
  justify-content: flex-start;
}

.lineups-table th:first-child .match-team .match-team-logo {
  margin-right: 12px;
}

.lineups-table th:first-child .match-team figcaption {
  text-align: left;
}

.lineups-table th:last-child .match-team {
  justify-content: flex-end;
}

.lineups-table th:last-child .match-team .match-team-logo {
  order: 1;
  float: right;
  margin-left: 12px;
}

.lineups-table th:last-child .match-team figcaption {
  text-align: right;
  clear: right;
}

.match-score__footer .match-team-logo {
  display: none;
  opacity: 0;
  transform: translateY(20px);
  transition: all 700ms ease;
}

@media (min-width: 992px) {
  .match-score__footer .match-team-logo {
    display: block;
  }
}

@media (min-width: 992px) {
  .match-score:hover .match-score__footer .match-team-logo {
    opacity: 1;
    transform: translateY(0);
    transition: all 700ms ease 150ms;
  }
}

.match-team-list {
  padding-left: 0;
  list-style: none;
}

.match-team-list li {
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 3px;
}

.matches-filter {
  margin-bottom: 28px;
  float: left;
  text-align: left;
  letter-spacing: -.03em;
}

@media (min-width: 576px) {
  .matches-filter {
    margin-top: -82px;
    float: right;
    text-align: right;
  }
}

@media (min-width: 768px) {
  .matches-filter {
    margin-top: -108px;
    margin-bottom: 64px;
  }
}

@media (min-width: 992px) {
  .matches-filter {
    margin-top: -114px;
  }
}

@media (min-width: 1920px) {
  .matches-filter {
    margin-top: -139px;
  }
}

.matches-filter__label {
  display: block;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: #5e627e;
}

.matches-filter .cs-select {
  text-align: left;
}

@media (min-width: 576px) {
  .matches-filter .cs-select {
    text-align: right;
  }
}

.matches-filter .cs-select .cs-placeholder {
  width: auto;
  display: inline-block;
  padding: 0 20px 0 0;
  font-size: 1rem;
  transition: color 300ms ease;
}

.matches-filter .cs-select .cs-placeholder:after {
  top: 6px;
  right: 0;
}

.matches-filter .cs-select .cs-placeholder:before {
  top: 4px;
  right: 2px;
}

@media (min-width: 576px) {
  .matches-filter .cs-select .cs-placeholder {
    padding: 0 0 0 20px;
    font-size: 1.125rem;
  }
  .matches-filter .cs-select .cs-placeholder:after, .matches-filter .cs-select .cs-placeholder:before {
    left: 0;
    right: auto;
  }
  .matches-filter .cs-select .cs-placeholder:after {
    top: 7px;
    left: 0;
  }
  .matches-filter .cs-select .cs-placeholder:before {
    top: 5px;
    left: 2px;
  }
}

@media (min-width: 1920px) {
  .matches-filter .cs-select .cs-placeholder {
    padding-left: 34px;
    font-size: 1.75rem;
  }
  .matches-filter .cs-select .cs-placeholder:after {
    top: 11px;
    width: 8px;
  }
  .matches-filter .cs-select .cs-placeholder:before {
    top: 8px;
    left: 3px;
    height: 8px;
  }
}

.matches-filter .cs-select .cs-placeholder:hover {
  color: #a3ff12;
}

.matches-filter .cs-select .cs-placeholder:hover:before {
  opacity: 0;
}

.matches-filter .cs-select .cs-options {
  width: 180px;
  margin-top: 6px;
  box-shadow: 0px 20px 40px 0px rgba(0, 2, 0, 0.2);
}

@media (min-width: 1920px) {
  .matches-filter .cs-select .cs-options {
    margin-top: 10px;
    width: 200px;
  }
}

@media (min-width: 1920px) {
  .matches-filter .cs-select .cs-options ul {
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

.matches-filter .cs-select .cs-options span {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  font-size: .625rem;
}

@media (min-width: 1920px) {
  .matches-filter .cs-select .cs-options span {
    padding: 10px 34px;
    font-size: .875rem;
  }
}

@media (min-width: 576px) {
  .matches-filter .cs-select .cs-options {
    left: auto;
  }
}

.matches-table {
  margin-bottom: 0;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
}

.matches-table tbody > tr:first-child td {
  border-top: none;
}

.matches-table tbody tr:nth-child(odd) {
  background-color: #151720;
}

.matches-table tbody tr:nth-child(even) {
  background-color: #13151e;
}

.matches-table tbody td {
  vertical-align: middle;
  border-color: rgba(94, 98, 126, 0.2);
}

.match-lineups-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .match-lineups-container {
    max-width: 708px;
  }
}

@media (min-width: 1200px) {
  .match-lineups-container {
    max-width: 970px;
  }
}

@media (min-width: 992px) {
  .matches-tabs .match-lineups-container {
    margin-bottom: -20px;
  }
}

.lineups-table {
  width: 100%;
}

.lineups-table tr + tr td {
  border-top-style: solid;
}

.lineups-table tr:nth-child(odd) td.team-blue {
  background-color: rgba(25, 122, 255, 0.2);
}

.lineups-table tr:nth-child(odd) td.team-red {
  background-color: rgba(255, 28, 92, 0.2);
}

.lineups-table tr:nth-child(even) td.team-blue {
  background-color: rgba(25, 122, 255, 0.1);
}

.lineups-table tr:nth-child(even) td.team-red {
  background-color: rgba(255, 28, 92, 0.1);
}

.lineups-table th, .lineups-table td {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.lineups-table th:first-child, .lineups-table td:first-child {
  padding-left: 0;
  text-align: left;
}

.lineups-table th:last-child, .lineups-table td:last-child {
  padding-right: 0;
  text-align: right;
}

.lineups-table th {
  padding-bottom: 5px;
  font-size: .75rem;
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .lineups-table th {
    padding-bottom: 11px;
    font-size: .875rem;
  }
}

.lineups-table td {
  font-size: .75rem;
  text-transform: none;
}

.lineups-table td.team-blue:first-child, .lineups-table td.team-red:last-child {
  position: relative;
}

.lineups-table td.team-blue:first-child:before, .lineups-table td.team-red:last-child:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  content: "";
}

.lineups-table td.team-blue:first-child:before {
  left: 0;
  background-color: #197aff;
}

.lineups-table td.team-red:last-child:before {
  right: 0;
  background-color: #ff1c5c;
}

.lineups-table td:last-child .match-player {
  justify-content: flex-end;
}

.lineups-table td:last-child .match-player__avatar {
  order: 2;
}

@media (max-width: 374px) {
  .lineups-table td:first-child .match-player {
    padding-left: 15px;
  }
  .lineups-table td:last-child .match-player {
    padding-right: 15px;
  }
}

@media (max-width: 767.98px) {
  .lineups-table .td-d-none-sm {
    display: none;
  }
}

.lineups-table svg {
  width: 14px;
  height: 14px;
  fill: #fff;
}

.lineups-table--style-1 tr + tr td {
  border-top-width: 1px;
}

.lineups-table--style-1 td {
  padding-top: 0;
  padding-bottom: 0;
}

.lineups-table--style-1 td:first-child, .lineups-table--style-1 td:last-child {
  width: 145px;
}

@media (min-width: 576px) {
  .lineups-table--style-1 td:first-child, .lineups-table--style-1 td:last-child {
    width: 225px;
  }
}

.lineups-table--style-1 td:last-child .match-player__avatar:before {
  position: absolute;
  right: -50%;
  left: 40%;
  transform: rotate(-20deg);
}

@media (max-width: 767.98px) {
  .lineups-table--style-1 td .match-player {
    position: relative;
    padding-top: 18px;
    padding-bottom: 17px;
  }
}

@media (max-width: 767.98px) and (min-width: 375px) {
  .lineups-table--style-1 td:first-child .match-player {
    padding-left: 24px;
  }
}

@media (max-width: 767.98px) {
  .lineups-table--style-1 td:first-child .match-player__avatar:not(.match-player__avatar--small):before {
    left: 0;
    right: auto;
  }
}

@media (max-width: 767.98px) and (min-width: 375px) {
  .lineups-table--style-1 td:last-child .match-player {
    padding-right: 24px;
  }
}

@media (max-width: 767.98px) {
  .lineups-table--style-1 td:last-child .match-player__avatar:not(.match-player__avatar--small):before {
    left: auto;
    right: 0;
  }
  .lineups-table--style-1 td .match-player__avatar:not(.match-player__avatar--small) {
    position: initial;
  }
  .lineups-table--style-1 td .match-player__avatar:not(.match-player__avatar--small) img {
    display: none;
  }
  .lineups-table--style-1 td .match-player__avatar:not(.match-player__avatar--small):before {
    top: 0;
    bottom: 0;
    width: 2px;
    transform: none;
  }
}

@media (max-width: 575px) {
  .lineups-table--style-1 td {
    padding: 0;
  }
}

.lineups-table--style-1 .td-role {
  width: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 576px) {
  .lineups-table--style-1 .td-role {
    width: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .lineups-table--style-1 .td-role {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .lineups-table--style-1 .td-role {
    width: 70px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-1 .td-role {
    width: 90px;
  }
}

.lineups-table--style-1 .td-center-left {
  padding-left: 5px;
  padding-right: 30px;
}

@media (min-width: 992px) {
  .lineups-table--style-1 .td-center-left {
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-1 .td-center-left {
    padding-right: 60px;
  }
}

.lineups-table--style-1 .td-center-right {
  padding-right: 5px;
  padding-left: 30px;
}

@media (min-width: 992px) {
  .lineups-table--style-1 .td-center-right {
    padding-left: 40px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-1 .td-center-right {
    padding-left: 60px;
  }
}

.lineups-table--style-2 tr + tr td {
  border-top-width: 2px;
  border-color: #151720;
}

.lineups-table--style-2 td {
  padding-top: 15px;
  padding-bottom: 14px;
}

@media (min-width: 992px) {
  .lineups-table--style-2 td:first-child {
    padding-right: 0;
  }
  .lineups-table--style-2 td:last-child {
    padding-left: 0;
  }
}

.lineups-table--style-2 .td-center-left {
  padding-right: 20px;
}

@media (min-width: 1200px) {
  .lineups-table--style-2 .td-center-left {
    padding-left: 10px;
    padding-right: 44px;
  }
}

.lineups-table--style-2 .td-center-right {
  padding-left: 20px;
}

@media (min-width: 1200px) {
  .lineups-table--style-2 .td-center-right {
    padding-right: 10px;
    padding-left: 44px;
  }
}

.lineups-table--style-2 td.td-center-right {
  border-left: 2px solid #151720;
}

@media (min-width: 576px) {
  .lineups-table--style-2 .td-player {
    min-width: 186px;
  }
}

@media (min-width: 768px) {
  .lineups-table--style-2 .td-player {
    min-width: 186px;
  }
}

@media (min-width: 992px) {
  .lineups-table--style-2 .td-player {
    min-width: 106px;
  }
  .lineups-table--style-2 .td-player:first-child {
    padding-right: 0;
  }
  .lineups-table--style-2 .td-player:last-child {
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-2 .td-player {
    min-width: 186px;
  }
}

.lineups-table--style-2 .td-kda {
  min-width: 80px;
}

.lineups-table--style-2 .td-strk {
  width: 70px;
  padding-left: 2px;
  padding-right: 2px;
}

@media (min-width: 992px) {
  .lineups-table--style-2 .td-strk {
    width: 44px;
  }
}

.lineups-table--style-2 .td-gold {
  min-width: 70px;
}

@media (min-width: 992px) {
  .lineups-table--style-2 .td-gold {
    min-width: 74px;
  }
}

.lineups-table--style-2 .td-cs {
  min-width: 70px;
}

@media (min-width: 992px) {
  .lineups-table--style-2 .td-cs {
    min-width: 52px;
  }
}

.lineups-table--style-2 .match-player__avatar img {
  display: block;
}

@media (max-width: 575.98px) {
  .lineups-table--style-2 .match-player__avatar, .lineups-table--style-2 .match-player__skills {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .lineups-table--style-2 .match-player__avatar, .lineups-table--style-2 .match-player__skills {
    display: none;
  }
}

.lineups-table--style-3 tr + tr td {
  border-top-width: 1px;
}

.lineups-table--style-3 td {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  .lineups-table--style-3 td {
    padding-top: 18px;
    padding-bottom: 17px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .lineups-table--style-3 td {
    padding-top: 18px;
    padding-bottom: 17px;
  }
}

.lineups-table--style-3 td .match-player:not(.match-player--small) .match-player__avatar:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: none;
}

@media (max-width: 575.98px) {
  .lineups-table--style-3 td:first-child .match-player {
    padding-left: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .lineups-table--style-3 td:first-child .match-player {
    padding-left: 24px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .lineups-table--style-3 td:first-child .match-player__avatar {
    margin-right: 18px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-3 td:first-child .match-player__avatar {
    margin-right: 18px;
  }
}

@media (max-width: 575.98px) {
  .lineups-table--style-3 td:last-child .match-player {
    padding-right: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .lineups-table--style-3 td:last-child .match-player {
    padding-right: 24px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .lineups-table--style-3 td:last-child .match-player__avatar {
    margin-left: 18px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-3 td:last-child .match-player__avatar {
    margin-left: 18px;
  }
}

@media (max-width: 575.98px) {
  .lineups-table--style-3 td:first-child .match-player__avatar:not(.match-player__avatar--small):before {
    left: 0;
    right: auto;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .lineups-table--style-3 td:first-child .match-player__avatar:not(.match-player__avatar--small):before {
    left: 0;
    right: auto;
  }
}

@media (max-width: 575.98px) {
  .lineups-table--style-3 td:last-child .match-player__avatar:not(.match-player__avatar--small):before {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .lineups-table--style-3 td:last-child .match-player__avatar:not(.match-player__avatar--small):before {
    left: auto;
    right: 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .lineups-table--style-3 td .match-player {
    min-width: 186px;
  }
}

@media (max-width: 575.98px) {
  .lineups-table--style-3 td .match-player__avatar:not(.match-player__avatar--small) {
    position: initial;
  }
  .lineups-table--style-3 td .match-player__avatar:not(.match-player__avatar--small):before {
    top: 0;
    bottom: 0;
    width: 2px;
    transform: none;
  }
  .lineups-table--style-3 td .match-player__avatar:not(.match-player__avatar--small) img {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .lineups-table--style-3 td .match-player__avatar:not(.match-player__avatar--small) {
    position: initial;
  }
  .lineups-table--style-3 td .match-player__avatar:not(.match-player__avatar--small):before {
    top: 0;
    bottom: 0;
    width: 2px;
    transform: none;
  }
  .lineups-table--style-3 td .match-player__avatar:not(.match-player__avatar--small) img {
    display: none;
  }
}

@media (max-width: 374px) {
  .lineups-table--style-3 td:first-child .match-player {
    padding-left: 15px;
  }
  .lineups-table--style-3 td:last-child .match-player {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-3 .td-center-left {
    padding-left: 5px;
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .lineups-table--style-3 .td-center-right {
    padding-right: 5px;
    padding-left: 60px;
  }
}

.upcoming-table {
  font-size: 1.125rem;
}

@media (min-width: 1920px) {
  .upcoming-table {
    font-size: 1.75rem;
  }
}

.upcoming-table tbody td {
  padding: 22px 14px;
}

.upcoming-table tbody td:first-child {
  width: 4.78%;
  min-width: 48px;
  padding-top: 24px;
  padding-right: 0;
}

.upcoming-table tbody td:nth-child(2) {
  width: 27.812%;
  min-width: 248px;
}

.upcoming-table tbody td:nth-child(3) {
  width: 23.5%;
  min-width: 180px;
}

.upcoming-table tbody td:nth-child(4), .upcoming-table tbody td:nth-child(5) {
  width: 13.675%;
  min-width: 130px;
}

.upcoming-table tbody td:nth-child(6) {
  width: 16.585%;
  min-width: 124px;
}

@media (min-width: 576px) {
  .upcoming-table tbody td {
    padding: 29px 0 27px 24px;
  }
  .upcoming-table tbody td:first-child {
    min-width: 56px;
    padding-top: 34px;
  }
  .upcoming-table tbody td:nth-child(6) {
    min-width: 140px;
  }
}

@media (min-width: 1920px) {
  .upcoming-table tbody td {
    padding-top: 36px;
    padding-bottom: 34px;
  }
  .upcoming-table tbody td:first-child {
    padding-top: 40px;
  }
}

.upcoming-table__label {
  display: block;
  margin-top: 6px;
  font-size: .75rem;
  color: #5e627e;
}

@media (min-width: 1920px) {
  .upcoming-table__label {
    margin-top: 5px;
    font-size: 1.25rem;
  }
}

.upcoming-table__team-vs {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

@media (min-width: 1920px) {
  .upcoming-table__team-vs {
    margin-left: 28px;
  }
}

.upcoming-table__tickets,
.upcoming-table__date span {
  color: #a3ff12;
}

.upcoming-table__date {
  font-size: .875rem;
}

@media (min-width: 1920px) {
  .upcoming-table__date {
    font-size: 1.75rem;
  }
}

.upcoming-table__date span {
  display: block;
  font-size: 1.75rem;
  line-height: .75;
}

@media (min-width: 1920px) {
  .upcoming-table__date span {
    margin-bottom: -1px;
    font-size: 3.25rem;
  }
}

.upcoming-table__tickets:hover {
  color: #fff;
}

.upcoming-table__tickets.disable {
  color: #5e627e;
}

.standings-table thead th,
.standings-table tbody td {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.standings-table thead th:first-child,
.standings-table tbody td:first-child {
  width: 6%;
  padding-left: 14px;
  padding-right: 0;
}

@media (min-width: 768px) {
  .standings-table thead th:first-child,
  .standings-table tbody td:first-child {
    padding-left: 24px;
  }
}

.standings-table thead th:nth-child(2),
.standings-table tbody td:nth-child(2) {
  padding-left: 20px;
  padding-right: 0;
}

@media (min-width: 768px) {
  .standings-table thead th:nth-child(2),
  .standings-table tbody td:nth-child(2) {
    padding-left: 10px;
  }
}

.standings-table thead th:first-child, .standings-table thead th:nth-child(2),
.standings-table tbody td:first-child,
.standings-table tbody td:nth-child(2) {
  text-align: left;
}

.standings-table thead th:nth-child(7),
.standings-table tbody td:nth-child(7) {
  width: 8%;
}

.standings-table thead th:last-child,
.standings-table tbody td:last-child {
  width: 8.6%;
  padding-left: 0;
  padding-right: 14px;
}

@media (min-width: 768px) {
  .standings-table thead th:last-child,
  .standings-table tbody td:last-child {
    padding-right: 24px;
  }
}

.standings-table thead th {
  padding: 2px 10px 11px;
  border: none;
}

@media (min-width: 1920px) {
  .standings-table thead th {
    padding-bottom: 22px;
  }
}

.standings-table tbody td {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: .75rem;
}

@media (min-width: 1920px) {
  .standings-table tbody td {
    font-size: 1.125rem;
  }
}

.standings-table__team {
  width: 19.658%;
  min-width: 180px;
}

.standings-table__wins, .standings-table__streak--wins {
  color: #a3ff12;
}

.standings-table__losses, .standings-table__streak--losses {
  color: #ff1c5c;
}

.team-carousel {
  position: relative;
  z-index: 1;
}

@media (min-width: 1440px) {
  .team-carousel {
    padding-left: 70px;
  }
}

.team-carousel__content > .slick-dots {
  position: absolute;
  top: 30px;
  padding-left: 0;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .team-carousel__content > .slick-dots {
    top: 50%;
    transform: translateY(-50%);
    left: -78px;
  }
}

@media (min-width: 1440px) {
  .team-carousel__content > .slick-dots {
    left: -98px;
  }
}

@media (min-width: 1920px) {
  .team-carousel__content > .slick-dots {
    left: -124px;
  }
}

.team-carousel__content > .slick-dots li {
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 2px solid #5e627e;
  border-radius: 100%;
  text-align: center;
}

@media (min-width: 1920px) {
  .team-carousel__content > .slick-dots li {
    width: 44px;
    height: 44px;
    line-height: 42px;
    border-width: 3px;
  }
}

body:not(.cursor-is--active) .team-carousel__content > .slick-dots li {
  cursor: pointer;
}

.team-carousel__content > .slick-dots li svg {
  display: inline-block;
  width: 12px;
  height: 12px;
  fill: #5e627e;
  transition: fill 300ms ease;
}

@media (min-width: 1920px) {
  .team-carousel__content > .slick-dots li svg {
    width: 16px;
    height: 16px;
  }
}

.team-carousel__content > .slick-dots li:hover, .team-carousel__content > .slick-dots li.slick-active {
  border-color: #a3ff12;
}

.team-carousel__content > .slick-dots li:hover svg, .team-carousel__content > .slick-dots li.slick-active svg {
  fill: #fff;
}

.team-carousel__content > .slick-dots li {
  display: inline-block;
}

@media (min-width: 1200px) {
  .team-carousel__content > .slick-dots li {
    display: block;
  }
}

.team-carousel__content > .slick-dots li + li {
  position: relative;
}

@media (max-width: 374px) {
  .player-info-page .team-carousel__content > .slick-dots li + li {
    margin-left: 30px;
  }
}

@media (max-width: 1199px) {
  .team-carousel__content > .slick-dots li + li {
    margin-left: 34px;
  }
}

@media (min-width: 1200px) {
  .team-carousel__content > .slick-dots li + li {
    margin-top: 38px;
  }
}

@media (min-width: 1920px) {
  .team-carousel__content > .slick-dots li + li {
    margin-top: 50px;
  }
}

.team-carousel__content > .slick-dots li + li:before {
  position: absolute;
  content: "";
}

@media (max-width: 374px) {
  .player-info-page .team-carousel__content > .slick-dots li + li:before {
    width: 22px;
  }
}

@media (max-width: 1199px) {
  .team-carousel__content > .slick-dots li + li:before {
    width: 26px;
    top: 14px;
    right: calc(100% + 6px);
    border-top: 2px dotted #5e627e;
  }
}

@media (min-width: 1200px) {
  .team-carousel__content > .slick-dots li + li:before {
    bottom: calc(100% + 6px);
    left: 14px;
    height: 30px;
    border-left: 2px dotted #5e627e;
  }
}

@media (min-width: 1920px) {
  .team-carousel__content > .slick-dots li + li:before {
    bottom: calc(100% + 12px);
    left: 16px;
    height: 32px;
    border-width: 4px;
  }
}

@media (min-width: 1200px) {
  .team-carousel__content {
    height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .team-carousel__content {
    height: calc(100vh - 100px);
  }
}

@media (max-width: 1199px) {
  .team-carousel__item {
    padding-top: 110px;
  }
}

@media (max-width: 767.98px) {
  .team-carousel__item {
    padding-top: 94px;
  }
}

@media (min-width: 1200px) {
  .team-carousel__item {
    display: flex !important;
    align-items: center;
    min-height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .team-carousel__item {
    min-height: calc(100vh - 100px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .team-carousel__item > .row > div {
    flex-basis: 80%;
    max-width: 80%;
  }
}

.team-carousel__item > .row > div > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .team-carousel__item .col-md-6.col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .team-carousel__item .row-between-xl-2col > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.team-carousel__item p {
  color: #c6cbea;
}

.team-carousel__item * + p {
  margin-top: -6px;
}

.team-carousel-tabs__navigation {
  justify-content: center;
  margin-top: 18px;
  font-weight: bold;
  letter-spacing: -.03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.team-carousel-tabs__navigation li {
  display: inline-block;
}

body:not(.cursor-is--active) .team-carousel-tabs__navigation li {
  cursor: pointer;
}

.team-carousel-tabs__navigation li a {
  outline: none;
  color: #5e627e;
}

.team-carousel-tabs__navigation li a:hover, .team-carousel-tabs__navigation li a.active {
  color: #a3ff12;
}

.team-carousel-tabs__navigation li + li {
  margin-left: 20px;
}

@media (min-width: 576px) {
  .team-carousel-tabs__navigation li + li {
    margin-left: 34px;
  }
}

.team-carousel * + .row.row-mb-balance {
  margin-bottom: -24px !important;
}

@media (min-width: 768px) {
  .team-carousel * + .row.row-mb-balance {
    margin-bottom: -39px !important;
  }
}

@media (min-width: 1440px) {
  .team-carousel * + .row.row-mb-balance {
    margin-bottom: -49px !important;
  }
}

.team-info-page .team-player,
.player-info-page .team-player {
  position: relative;
}

@media (min-width: 1200px) {
  .team-info-page .team-player,
  .player-info-page .team-player {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .team-info-page .team-player,
  .player-info-page .team-player {
    height: calc(100vh - 100px);
  }
}

.team-info-page .team-player {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .team-info-page .team-player {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .team-info-page .team-player {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .team-info-page .team-player > .container > .row > div {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .player-info-page .team-player {
    display: none;
  }
}

@media (min-width: 1200px) {
  .player-info-page .team-player__base, .player-info-page .team-player__photo {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}

.player-info-page .team-player__base {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.player-info-page .team-player__base img {
  width: 100%;
  object-fit: contain;
}

@media (min-width: 1200px) {
  .player-info-page .team-player__base img {
    height: 100%;
    object-fit: cover;
  }
}

.team-info-page .team-player__base {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1200px) {
  .team-info-page .team-player__base {
    height: calc(54.5573vh);
  }
}

@media (max-width: 374px) {
  .team-info-page .team-player__base img {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .team-info-page .team-player__base img {
    position: relative;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
  }
}

.team-player__photo {
  z-index: 1;
}

.player-info-page .team-player__photo {
  width: 100%;
}

@media (max-width: 1199px) {
  .player-info-page .team-player__photo {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .player-info-page .team-player__photo {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .player-info-page .team-player__photo {
    width: 50%;
  }
}

.player-info-page .team-player__photo img {
  width: 100%;
}

@media (min-width: 1200px) {
  .player-info-page .team-player__photo img {
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1200px) {
  .team-info-page .team-player__photo {
    height: 54.5573vh;
  }
}

@media (min-width: 1920px) {
  .team-info-page .team-player__photo {
    height: 53.981481vh;
  }
}

.team-info-page .team-player__photo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: .75;
  transform: translate3d(0, 7.5%, 0);
  transition: 0.2s all cubic-bezier(0.86, 0, 0.07, 1);
}

@media (max-width: 374px) {
  .team-info-page .team-player__photo img {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .team-info-page .team-player__photo img {
    position: relative;
    left: 50%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    transform: translate3d(-50%, 7.5%, 0);
  }
}

.team-info-page .tab-pane.show .team-player__photo img {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 1200px) {
  .team-info-page .tab-pane.show .team-player__photo img {
    transform: translate3d(-50%, 0, 0);
  }
}

.team-player__info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}

@media (min-width: 1200px) {
  .team-player__info {
    height: calc(100vh - 72px);
  }
}

@media (min-width: 1920px) {
  .team-player__info {
    height: calc(100vh - 100px);
  }
}

.team-player__header {
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .team-player__header {
    margin-bottom: 50px;
  }
}

@media (min-width: 1920px) {
  .team-player__header {
    margin-bottom: 52px;
  }
}

.team-player__name {
  margin-bottom: 9px;
}

@media (min-width: 1920px) {
  .team-player__name {
    margin-bottom: 21px;
  }
}

.team-player__nickname {
  margin-bottom: 15px;
  font-size: 2.5rem;
  text-transform: none;
  color: #fff;
}

@media (min-width: 576px) {
  .team-player__nickname {
    margin-bottom: 25px;
    font-size: 3.5rem;
  }
}

@media (min-width: 1920px) {
  .team-player__nickname {
    margin-bottom: 39px;
    font-size: 5.25rem;
  }
}

.team-player__nickname .add-icon {
  position: absolute;
  top: 4px;
  left: calc(100% + 13px);
}

@media (min-width: 576px) {
  .team-player__nickname .add-icon {
    top: 16px;
    left: calc(100% + 23px);
  }
}

@media (min-width: 1920px) {
  .team-player__nickname .add-icon {
    top: 24px;
    left: calc(100% + 27px);
  }
}

.team-player__filter {
  margin-bottom: 40px;
  padding: 15px 15px 60px;
  display: flex;
  justify-content: center;
  z-index: 1;
  -webkit-transform: translateZ(0px);
  will-change: transform;
}

@media (min-width: 1200px) {
  .team-player__filter {
    position: absolute;
    top: calc(50% - 130px);
    left: calc(100% + 122px);
    transform: translateY(-50%);
    flex-direction: column;
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .team-player__filter {
    left: calc(100% + 110px);
  }
}

@media (min-width: 1920px) {
  .team-player__filter {
    top: calc(50% - 190px);
    left: calc(100% + 130px);
  }
}

.team-player__filter li:nth-child(even) a {
  transform: rotate(45deg) translate(45.75%, 45.75%);
}

@media (min-width: 1200px) {
  .team-player__filter li:nth-child(even) a {
    transform: rotate(45deg) translate(-45.75%, 45.75%);
  }
}

.team-player__filter li + li {
  margin-left: -24.5px;
}

@media (min-width: 1200px) {
  .team-player__filter li + li {
    margin-top: -24.5px;
    margin-left: 0;
  }
}

@media (min-width: 1920px) {
  .team-player__filter li + li {
    margin-top: -34.3px;
  }
}

.team-player__filter a {
  position: relative;
  display: block;
  width: 70px;
  height: 70px;
  padding: 0;
  outline: 0;
  border: 6px solid #3d4055;
  transform: rotate(45deg);
  overflow: hidden;
  color: #fff;
  background-color: #151720;
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .team-player__filter a {
    width: 98px;
    height: 98px;
    border-width: 8.4px;
  }
}

.team-player__filter a::before, .team-player__filter a::after {
  position: absolute;
  content: "";
  top: -21px;
  left: -21px;
  width: 100px;
  height: 100px;
  z-index: 33;
  transform: rotate(-45deg);
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .team-player__filter a::before, .team-player__filter a::after {
    top: -29.4px;
    left: -29.4px;
    width: 140px;
    height: 140px;
  }
}

.team-player__filter a::before {
  z-index: 34;
  mix-blend-mode: multiply;
  background-color: #6b7199;
}

.team-player__filter a::after {
  z-index: 35;
  mix-blend-mode: screen;
  background-color: #151720;
}

.team-player__filter a img {
  width: 100px;
  height: 100px;
  margin-left: -21px;
  margin-top: -21px;
  object-fit: cover;
  transform: rotate(-45deg);
  filter: grayscale(100%);
}

@media (min-width: 1920px) {
  .team-player__filter a img {
    top: -29.4px;
    left: -29.4px;
    width: 140px;
    height: 140px;
  }
}

.team-player__filter a:hover, .team-player__filter a.active {
  position: relative;
  z-index: 1;
  border-color: #a3ff12;
}

.team-player__filter a:hover img, .team-player__filter a.active img {
  filter: grayscale(0);
}

.team-player__filter a:hover::before, .team-player__filter a:hover::after, .team-player__filter a.active::before, .team-player__filter a.active::after {
  opacity: 0;
}

.team-info-page,
.player-info-page {
  color: #fff;
  overflow: hidden;
}

.team-info-page.site-content,
.player-info-page.site-content {
  padding-top: 30px;
  padding-bottom: 72px;
}

@media (min-width: 992px) {
  .team-info-page.site-content,
  .player-info-page.site-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.player-info-page__statistics-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

@media (max-width: 575.98px) {
  .player-info-page__statistics-footer .player-info-detail--hero {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 575.98px) {
  .player-info-page__statistics-footer > div:not(.player-info-detail--hero) {
    width: 50%;
  }
}

@media (min-width: 576px) {
  .player-info-page__statistics-footer > .player-info-detail:last-child {
    margin-left: 56px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .team-info-page > .container > .row > div {
    flex-basis: 80%;
    max-width: 80%;
  }
}

.team-info-page .team-player > .container > .row > div {
  position: relative;
}

@media (min-width: 1920px) {
  .team-info-page .team-player > .container > .row > div {
    margin-left: 54%;
  }
}

.team-info-page .team-player > .container > .row > div:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  padding-bottom: 54.5573%;
  transform: translateX(-50%);
  background: url(../img/team-info-page-player-base.png) bottom no-repeat;
  background-size: contain;
}

@media (min-width: 1200px) {
  .team-info-page .team-player > .container > .row > div:before {
    width: 87.2396vh;
    padding-bottom: 54.5573vh;
  }
}

@media (max-width: 374px) {
  .team-info-page .team-player > .container > .row > div:before img {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .team-info-page .team-player > .container > .row > div:before img {
    position: relative;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
  }
}

.player-info-subtitle, .player-info-title {
  text-transform: none;
}

.player-info-subtitle {
  letter-spacing: -.03em;
  color: #a3ff12;
}

.player-info-subtitle.h4 {
  margin-bottom: 12px;
  font-size: 1.5rem;
}

@media (min-width: 1920px) {
  .player-info-subtitle.h4 {
    margin-bottom: 15px;
    font-size: 2.25rem;
  }
}

.player-info-subtitle.h5 {
  margin-bottom: 9px;
  font-size: 1.25rem;
}

@media (min-width: 1920px) {
  .player-info-subtitle.h5 {
    margin-bottom: 16px;
    font-size: 1.5rem;
  }
}

.player-info-subtitle.h5 + .btn {
  margin-top: 5px;
}

@media (max-width: 767.98px) {
  .player-info-subtitle.h5 + .btn {
    margin-top: -19px;
  }
}

@media (max-width: 374px) {
  .player-info-subtitle.h5 + .btn {
    margin-top: -10px;
    padding: 0.75rem 1rem 0.625rem;
  }
  .player-info-subtitle.h5 + .btn i {
    display: none;
  }
}

.player-info-title {
  color: #fff;
  margin-bottom: 32px;
}

@media (min-width: 992px) {
  .player-info-title {
    margin-bottom: 43px;
  }
}

@media (min-width: 768px) and (max-width: 1439.98px) {
  .player-info-title {
    font-size: 3.875rem;
  }
}

@media (min-width: 1440px) {
  .player-info-title {
    margin-bottom: 67px;
  }
}

@media (min-width: 1920px) {
  .player-info-title {
    margin-bottom: 85px;
    font-size: 5.25rem;
  }
}

.player-info-title.h1 {
  margin-bottom: 34px;
}

@media (max-width: 767.98px) {
  .player-info-title.h1 {
    font-size: 2.75rem;
  }
}

@media (min-width: 992px) {
  .player-info-title.h1 {
    margin-bottom: 38px;
  }
}

@media (min-width: 992px) and (max-width: 1439.98px) {
  .player-info-title.h1 {
    font-size: 5.125rem;
  }
}

@media (min-width: 1440px) {
  .player-info-title.h1 {
    margin-bottom: 58px;
  }
}

@media (min-width: 1920px) {
  .player-info-title.h1 {
    margin-bottom: 76px;
    font-size: 9.125rem;
  }
}

.player-info-detail__link, .player-info-detail__label, .player-info-detail__title {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -.03em;
  line-height: 1;
}

.player-info-detail__link, .player-info-detail__label {
  color: #5e627e;
}

.player-info-detail__link {
  display: inline-block;
  outline: none;
}

.player-info-detail__link:hover {
  color: #a3ff12;
}

.player-info-detail__title {
  font-size: 1rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .player-info-detail__title {
    font-size: 1.125rem;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__title {
    font-size: 1.75rem;
  }
}

.player-info-detail__icon {
  display: block;
  float: left;
  margin-right: 18px;
}

@media (min-width: 1920px) {
  .player-info-detail__icon {
    margin-right: 26px;
  }
}

.player-info-detail__icon svg {
  width: 24px;
  height: 24px;
}

@media (min-width: 1920px) {
  .player-info-detail__icon svg {
    width: 30px;
    height: 30px;
  }
}

.player-info-detail__icon--lg {
  padding-top: 2px;
}

@media (max-width: 767.98px) {
  .col-6 .player-info-detail__icon--lg {
    float: none;
    margin-bottom: 2px;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__icon--lg {
    padding-top: 4px;
  }
}

.player-info-detail__icon--lg svg {
  width: 32px;
  height: 35px;
}

@media (min-width: 768px) {
  .player-info-detail__icon--lg svg {
    width: 44px;
    height: 50px;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__icon--lg svg {
    width: 67px;
    height: 70px;
  }
}

.player-info-detail__value {
  display: block;
  float: left;
  margin-right: 12px;
  color: #a3ff12;
}

@media (min-width: 375px) {
  .player-info-detail__value {
    margin-right: 18px;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__value {
    margin-right: 26px;
  }
}

.player-info-detail__value.h3 {
  margin-bottom: 0;
  font-size: 2.5rem;
  letter-spacing: -.03em;
}

@media (min-width: 375px) {
  .player-info-detail__value.h3 {
    font-size: 2.875rem;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__value.h3 {
    font-size: 4.375rem;
  }
}

.player-info-detail__bar {
  position: relative;
  display: block;
  float: left;
  margin-top: -2px;
  margin-right: 12px;
}

@media (min-width: 375px) {
  .player-info-detail__bar {
    margin-right: 18px;
  }
}

@media (min-width: 768px) {
  .player-info-detail__bar {
    margin-top: -8px;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__bar {
    margin-top: -14px;
    margin-right: 26px;
  }
}

.player-info-detail__bar svg {
  display: block;
  width: 40px;
  height: 40px;
  transform: rotate(90deg);
}

@media (min-width: 768px) {
  .player-info-detail__bar svg {
    width: 52px;
    height: 52px;
  }
}

@media (min-width: 1920px) {
  .player-info-detail__bar svg {
    width: 80px;
    height: 80px;
  }
}

.player-info-detail__bar svg path {
  stroke: #5e627e;
}

.player-info-detail__bar svg path + path {
  stroke: #a3ff12;
}

.player-info-detail__bar i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: .5rem;
  transform: translate(-40%, -65%);
}

@media (min-width: 768px) {
  .player-info-detail__bar i {
    font-size: .5625rem;
    transform: translate(-35%, -65%);
  }
}

@media (min-width: 1920px) {
  .player-info-detail__bar i {
    font-size: .9375rem;
  }
}

.player-info-detail__hero {
  position: relative;
  display: block;
  width: 52px;
  height: 52px;
  margin-top: -8px;
  margin-right: 14px;
  float: left;
}

.player-info-detail__hero img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.player-info-detail__body {
  overflow: hidden;
}

.player-info-detail__label + * {
  margin-top: 5px;
}

@media (min-width: 1920px) {
  .player-info-detail__label + * {
    margin-top: 6px;
  }
}

.player-info-detail__title + .player-info-detail__label {
  margin-top: 4px;
}

* + .row .player-info-detail {
  margin-bottom: 24px;
}

* + .row .player-info-detail--icon {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  * + .row:not(:last-child) .player-info-detail,
  .team-carousel * + .row .player-info-detail {
    margin-bottom: 39px;
  }
}

@media (min-width: 1440px) {
  * + .row:not(:last-child) .player-info-detail,
  .team-carousel * + .row .player-info-detail {
    margin-bottom: 49px;
  }
}

@media (min-width: 1920px) {
  * + .row:not(:last-child) .player-info-detail,
  .team-carousel * + .row .player-info-detail {
    margin-bottom: 69px;
  }
}

@media (min-width: 768px) {
  * + .row:not(:last-child) .player-info-detail--icon,
  .team-carousel * + .row .player-info-detail--icon {
    margin-bottom: 35px;
  }
}

@media (min-width: 1440px) {
  * + .row:not(:last-child) .player-info-detail--icon,
  .team-carousel * + .row .player-info-detail--icon {
    margin-bottom: 45px;
  }
}

@media (min-width: 1920px) {
  * + .row:not(:last-child) .player-info-detail--icon,
  .team-carousel * + .row .player-info-detail--icon {
    margin-bottom: 66px;
  }
}

.player-info-detail p {
  color: #c6cbea;
}

.player-info-carousel {
  position: relative;
  margin-bottom: 4px;
}

@media (max-width: 1439.98px) {
  .player-info-carousel {
    padding-right: 86px;
  }
}

.player-info-carousel .slick-arrow {
  position: absolute;
  top: 4px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 0;
  outline: 0;
  z-index: 1;
  transition: opacity 300ms ease;
  cursor: none;
}

body:not(.cursor-is--active) .player-info-carousel .slick-arrow {
  cursor: pointer;
}

.player-info-carousel .slick-arrow:hover {
  opacity: 1;
}

.player-info-carousel .slick-arrow svg {
  width: 18px;
  height: 10px;
  fill: #fff;
}

@media (min-width: 1920px) {
  .player-info-carousel .slick-arrow svg {
    height: 20px;
  }
}

.player-info-carousel .slick-arrow:hover svg {
  fill: #a3ff12;
}

.player-info-carousel .slick-prev {
  right: 66px;
}

@media (min-width: 1440px) {
  .col-xl-8 .player-info-carousel .slick-prev {
    right: auto;
    left: calc(100% + 25px);
  }
}

@media (min-width: 1920px) {
  .col-xl-8 .player-info-carousel .slick-prev {
    left: calc(100% + 55px);
  }
}

.player-info-carousel .slick-next {
  right: 0;
}

@media (min-width: 1440px) {
  .col-xl-8 .player-info-carousel .slick-next {
    right: auto;
    left: calc(100% + 91px);
  }
}

@media (min-width: 1920px) {
  .col-xl-8 .player-info-carousel .slick-next {
    left: calc(100% + 141px);
  }
}

.player-info-carousel .slick-custom-pagination {
  display: block;
  position: absolute;
  top: 1px;
  right: 30px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1;
}

@media (min-width: 1920px) {
  .player-info-carousel .slick-custom-pagination {
    top: 4px;
    font-size: 1.25rem;
  }
}

@media (min-width: 1440px) {
  .col-xl-8 .player-info-carousel .slick-custom-pagination {
    right: auto;
    left: calc(100% + 67px);
    transform: translateX(-50%);
  }
}

@media (min-width: 1920px) {
  .col-xl-8 .player-info-carousel .slick-custom-pagination {
    left: calc(100% + 108px);
  }
}

.header-player-info-navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
}

@media (min-width: 1920px) {
  .header-player-info-navigation {
    width: 78px;
  }
}

.header-player-info-navigation a {
  margin-top: 4px;
  display: block;
  line-height: 1;
}

.header-player-info-navigation a.disable svg {
  fill: rgba(94, 98, 126, 0.4);
}

.header-player-info-navigation svg {
  width: 7px;
  height: 14px;
  fill: #fff;
}

@media (min-width: 1920px) {
  .header-player-info-navigation svg {
    width: 11px;
    height: 22px;
  }
}

.header-player-info-navigation__card {
  position: absolute;
  left: -26px;
  display: flex;
  align-items: center;
  width: 260px;
  height: 72px;
  background-color: #151720;
  opacity: 0;
  visibility: hidden;
  transition: all 0s ease-in-out;
}

@media (min-width: 992px) {
  .header-player-info-navigation__card {
    left: -40px;
  }
}

@media (min-width: 1920px) {
  .header-player-info-navigation__card {
    left: -60px;
    width: 320px;
    height: 104px;
  }
}

.site-header--top .header-player-info-navigation__card {
  top: calc(100% + 2px);
  transform: translateY(-1rem);
}

.site-header--bottom .header-player-info-navigation__card {
  bottom: calc(100% + 2px);
  transform: translateY(1rem);
}

.header-player-info-navigation a:hover .header-player-info-navigation__card {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .3s;
}

.header-player-info-navigation__avatar {
  height: 100%;
}

.header-player-info-navigation__body {
  padding-left: 12px;
  font-weight: bold;
  letter-spacing: -.03em;
  color: #fff;
}

.header-player-info-navigation__name {
  font-size: .625rem;
  text-transform: uppercase;
}

.header-player-info-navigation__nickname {
  font-size: 1rem;
}

@media (min-width: 1920px) {
  .header-player-info-navigation__nickname {
    font-size: 1.125rem;
  }
}

.header-player-info-navigation img {
  height: 100%;
}

.touch .team-selection-item * {
  -webkit-transform: translateZ(0px);
  will-change: transform;
}

.team-selection-item__title, .team-selection-item__subtitle {
  letter-spacing: -.03em;
  color: #fff;
}

.team-selection-item__subtitle {
  display: block;
  padding-top: 5px;
  margin-bottom: 5px;
  font-size: .875rem;
  color: #5e627e;
  transition: color 300ms ease;
}

.team-selection-item--style-1 .team-selection-item__subtitle {
  padding-top: 5px;
}

@media (min-width: 1920px) {
  .team-selection-item--style-1 .team-selection-item__subtitle {
    padding-top: 24px;
  }
}

.team-selection-item--style-2 .team-selection-item__subtitle {
  padding-top: 2px;
}

@media (min-width: 1920px) {
  .team-selection-item--style-2 .team-selection-item__subtitle {
    padding-top: 10px;
  }
}

@media (min-width: 1920px) {
  .team-selection-item__subtitle {
    margin-bottom: 8px;
    font-size: 1.25rem;
  }
}

.team-selection-item__thumbnail:hover ~ .team-selection-item__subtitle {
  color: #a3ff12;
}

.team-selection-item__title {
  font-size: 1.625rem;
}

@media (min-width: 1920px) {
  .team-selection-item__title {
    font-size: 2.25rem;
  }
}

.team-selection-item__thumbnail {
  display: block;
  position: relative;
  width: 70%;
  padding: 0;
  outline: 0;
  margin-top: 26%;
  margin-bottom: 21%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transform: rotate(45deg);
  color: #fff;
  background-color: #151720;
  transition: all 300ms ease;
}

.touch .team-selection-item__thumbnail {
  -webkit-transform: translateZ(0px) rotate(45deg);
  will-change: transform;
}

.team-selection-item--style-1 .team-selection-item__thumbnail {
  padding-bottom: calc(70% - 16px);
  border: 8px solid #3d4055;
}

@media (min-width: 1920px) {
  .team-selection-item--style-1 .team-selection-item__thumbnail {
    padding-bottom: calc(70% - 30px);
    border-width: 15px;
  }
}

.team-selection-item--style-2 .team-selection-item__thumbnail {
  padding-bottom: 70%;
  border: none;
}

.team-selection-item--style-2 .team-selection-item__thumbnail:after {
  content: "";
  position: absolute;
  border: 8px solid #3d4055;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: opacity 300ms ease;
}

@media (min-width: 1920px) {
  .team-selection-item--style-2 .team-selection-item__thumbnail:after {
    border-width: 15px;
  }
}

.team-selection-item__thumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 23, 32, 0.4);
  z-index: 3;
}

.team-selection-item__thumbnail-inner {
  position: absolute;
}

.team-selection-item--style-1 .team-selection-item__thumbnail-inner {
  top: calc(-25% - 2px);
  left: calc(-25% - 2px);
  width: calc(100% / .7 + 16px);
  width: calc(100% / .7 + 16px);
}

.team-selection-item--style-2 .team-selection-item__thumbnail-inner {
  top: -22%;
  left: -22%;
  width: calc(100% / .7);
  height: calc(100% / .7);
}

.team-selection-item__thumbnail-inner:after, .team-selection-item__thumbnail-inner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.team-selection-item__thumbnail-inner:before {
  z-index: 1;
  background-image: linear-gradient(to bottom, transparent 0%, #151720 100%);
  opacity: .6;
}

.team-selection-item__thumbnail-inner:after {
  z-index: 2;
  filter: contrast(2);
  background-color: #6b7199;
  opacity: .35;
  transition: opacity 300ms ease;
}

.team-selection-item__thumbnail-inner img {
  transform: rotate(-45deg);
}

.touch .team-selection-item__thumbnail-inner img {
  -webkit-transform: translateZ(0px) rotate(-45deg);
  will-change: transform;
}

.team-selection-item__thumbnail-inner img:first-child {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}

.team-selection-item--style-2 .team-selection-item__thumbnail-inner img:last-of-type {
  position: absolute;
  bottom: -1%;
  left: 1%;
  width: 100%;
  height: 100%;
  z-index: 5;
}

@media (min-width: 1920px) {
  .team-selection-item--style-2 .team-selection-item__thumbnail-inner img:last-of-type {
    bottom: 1%;
    left: 2.75%;
    width: calc(100% - 14px);
    height: calc(100% - 14px);
  }
}

.team-selection-item__thumbnail:hover {
  position: relative;
  z-index: 1;
  transform: rotate(45deg) translate(-8px, -8px);
}

.touch .team-selection-item__thumbnail:hover {
  -webkit-transform: translateZ(0px) rotate(45deg) translate(-8px, -8px);
  will-change: transform;
}

.team-selection-item--style-1 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2 .team-selection-item__thumbnail:hover {
  box-shadow: 0px 0px 60px 4px rgba(163, 255, 18, 0.2);
}

.team-selection-item--style-1 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2 .team-selection-item__thumbnail:hover:after {
  border-color: #a3ff12;
}

.team-selection-item--style-1.team-1 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-1 .team-selection-item__thumbnail:hover {
  box-shadow: 0px 0px 60px 4px rgba(25, 122, 255, 0.2);
}

.team-selection-item--style-1.team-1 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-1 .team-selection-item__thumbnail:hover:after {
  border-color: #197aff;
}

.team-selection-item--style-1.team-2 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-2 .team-selection-item__thumbnail:hover {
  box-shadow: 0px 0px 60px 4px rgba(93, 38, 251, 0.2);
}

.team-selection-item--style-1.team-2 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-2 .team-selection-item__thumbnail:hover:after {
  border-color: #5d26fb;
}

.team-selection-item--style-1.team-3 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-3 .team-selection-item__thumbnail:hover {
  box-shadow: 0px 0px 60px 4px rgba(255, 222, 0, 0.2);
}

.team-selection-item--style-1.team-3 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-3 .team-selection-item__thumbnail:hover:after {
  border-color: #ffde00;
}

.team-selection-item--style-1.team-4 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-4 .team-selection-item__thumbnail:hover {
  box-shadow: 0px 0px 60px 4px rgba(255, 49, 28, 0.2);
}

.team-selection-item--style-1.team-4 .team-selection-item__thumbnail:hover,
.team-selection-item--style-2.team-4 .team-selection-item__thumbnail:hover:after {
  border-color: #ff311c;
}

.team-selection-item__thumbnail:hover img {
  filter: grayscale(0);
}

.team-selection-item__thumbnail:hover .team-selection-item__thumbnail-inner:after {
  opacity: 0;
}

.team-selection-item__logo {
  z-index: 4;
  height: 70px;
}

@media (min-width: 1920px) {
  .team-selection-item__logo {
    width: 100px;
    height: 100px;
  }
}

.team-selection-item__logo--middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(-45deg) translateY(calc(-75% + 4px));
}

.touch .team-selection-item__logo--middle {
  -webkit-transform: translateZ(0px) rotate(-45deg) translateY(calc(-75% + 4px));
  will-change: transform;
}

.team-selection-item__logo--bottom {
  position: relative;
  display: block;
  width: 25.926%;
  margin: -28% auto 10px;
  transition: transform 300ms ease;
  height: auto;
}

.team-selection-item__thumbnail:hover ~ .team-selection-item__logo--bottom {
  transform: translateY(-12px);
}

.touch .team-selection-item__thumbnail:hover ~ .team-selection-item__logo--bottom {
  -webkit-transform: translateZ(0px) translateY(-12px);
  will-change: transform;
}
