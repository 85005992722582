//- Matches Table
//- ----------------------------------------------

.matches-table {
	margin-bottom: 0;
	font-weight: $font-weight-bold;
	line-height: 1;
	letter-spacing: -.03em;
	text-transform: uppercase;
	color: theme-color("light");

	tbody {
		& > tr:first-child td {
			border-top: none;
		}

		tr {
			&:nth-child(odd) {
				background-color: theme-color("dark");
			}

			&:nth-child(even) {
				background-color: #13151e;
			}
		}

		td {
			vertical-align: middle;
			border-color: rgba(theme-color("secondary"), .2);
		}
	}
}