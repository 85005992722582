//- Comments
//- ----------------------------------------------

.post-comments {
	&__title {
		margin-bottom: 75px;

		@media (min-width: $xxl-breakpoint) {
			margin-bottom: 93px;
		}
	}

	.post + & {
		margin-top: 90px;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 105px;
		}
	}
}

.comments,
.comments .children {
	@include list-unstyled;
}

.comment {
	&__avatar {
		float: left;
		width: 60px;
		margin-right: 24px;

		@media (min-width: $xxl-breakpoint) {
			width: 80px;
			margin-right: 40px;
		}

		img {
			width: 100%;
		}
	}

	&__body {
		overflow: hidden;

		p {
			margin-bottom: 14px;
		}
	}

	&__author {
		margin-bottom: 13px;
		font-size: .875rem;

		@media (min-width: $xxl-breakpoint) {
			margin-top: -3px;
			margin-bottom: 19px;
			font-size: 1.25rem;
		}
	}

	&__meta {
		& > * {
			display: inline-block;

			& + * {
				margin-left: 22px;

				@media (min-width: $xxl-breakpoint) {
					margin-left: 36px;
				}
			}
		}
	}

	&__date,
	&__reply a {
		font-size: .625rem;
		font-weight: 700;
		line-height: 1;
		letter-spacing: -.03em;
		text-transform: uppercase;

		@media (min-width: $xxl-breakpoint) {
			font-size: .875rem;
		}
	}

	&__reply a {
		color: theme-color("success");
		transition: color 300ms ease;

		&:hover {
			color: $body-color;
		}
	}

	.children & {
		padding-left: 28px;
	}

	& + &,
	.children & {
		margin-top: 50px;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 70px;
		}
	}
}

// Post Comments Form
.post-comments-form {
	margin-top: 90px;

	@media (min-width: $xxl-breakpoint) {
		margin-top: 105px;
	}

	&__title {
		margin-bottom: 68px;

		@media (min-width: $xxl-breakpoint) {
			margin-bottom: 82px;
		}
	}
}