.gm-map {
	&--aside {
		display: block;
		width: calc(100% + 48px);
		height: 400px;
		margin: 60px -24px -60px;

		@include media-breakpoint-up(lg) {
			position: fixed !important;
			left: 0;
			width: calc(100% - 606px);
			height: calc(100vh - #{$header-height-desktop-mobile});
			margin: 0;

			.site-header--top ~ .site-content & {
				bottom: 0;
			}

			.site-header--bottom ~ .site-content & {
				top: 0;
			}
		}

		@media (min-width: 1440px) {
			width: calc(100% - 860px);
		}
	}

	.gm-style {
		br {
			background-color: transparent;
		}

		.gm-style-iw-t {
			&:after,
			&:before {
				display: none;
			}
		}

		.gm-style-iw-c {
			padding: 13px 22px 12px!important;
			border-radius: 0;
			box-shadow: none;
			background-color: theme-color("dark");
		}

		.gm-ui-hover-effect {
			opacity: .3;
			transition: opacity 300ms ease;

			img {
				display: none!important;
			}

			&:after,
			&:before {
				content: "";
				position: absolute;
				top: 14px;
				right: 12px;
				width: 8px;
				height: 2px;
				background-color: theme-color("light");
			}

			&:after {
				transform: rotate(45deg);
			}

			&:before {
				transform: rotate(-45deg);
			}

			&:hover {
				opacity: 1;
			}
		}

		.gm-style-iw-d {
			overflow: hidden!important;
			font-family: $font-family-base;
			font-weight: $font-weight-bold;
			line-height: .75rem;
			font-size: .875rem;
			letter-spacing: -.03em;
			text-transform: uppercase;
			text-align: center;
			color: theme-color("light");

			address {
				display: block;
				margin-bottom: 0;
				padding-top: 7px;
				font-size: .75rem;
				color: theme-color("secondary");
			}
		}
	}
}