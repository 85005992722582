//- Match Result
//- ----------------------------------------------

.match-result {
	font-weight: $font-weight-bold;
	letter-spacing: -.03em;
	text-align: center;
	text-transform: uppercase;

	.match-score & {
		flex: 2 0 auto;
		padding-top: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.match-score-inline & {
		margin-top: 4px;
		font-size: .75rem;

		&:nth-child(2) {
			margin-right: 14px;
		}

		&:last-child {
			min-width: 30px;
		}
	}

	.match-stats-widget & {
		padding-bottom: 2px;
	}

	&__score {
		display: block;
		line-height: 1;
		color: theme-color("light");

		.match-score & {
			font-size: 1rem;

			@include media-breakpoint-up(lg) {
				font-size: 1.125rem;
			}

			@media (min-width: $xxl-breakpoint) {
				font-size: 1.75rem;
			}
		}

		.match-overview & {
			font-size: 2rem;

			@include media-breakpoint-up(sm) {
				font-size: 3rem;
			}

			@include media-breakpoint-up(md) {
				font-size: 3.75rem;
			}

			@include media-breakpoint-up(lg) {
				font-size: 4.5rem;
			}

			@media (min-width: $xxl-breakpoint) {
				font-size: 5.875rem;
			}
		}

		.match-stats-widget & {
			font-size: .875rem;
			margin-bottom: 11px;
		}

		.match-result--detailed & {
			display: inline-block;
			margin-left: 2px;
			margin-right: 2px;
		}

		.match-result--winner-left &,
		.match-result--winner-right & {
			position: relative;
			display: inline-block;

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 0;

				.match-score &,
				.match-score-inline &,
				.match-stats-widget & {
					border-top: 3px solid transparent;
					border-bottom: 3px solid transparent;

					@media (min-width: $xxl-breakpoint) {
						border-top-width: 4px;
						border-bottom-width: 4px;
					}
				}

				.match-score & {
					top: 4px;

					@include media-breakpoint-up(lg) {
						top: 5px;
					}

					@media (min-width: $xxl-breakpoint) {
						top: 9px;
					}
				}

				.match-score-inline & {
					top: 2px;
				}

				.match-stats-widget & {
					top: 3px;
				}

				.match-overview & {
					top: 10px;
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;

					@include media-breakpoint-up(sm) {
						top: 16px;
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
					}

					@include media-breakpoint-up(md) {
						top: 19px;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
					}

					@include media-breakpoint-up(lg) {
						top: 23px;
						border-top: 10px solid transparent;
						border-bottom: 10px solid transparent;
					}

					@media (min-width: $xxl-breakpoint) {
						top: 30px;
						border-top-width: 13px;
						border-bottom-width: 13px;
					}
				}
			}
		}

		.match-result--winner-left & {
			&:before {
				.match-score &,
				.match-score-inline &,
				.match-stats-widget & {
					border-left: 4px solid theme-color("primary");

					@media (min-width: $xxl-breakpoint) {
						border-left-width: 6px;
					}
				}

				.match-score &,
				.match-score-inline &, {
					left: -13px;

					@media (min-width: $xxl-breakpoint) {
						left: -18px;
					}
				}

				.match-stats-widget & {
					left: -12px;
				}

				.match-overview & {
					left: -16px;
					border-left: 6px solid theme-color("primary");

					@include media-breakpoint-up(sm) {
						left: -20px;
						border-left: 8px solid theme-color("primary");
					}

					@include media-breakpoint-up(md) {
						left: -22px;
						border-left: 10px solid theme-color("primary");
					}

					@include media-breakpoint-up(lg) {
						left: -35px;
						border-left: 14px solid theme-color("primary");
					}

					@media (min-width: $xxl-breakpoint) {
						left: -54px;
						border-left-width: 18px;
					}
				}
			}
		}

		.match-result--winner-right & {
			&:before {
				.match-score &,
				.match-score-inline &,
				.match-stats-widget & {
					border-right: 4px solid theme-color("primary");

					@media (min-width: $xxl-breakpoint) {
						border-right-width: 6px;
					}
				}

				.match-score &,
				.match-score-inline & {
					right: -13px;

					@media (min-width: $xxl-breakpoint) {
						right: -18px;
					}
				}

				.match-stats-widget & {
					right: -12px;
				}

				.match-overview & {
					right: -16px;
					border-right: 6px solid theme-color("primary");

					@include media-breakpoint-up(sm) {
						right: -20px;
						border-right: 8px solid theme-color("primary");
					}

					@include media-breakpoint-up(md) {
						right: -22px;
						border-right: 10px solid theme-color("primary");
					}

					@include media-breakpoint-up(lg) {
						right: -35px;
						border-right: 14px solid theme-color("primary");
					}

					@media (min-width: $xxl-breakpoint) {
						right: -54px;
						border-right-width: 18px;
					}
				}
			}
		}

		&--sm {
			font-size: .625rem;

			.team-cyan {
				color: $msw-cyan-color;
			}

			.team-yellow {
				color: $msw-yellow-color;
			}
		}
	}

	&__label {
		display: inline-block;
		color: theme-color("secondary");

		.match-score & {
			margin-top: 6px;
			font-size: .625rem;

			@include media-breakpoint-up(sm) {
				font-size: .75rem;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-top: 5px;
				font-size: 1.25rem;
			}
		}

		.match-score-inline__body-inner:first-child & {
			color: theme-color("light");
		}

		.match-overview & {
			font-size: .6875rem;

			@include media-breakpoint-down(sm) {
				margin-top: 4px;
				line-height: 1;
			}

			@include media-breakpoint-up(sm) {
				margin-top: 6px;
				font-size: .75rem;
			}

			@include media-breakpoint-up(md) {
				font-size: .875rem;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 10px;
				font-size: 1rem;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-top: 16px;
				font-size: 1.5rem;
			}
		}

		.match-result--live & {
			position: relative;
			width: auto;
			color: theme-color("light");

			&:before {
				content: "";
				position: absolute;
				top: 3px;
				right: calc(100% + 6px);
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: theme-color("danger");

				@include media-breakpoint-up(sm) {
					width: 4px;
					height: 4px;
				}

				@media (min-width: $xxl-breakpoint) {
					top: 7px;
					width: 6px;
					height: 6px;

					// to-do: temporary fix before full responsiveness
					.top-bar-carousel & {
						top: 2px;
					}
				}

				.match-overview & {
					@include media-breakpoint-up(sm) {
						width: 4px;
						height: 4px;
					}

					@include media-breakpoint-up(md) {
						top: 9px;
						right: calc(100% + 10px);
						width: 5px;
						height: 5px;
					}

					@include media-breakpoint-up(lg) {
						top: 10px;
						width: 6px;
						height: 6px;
					}

					@media (min-width: $xxl-breakpoint) {
						top: 16px;
						right: calc(100% + 16px);
					}
				}
			}
		}

		.match-result--winner-left &,
		.match-result--winner-right & {
			display: block;
		}
	}
}
