//- Logo
//- ----------------------------------------------

// Header Logo
.header-logo {
	&--img {
		z-index: 1;
		transition: .3s all ease;

		.site-wrapper--has-menu-overlay & {
			opacity: 0;
		}

		.site-layout--landing & {
			opacity: 1;
			transition: .7s .6s all cubic-bezier(0.86, 0, 0.07, 1);
		}

		.preloader-is--active .site-layout--landing & {
			opacity: 0;
			transform: translateY(-50%) scale(1.5);
		}

		.site-header--top &,
		.site-header--bottom & {
			position: absolute;
			left: 28px;

			@include media-breakpoint-down(md) {
				max-width: 90px;
				left: 20px;

				img {
					max-width: 100%;
					height: auto;
				}
			}

			@media (min-width: $xxl-breakpoint) {
				left: 46px;
			}
		}

		.site-header--top & {
			top: 12px;

			@media (min-width: $xxl-breakpoint) {
				top: 20px;
			}
		}

		.site-header--bottom & {
			bottom: 12px;

			@include media-breakpoint-down(md) {
				bottom: 6px;
			}

			@media (min-width: $xxl-breakpoint) {
				bottom: 20px;
			}

			html.touch & {
				@include media-breakpoint-down(md) {
					top: 6px;
					bottom: auto;
				}
			}
		}

		.site-header--landing & {
			z-index: 3;
			padding-left: 48px;
			padding-right: 48px;

			@include media-breakpoint-up(lg) {
				padding-left: 86px;
				padding-right: 86px;
			}
		}

		img {
			max-width: 140px;

			@media (min-width: 992px) and (max-width: 1919px) {
				.site-header--landing & {
					max-width: 18.229167vh;
				}
			}

			@media (min-width: $xxl-breakpoint) {
				max-width: 200px;
			}
		}
	}
}

