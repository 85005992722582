//- Checkout page
//- ----------------------------------------------

.checkout-page {
	background-color: theme-color("light");

	& > .row > * {

		@include media-breakpoint-up(lg) {
			min-height: calc(100vh - #{$header-height-desktop-mobile});
		}
		
		@media (min-width: $xxl-breakpoint) {
			min-height: calc(100vh - #{$header-height-desktop});
		}
	}

	& > .row > *:nth-child(odd) {
		padding: 50px 34px;

		@include media-breakpoint-up(md) {
			padding: 80px;
		}
	}

	& > .row > *:nth-child(even) {
		padding: 48px 34px 94px;
		background-color: #f7f8fa;

		@include media-breakpoint-up(lg) {
			padding: 38px 40px;
		}

		@media (min-width: $xxl-breakpoint) {
			padding: 58px 60px;
		}
	}

	.h4 + form {
		margin-top: 38px;

		@include media-breakpoint-up(md) {
			margin-top: 68px;
		}
	}

	* + .h4 {
		margin-top: 33px;

		@include media-breakpoint-up(md) {
			margin-top: 63px;
		}
	}
}

//
// Checkout Login & Redeem
//

.checkout-login,
.checkout-redeem,
.checkout-redeem-popup__header {
	display: block;
	font-weight: $font-weight-bold;
	letter-spacing: -.03em;
	text-transform: uppercase;
	text-align: center;
	color: theme-color("light");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.checkout-login,
.checkout-redeem,
.checkout-redeem-popup {
	&__tiny {
		display: inline-block;
		padding: 4px 6px 3px;
		font-size: .625rem;
		line-height: 1;
		color: $body-color;
	}
}

.checkout-login,
.checkout-redeem {
	margin-bottom: 30px;
	padding: 36px 18px 40px;

	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}

	&:hover {
		color: theme-color("light");
	}

	&__title {
		margin-bottom: 2px;
		font-size: 2.75rem;
		line-height: 1;
	}

	&__subtitle {
		line-height: 1;
	}

	&__tiny {
		transition: transform 400ms ease;
	}

	&:hover {
		.checkout-login__tiny,
		.checkout-redeem__tiny {
			transform: translateY(4px);
		}
	}
}

.checkout-login {
	&__title {
		span {
			color: theme-color("primary");
		}
	}

	&__subtitle {

	}

	&__tiny {
		background-color: theme-color("primary");
	}

	@include media-breakpoint-up(lg) {
		margin-right: 8px;
	}
}

.checkout-redeem,
.checkout-redeem-popup {
	&__title {
		span {
			color: #fff60d;
		}
	}

	&__tiny {
		background-color: #fff60d;
	}

	
}

.checkout-redeem {
	@include media-breakpoint-up(lg) {
		margin-left: 8px;
	}
}

//
// Checkour Redeem PopUp
//

.checkout-redeem-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 290px;
	transform: translate(-50%, -40%);
	background-color: theme-color("light");
	visibility: hidden;
	opacity: 0;
	transition-duration: 0;
	z-index: $zindex-redeem;

	@include media-breakpoint-up(sm) {
		width: 370px;
	}

	&__header {
		padding: 38px 30px;
		font-weight: $font-weight-bold;
		letter-spacing: -.03em;
		text-transform: uppercase;
		text-align: center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__title {
		margin-bottom: 5px;
		color: theme-color("light");
	}

	&__body {
		padding: 32px;
	}

	.site-wrapper--has-redeem-overlay & {
		visibility: visible;
		opacity: 1;
		transform: translate(-50%, -50%);
		transition: opacity ease 320ms, transform ease 800ms;
	}

	.form-group {
		margin-bottom: 26px;
	}
}

// Payment Methods
.checkout-payment-methods {
	@include list-unstyled;
	margin-top: 70px;
}

.checkout-payment-method {
	padding-bottom: 24px;
	border-bottom: 1px solid $input-border-color;

	button {
		padding: 0;
		outline: 0;
		border: none;
		background-color: transparent;
	}

	.checkbox {
		margin-bottom: 4px;
	}

	&__body {
		margin-top: 16px;
	}

	& + & {
		margin-top: 28px;
	}
}

// Checkout Order
.checkout-order {
	&__title {
		margin-bottom: 35px;
		font-size: 1.5rem;

		@media (min-width: $xxl-breakpoint) {
			margin-bottom: 54px;
			font-size: 2.125rem;
		}
	}

	.product {
		// Product Info
		&__info {
			width: 85%;
			padding: 18px 15px 18px 0;

			@media (min-width: $xxl-breakpoint) {
				padding-top: 26px;
				padding-bottom: 26px;
			}

			&-content {
				overflow: hidden;
				padding-left: 15px;

				@media (min-width: $xxl-breakpoint) {
					padding-left: 22px;
				}
			}
		}

		&__thumb {
			float: left;
			width: 44px;
			margin-bottom: 0;

			img {
				width: 100%;
			}

			@media (min-width: $xxl-breakpoint) {
				width: 60px;
			}
		}

		&__name {
			margin-top: 2px;
			margin-bottom: 1px;
			font-size: .875rem;
			line-height: 1;
			letter-spacing: -.03em;
			text-transform: uppercase;

			@media (min-width: $xxl-breakpoint) {
				font-size: 1rem;
			}

			a {
				color: $body-color;

				&:hover {
					color: theme-color("primary");
				}
			}
		}

		&__cat,
		&__info-details {
			display: block;
			font-size: .625rem;
			font-weight: 700;
			line-height: 1;
			letter-spacing: -.03em;
			text-transform: uppercase;

			@media (min-width: $xxl-breakpoint) {
				font-size: .875rem;
			}
		}

		&__cat,
		&__info-details {
			color: theme-color("secondary");
		}

		&__cat {
			@media (min-width: $xxl-breakpoint) {
				margin-top: 2px;
			}
		}

		&__info-details {
			margin-top: 1px;

			@media (min-width: $xxl-breakpoint) {
				margin-top: 3px;
			}
		}

		// Product Total
		&__total:not(th) {
			font-size: .875rem;
			font-weight: 700;
			line-height: 1;
			letter-spacing: -.03em;
		}

		&__total {
			padding: 20px 0 0;
			text-align: right;

			@media (min-width: $xxl-breakpoint) {
				padding-top: 29px;
			}
		}
	}

	table td {
		border-color: #e7e9ed;
	}

	tr:last-child {
		td {
			border-bottom: $table-border-width solid $table-border-color;
		}
	}

	.shop-table {
		margin-bottom: 26px;

		@media (min-width: $xxl-breakpoint) {
			margin-bottom: 33px;
		}
	}

	// Cart Totals
	&__totals {
		text-align: right;

		.table {
			margin-bottom: 32px;
		}

		th, td {
			padding: 0;
			border: none;
		}

		tr:last-child td {
			border: none;
		}
	}
	
	&__taxes,
	&__promo,
	&__subtotal,
	&__shipping,
	&__order-total {
		font-weight: 700;
		text-transform: uppercase;
	}
	
	&__taxes,
	&__promo,
	&__subtotal,
	&__shipping {
		font-size: .625rem;
		color: theme-color("secondary");

		@media (min-width: $xxl-breakpoint) {
			font-size: .875rem;
		}
	}
	
	&__taxes,
	&__promo,
	&__shipping {
		th, td {
			padding-top: 8px;

			@media (min-width: $xxl-breakpoint) {
				padding-top: 11px;
			}
		}
	}

	&__promo {
		.amount {
			color: theme-color("success");
		}
	}

	&__order-total {
		font-size: .875rem;

		@media (min-width: $xxl-breakpoint) {
			font-size: 1.125rem;
		}

		th, td {
			padding-top: 5px;

			@media (min-width: $xxl-breakpoint) {
				padding-top: 10px;
			}
		}
	}

	&__button {
		margin-top: 42px;
		letter-spacing: -.03em;
		color: theme-color("light");
		background-color: $body-color;

		@include media-breakpoint-up(md) {
			margin-top: 72px;
		}

		@media (min-width: $xxl-breakpoint) {
			margin-top: 70px;
		}

		&:hover {
			background-color: lighten($body-color, 10%);
		}
	}
}