//- Menu Panel
//- ----------------------------------------------
.menu-panel {
	position: fixed;
	left: 0;
	z-index: $zindex-menu-panel;
	width: 100%;
	height: calc(100% - #{$header-height-desktop-mobile});
	background-color: $header-bg-color;
	visibility: hidden;
	opacity: 0;
	overflow: hidden;
	transition: visibility 0ms, opacity 320ms ease;
	transform: translate3d(0,0,0);

	@include media-breakpoint-up(md) {
		overflow-y: auto;
	}

	@media (min-width: $xxl-breakpoint) {
		height: calc(100% - #{$header-height-desktop});
	}

	.site-header--top ~ & {
		bottom: 0;
	}

	.site-header--bottom ~ & {
		top: 0;

		html.touch & {
			@include media-breakpoint-down(md) {
				top: $header-height-desktop-mobile;
				bottom: 0;
			}
		}
	}

	.site-wrapper--has-menu-overlay & {
		visibility: visible;
		opacity: 1;

		&__content {
			opacity: 1;
			transform: translateY(0);
			transition: opacity ease 320ms, transform ease 800ms;
		}
	}

	&__top-bar {
		color: theme-color("light");

		@include media-breakpoint-down(sm) {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $header-bg-color;
			transform: translate3d(100%, 0, 0);
			transition: transform ease 440ms 0ms;
			z-index: 1;

			&.toggled {
				transform: translate3d(0, 0, 0);
			}
		}

		@include media-breakpoint-up(md) {
			display: flex;
			width: 100%;
			height: 115px;
			border-bottom: 1px solid $nav-sub-border-color;
		}
	}

	&__top-bar,
	&__mobile-bar {
		@include media-breakpoint-down(sm) {
			height: calc(100vh - #{$header-height-desktop-mobile});
			overflow-y: auto;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		padding: 78px 72px;
		opacity: 0;
		transform: translateY(1rem);
		transition-duration: 0;

		.row + .row {
			margin-top: 3rem;

			@include media-breakpoint-up(lg) {
				margin-top: 80px;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-top: 100px;
			}
		}
	}

	&__navigation,
	&__widget-area {
		width: 50%;
		padding-left: 15px;
		padding-right: 15px;
	}

	&_widget-area {

	}
}


// Top Bar Filter
.top-bar-filter {
	padding-top: 20px;
	padding-bottom: 18px;
	border-right: 1px solid $nav-sub-border-color;

	@include media-breakpoint-down(sm) {
		width: 100%;
		height: auto;
		border-bottom: 1px solid $nav-sub-border-color;
	}

	@include media-breakpoint-up(md) {
		width: 181px;
		height: 100%;
		padding-top: 43px;
		padding-bottom: 43px;
	}

	&__label {
		display: block;
		margin-bottom: -3px;
		padding: 0 42px 0 24px;
		font-size: .625rem;
		font-weight: bold;
		line-height: 1;
		letter-spacing: -.03em;
		text-transform: uppercase;
		color: theme-color("secondary");
	}

	.cs-select {
		.cs-placeholder {
			display: block;
			padding: 0 42px 0 24px;
			font-size: .75rem;
			transition: color 300ms ease;

			&:after {
				right: 18px;
				top: 2px;

				@include media-breakpoint-up(md) {
					top: 4px;
				}
			}

			&:before {
				top: 0;
				right: 20px;

				@include media-breakpoint-up(md) {
					top: 2px;
				}
			}
		}

		.cs-options {
			width: calc(100% + 1px);
			margin-top: 23px;
			border: 1px solid $nav-sub-border-color;

			@include media-breakpoint-up(md) {
				margin-top: 46px;
			}
		}
	}
}

// Top Bar Carousel
.top-bar-carousel {
	@include list-unstyled;
	margin: 0;

	@include media-breakpoint-up(md) {
		flex-basis: calc(100% - 181px);
		max-width: calc(100% - 181px);
	}

	.slick-arrow + .slick-list {
		margin: 0 45px;
	}

	&__item {
		&:not(.top-bar-carousel__headline) {
			width: 261px;
			height: 114px;
			border-right: 1px solid $nav-sub-border-color;
			transition: .3s border-left ease;
		}

		@include media-breakpoint-down(sm) {
			width: 100%!important;
		}

		&:hover {
			@include media-breakpoint-down(sm) {
				border-left: 2px solid theme-color("primary");
			}
		}
	}

	& > li + li {
		@include media-breakpoint-down(sm) {
			border-top: 1px solid $nav-sub-border-color;
		}
	}

	& > li:last-child {
		@include media-breakpoint-down(sm) {
			border-bottom: 1px solid $nav-sub-border-color;
		}
	}

	&__headline {
		display: flex !important;
		align-items: center;
		padding-left: 18px;
		padding-right: 18px;
		font-size: .75rem;
		font-weight: $font-weight-bold;
		letter-spacing: -.03em;
		line-height: .875rem;
		text-transform: uppercase;
		text-align: center;

		@include media-breakpoint-down(sm) {
			width: 100%;
			padding-top: 18px;
			padding-bottom: 16px;
			border-left: 2px solid theme-color("primary");
		}

		@include media-breakpoint-up(md) {
			width: 141px;
			height: 114px;
			border-top: 2px solid theme-color("primary");
			border-right: 1px solid $nav-sub-border-color;
		}
	}

	.slick-arrow {
		position: absolute;
		top: 0;
		padding: 50px 18px;
		border: none;
		background-color: transparent;
		font-size: 0;
		outline: 0;
		text-align: center;

		body:not(.cursor-is--active) & {
			cursor: pointer;
		}

		svg {
			width: 8px;
			height: 14px;
			opacity: .4;
			fill: theme-color("light");
			transition: opacity 300ms ease;
		}

		&:hover {
			svg {
				opacity: 1;
			}
		}
	}

	.slick-prev {
		left: 0;
		border-right: 1px solid $nav-sub-border-color;
	}

	.slick-next {
		right: 0;
		border-left: 1px solid $nav-sub-border-color;
	}
}

// Mobile Bar
.menu-panel__mobile-bar {
	width: 100%;

}

.mobile-bar-item {
	color: theme-color("light");

	&__header {
		position: relative;
		display: block;
		padding: 17px 54px 16px 34px;
		font-size: .875rem;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		letter-spacing: -.03em;

		&,
		&:hover,
		&:focus {
			color: theme-color("light");
		}
	}

	&__icon {

	}

	&__body {
		display: block;
		background-color: #13151e;
		border-top: 1px solid $nav-sub-border-color;
	}

	& + & {
		border-top: 1px solid $nav-sub-border-color;
	}

	&:last-child {
		border-bottom: 1px solid $nav-sub-border-color;
	}

	&--info {
		.mobile-bar-item__inner {
			padding: 24px 54px 17px 34px;
		}

		.info-box__content {
			font-size: .75rem;
		}

		.info-box + .info-box {
			margin-top: 17px;
		}
	}

	&--partners {
		.mobile-bar-item__inner {
			padding: 41px 34px;
		}
	}
}

// Widget Partners Mobile Carousel
.widget-partners-mobile-carousel {
	padding-left: 0;

	.slick-arrow {
		position: absolute;
		top: 12px;
		width: 20px;
		padding: 2px 0;
		border: none;
		background-color: transparent;
		font-size: 0;
		outline: 0;
		z-index: 1;
		text-align: center;

		body:not(.cursor-is--active) & {
			cursor: pointer;
		}

		svg {
			display: inline-block;
			fill: theme-color("light");
			width: 6px;
			height: 10px;
			transition: fill 300ms ease;
		}

		&:hover {
			svg {
				fill: theme-color("primary");
			}
		}
	}

	.slick-prev {
		left: -10px;
	}

	.slick-next {
		right: -10px;
	}

	.slick-slide {
		img {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
