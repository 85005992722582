//- Team Player
//- ----------------------------------------------

.team-info-page,
.player-info-page {
	color: theme-color("light");
	overflow: hidden;

	&.site-content {
		padding-top: 30px;
		padding-bottom: $header-height-desktop-mobile;
	}

	@include media-breakpoint-up(lg) {
		&.site-content {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.player-info-page {
	&__statistics-footer {
		display: flex;
		flex-wrap: wrap;
		margin-top: 25px;

		.player-info-detail--hero {
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin-bottom: 30px;
			}
		}

		> div:not(.player-info-detail--hero) {
			@include media-breakpoint-down(xs) {
				width: 50%;
			}
		}

		@include media-breakpoint-up(sm) {
			> .player-info-detail:last-child {
				margin-left: 56px;
			}
		}
	}
}

.team-info-page {
	& > .container > .row > div {
		@media (min-width: 992px) and (max-width: 1199px) {
			flex-basis: 80%;
			max-width: 80%;
		}
	}

	.team-player > .container > .row > div {
		position: relative;

		@media (min-width: $xxl-breakpoint) {
			margin-left: 54%;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 100%;
			padding-bottom: 54.5573%;
			transform: translateX(-50%);
			background: url(../img/team-info-page-player-base.png) bottom no-repeat;
			background-size: contain;

			@media (min-width: 1200px) {
				width: 87.2396vh;
				padding-bottom: 54.5573vh;
			}

			img {
				@media (max-width: 374px) {
					max-width: 100%;
				}

				@media (min-width: 1200px) {
					position: relative;
					left: 50%;
					height: 100%;
					transform: translateX(-50%);
				}
			}
		}
	}
}

.player-info {
	&-subtitle,
	&-title {
		text-transform: none;
	}

	&-subtitle {
		letter-spacing: -.03em;
		color: theme-color("primary");

		&.h4 {
			margin-bottom: 12px;
			font-size: 1.5rem;

			@media (min-width: $xxl-breakpoint) {
				margin-bottom: 15px;
				font-size: 2.25rem;
			}
		}

		&.h5 {
			margin-bottom: 9px;
			font-size: 1.25rem;

			@media (min-width: $xxl-breakpoint) {
				margin-bottom: 16px;
				font-size: 1.5rem;
			}

			& + .btn {
				margin-top: 5px;

				@include media-breakpoint-down(sm) {
					margin-top: -19px;
				}

				@media (max-width: 374px) {
					margin-top: -10px;
					padding: 0.75rem 1rem 0.625rem;

					i {
						display: none;
					}
				}
			}
		}
	}

	&-title {
		color: theme-color("light");
		margin-bottom: 32px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 43px;
		}

		@include media-breakpoint-between(md,lg) {
			font-size: 3.875rem;
		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 67px;
		}

		@media (min-width: $xxl-breakpoint) {
			margin-bottom: 85px;
			font-size: 5.25rem;
		}

		&.h1 {
			margin-bottom: 34px;

			@include media-breakpoint-down(sm) {
				font-size: 2.75rem;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: 38px;
			}

			@include media-breakpoint-between(lg,lg) {
				font-size: 5.125rem;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 58px;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-bottom: 76px;
				font-size: 9.125rem;
			}
		}
	}

	&-detail {
		&__link,
		&__label,
		&__title {
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			letter-spacing: -.03em;
			line-height: 1;
		}
		
		&__link,
		&__label {
			color: theme-color("secondary");
		}

		&__link {
			display: inline-block;
			outline: none;

			&:hover {
				color: theme-color("primary");
			}
		}

		&__title {
			font-size: 1rem;
			line-height: 1;

			@include media-breakpoint-up(md) {
				font-size: 1.125rem;
			}

			@media (min-width: $xxl-breakpoint) {
				font-size: 1.75rem;
			}
		}

		&__icon {
			display: block;
			float: left;
			margin-right: 18px;

			@media (min-width: $xxl-breakpoint) {
				margin-right: 26px;
			}

			svg {
				width: 24px;
				height: 24px;

				@media (min-width: $xxl-breakpoint) {
					width: 30px;
					height: 30px;
				}
			}

			&--lg {
				padding-top: 2px;

				@include media-breakpoint-down(sm) {
					.col-6 & {
						float: none;
						margin-bottom: 2px;
					}
				}

				@media (min-width: $xxl-breakpoint) {
					padding-top: 4px;
				}

				svg {
					width: 32px;
					height: 35px;

					@include media-breakpoint-up(md) {
						width: 44px;
						height: 50px;
					}

					@media (min-width: $xxl-breakpoint) {
						width: 67px;
						height: 70px;
					}
				}
			}
		}

		&__value {
			display: block;
			float: left;
			margin-right: 12px;
			color: theme-color("primary");

			@media (min-width: 375px) {
				margin-right: 18px;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-right: 26px;
			}

			&.h3 {
				margin-bottom: 0;
				font-size: 2.5rem;
				letter-spacing: -.03em;

				@media (min-width: 375px) {
					font-size: 2.875rem;
				}

				@media (min-width: $xxl-breakpoint) {
					font-size: 4.375rem;
				}
			}
		}

		&__bar {
			position: relative;
			display: block;
			float: left;
			margin-top: -2px;
			margin-right: 12px;

			@media (min-width: 375px) {
				margin-right: 18px;
			}

			@include media-breakpoint-up(md) {
				margin-top: -8px;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-top: -14px;
				margin-right: 26px;
			}

			svg {
				display: block;
				width: 40px;
				height: 40px;
				transform: rotate(90deg);

				@include media-breakpoint-up(md) {
					width: 52px;
					height: 52px;
				}

				@media (min-width: $xxl-breakpoint) {
					width: 80px;
					height: 80px;
				}

				path {
					stroke: theme-color("secondary");
				}

				path + path {
					stroke: theme-color("primary");
				}
			}

			i {
				position: absolute;
				top: 50%;
				left: 50%;
				font-size: .5rem;
				transform: translate(-40%, -65%);

				@include media-breakpoint-up(md) {
					font-size: .5625rem;
					transform: translate(-35%, -65%);
				}

				@media (min-width: $xxl-breakpoint) {
					font-size: .9375rem;
				}
			}
		}

		&__hero {
			position: relative;
			display: block;
			width: 52px;
			height: 52px;
			margin-top: -8px;
			margin-right: 14px;
			float: left;

			img {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
		}

		&__body {
			overflow: hidden;
		}

		&__label + * {
			margin-top: 5px;

			@media (min-width: $xxl-breakpoint) {
				margin-top: 6px;
			}
		}

		&__title + &__label {
			margin-top: 4px;
		}

		* + .row & {
			margin-bottom: 24px;

			&--icon {
				margin-bottom: 20px;
			}
		}

		* + .row:not(:last-child) &,
		.team-carousel * + .row & {
			@include media-breakpoint-up(md) {
				margin-bottom: 39px;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 49px;
			}

			@media (min-width: $xxl-breakpoint) {
				margin-bottom: 69px;
			}

			&--icon {
				@include media-breakpoint-up(md) {
					margin-bottom: 35px;
				}

				@include media-breakpoint-up(xl) {
					margin-bottom: 45px;
				}

				@media (min-width: $xxl-breakpoint) {
					margin-bottom: 66px;
				}
			}
		}

		p {
			color: $body-color-dark;
		}
	}

	&-carousel {
		position: relative;
		margin-bottom: 4px;

		@include media-breakpoint-down(lg) {
			padding-right: 86px;
		}

		.slick-arrow {
			position: absolute;
			top: 4px;
			padding: 0;
			border: none;
			background-color: transparent;
			font-size: 0;
			outline: 0;
			z-index: 1;
			transition: opacity 300ms ease;
			cursor: none;

			body:not(.cursor-is--active) & {
				cursor: pointer;
			}

			&:hover {
				opacity: 1;
			}

			svg {
				width: 18px;
				height: 10px;
				fill: theme-color("light");

				@media (min-width: $xxl-breakpoint) {
					height: 20px;
				}
			}

			&:hover {
				svg {
					fill: theme-color("primary");
				}
			}
		}

		.slick-prev {
			right: 66px;

			.col-xl-8 & {
				@include media-breakpoint-up(xl) {
					right: auto;
					left: calc(100% + 25px);
				}

				@media (min-width: $xxl-breakpoint) {
					left: calc(100% + 55px);
				}
			}
		}

		.slick-next {
			right: 0;

			.col-xl-8 & {
				@include media-breakpoint-up(xl) {
					right: auto;
					left: calc(100% + 91px);
				}

				@media (min-width: $xxl-breakpoint) {
					left: calc(100% + 141px);
				}
			}
		}

		.slick-custom-pagination {
			display: block;
			position: absolute;
			top: 1px;
			right: 30px;
			font-size: 1.125rem;
			font-weight: $font-weight-bold;
			line-height: 1;

			@media (min-width: $xxl-breakpoint) {
				top: 4px;
				font-size: 1.25rem;
			}

			.col-xl-8 & {
				@include media-breakpoint-up(xl) {
					right: auto;
					left: calc(100% + 67px);
					transform: translateX(-50%);
				}

				@media (min-width: $xxl-breakpoint) {
					left: calc(100% + 108px);
				}
			}
		}
	}
}

// Header Player Info Navigation
.header-player-info-navigation {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 50px;

	@media (min-width: $xxl-breakpoint) {
		width: 78px;
	}

	a {
		margin-top: 4px;
		display: block;
		line-height: 1;

		&.disable {
			svg {
				fill: rgba(theme-color("secondary"), .4);
			}
		}
	}

	svg {
		width: 7px;
		height: 14px;
		fill: theme-color("light");

		@media (min-width: $xxl-breakpoint) {
			width: 11px;
			height: 22px;
		}
	}

	&__card {
		position: absolute;
		left: -26px;
		display: flex;
		align-items: center;
		width: 260px;
		height: 72px;
		background-color: $header-bg-color;
		opacity: 0;
		visibility: hidden;
		transition: all 0s ease-in-out;

		@include media-breakpoint-up(lg) {
			left: -40px;
		}

		@media (min-width: $xxl-breakpoint) {
			left: -60px;
			width: 320px;
			height: 104px;
		}

		.site-header--top & {
			top: calc(100% + 2px);
			transform: translateY(-1rem);
		}

		.site-header--bottom & {
			bottom: calc(100% + 2px);
			transform: translateY(1rem);
		}

		.header-player-info-navigation a:hover & {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
			transition-duration: .3s;
		}
	}

	&__avatar {
		height: 100%;
	}

	&__body {
		padding-left: 12px;
		font-weight: $font-weight-bold;
		letter-spacing: -.03em;
		color: theme-color("light");
	}

	&__name {
		font-size: .625rem;
		text-transform: uppercase;
	}

	&__nickname {
		font-size: 1rem;

		@media (min-width: $xxl-breakpoint) {
			font-size: 1.125rem;
		}
	}

	img {
		height: 100%;
	}
}